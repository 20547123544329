
<script>
export default {
  name: 'FieldSetHeader',
  props: {
    rank: {
      type: Number,
      default: 6,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    headerRank() {
      if (this.rank < 1) return 1
      if (this.rank > 6) return 6
      return this.rank
    },
  },
  render: function (createElement) {
    return createElement(`h${this.headerRank}`, this.title)
  },
}
</script>

