<template>
  <div
    class="coordinates"
  >
    <EVAlert
      :show="showAlert"
      fade
      :count="3"
      message="Beide velden zijn aangepast!"
      variant="success"
      @dismissed="showAlert = false"
    />
    <FieldSet
      class="mb-2"
      :debounce="1000"
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="coordlayout"
      :disabled="disabled"
      @update="handleUpdate"
    />
    <b-spinner
      v-if="disabled"
      small
    />
    <div :class="['btn-group', { showActions }]">
      <b-button
        variant="primary"
        @click="emitCoordinateUpdate"
      >
        Opslaan
      </b-button>
      <b-button
        variant="outline-primary"
        @click="cancelCoordinateUpdate"
      >
        x
      </b-button>
    </div>
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'
import EVAlert from '@/components/EVAlert'

import { required } from 'vuelidate/lib/validators'
import { isValidLatitude, isValidLongitude } from '@/services/validation'

export default {
  name: 'Coordinates',
  components: { FieldSet, EVAlert },
  props: {
    label: {
      type: String,
      default: '',
    },
    values: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: {
        'Latitude': {
            'type': 'float',
            'title': 'Breedtegraad',
        },
        'Longitude': {
            'type': 'float',
            'title': 'Lengtegraad',
        },
      },
      coordlayout: [{
        'columns': '1fr 1fr',
        'fields': ['Latitude', 'Longitude'],
      }],
      validation: {
        'Latitude': {
          required,
          isValidLatitude,
        },
        'Longitude': {
          required,
          isValidLongitude,
        },
      },
      internalData: {},
      showActions: false,
      longitude: 0,
      latitude: 0,
      showAlert: false,
    }
  },
  watch: {
    values: {
      deep: true,
      handler (coords) {
        this.showActions = isValidLongitude(coords.Longitude) && isValidLatitude(coords.Latitude)
        if (this.showActions) {
          this.latitude = coords.Latitude
          this.longitude = coords.Longitude
          this.$emit('pin-location', { lng: this.longitude, lat: this.latitude })
        }
      }
    }
  },

  methods: {
    handleUpdate({ name, value }) {
      const coordinates = value.split(',').map(entry => parseFloat(entry.trim())).filter(Boolean)

      if (coordinates.length > 1) {
        const [latitude, longitude] = coordinates

        this.internalData.Longitude = longitude
        this.internalData.Latitude = latitude

        this.showAlert = true
      } else {
        this.internalData[name] = parseFloat(value.trim())
      }

      const { lat, lng } = this.request.coordinates
      this.longitude = this.internalData.Longitude || lng
      this.latitude = this.internalData.Latitude || lat

      this.showActions = (this.latitude && this.latitude !== lat) || (this.longitude && this.longitude !== lng)

      this.$emit('pin-location', { lng: this.longitude, lat: this.latitude })
    },

    /**
     * Communicate the current coordinates (changed or not) after user interaction with the coordinate fields
     */
    emitCoordinateUpdate () {
      this.$emit('coordinates', { lng: this.longitude, lat: this.latitude })
      this.showActions = false
    },

    cancelCoordinateUpdate () {
      this.internalData = {}
      const { lat, lng } = this.request.coordinates

      this.showActions = false
      this.$emit('pin-location', { lng, lat })
    },
  },
}
</script>
<style lang="scss">
.coordinates {
  position: relative;
  .spinner-border {
    position: absolute;
    left: -1.4rem;
    top: 2.2rem;
    color: lightgrey;
  }
  .btn-group {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    min-height: 41px;
    transition: left 0.3s ease-in-out, opacity 0.4s  ease-in-out ;
    &.showActions {
      left: -7.5em;
      opacity: 1;
    }
    .btn {
      &:active, &:focus {
        box-shadow: none !important;
      }
      &.btn-light {
        background: transparent;
        border-color: #6c757d;

      }
    }
  }
  .FieldSet {
    margin: 0 !important;
    .form-group {
      margin: 0 !important;
    }
  }
}
</style>
