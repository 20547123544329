import { saveAs } from 'file-saver';
import store from '@/store';

const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const colors = {
  Afgerond: '008000',
  Geannuleerd: 'FF0000',
  'On hold': 'ffc107'
};

function getDateStamp() {
  // Add date(now) to the file name //
  const date = Date.now();
  return new Date(date).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
}

function worksheetStyle(worksheet) {
  return worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.height = 20;
    row.eachCell(cell => {
      cell.alignment = {
        indent: 1,
        vertical: 'middle'
      };
      cell.font = {
        color: { argb: colors[cell.value] || '000000' }
      };

      if (rowNumber === 1) {
        row.height = 25;
        cell.font = {
          size: 12,
          bold: true
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }
        };
      } else if (cell.value.url) {
        cell.value = {
          text: cell.value.text,
          hyperlink: cell.value.url,
          tooltip: 'Ga naar het proces'
        };
        cell.font = {
          underline: true,
          color: { argb: 'FF0000FF' }
        };
      }
    });
  });
}

export async function downloadExcel({ config, records }) {
  const { default: ExcelJS } = await import('exceljs');
  const workbook = new ExcelJS.Workbook();
  const acronym = config.file.acronym;
  const fileName = config.file.name;

  const worksheet = workbook.addWorksheet(fileName, {
    views: [{ state: 'frozen', ySplit: 1 }]
  });

  worksheet.columns = config.worksheetColumns;

  worksheet.addRows(config.formatRecords(records));
  worksheetStyle(worksheet);

  const buffer = await workbook.xlsx.writeBuffer({ base64: true });
  saveAs(
    new Blob([buffer], { type: contentType }),
    `${getDateStamp()}-${store.getters['tenant/getTenant'].acronym}-${acronym}.xlsx`
  );
}
