<template>
  <div style="display: none" />
</template>

<script>
/* eslint-disable */

import MapBoxMixin from '@/components/common/MapBoxMixin';
import { mapGetters, mapActions } from 'vuex';

/**
 * This layer shows the realisation process locations
 */
export default {
  name: 'LayerRealisationProcesses',
  mixins: [MapBoxMixin],
  props: {
    /**
     * The selected realisation process
     *  Used on the LocationSelection page
     */
    realisationUuid: {
      type: String,
      default: null
    },

    /**
     * Starting from this step, the location has been validated and actual realisation is started
     */
    realisationFromStep: {
      type: Number,
      default: 6
    },

    /**
     * The uuid of the realisation process being looked at
     *  This realisation process is plotted on the map with a pin already
     */
    activeUuid: {
      type: String,
      default: null
    },
    municipalityCode: {
      type: String,
      default: ''
    },
    showMapItems: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      /**
       * Icon counter. We're loading 2 icons (see requiredIcons)
       */
      iconCounter: 0,
      requiredIcons: [
        'ball',
        'teardrop',
        'onhold-ball',
        'onhold-teardrop',
        'cancelled-ball',
        'cancelled-teardrop',
        'completed-ball'
        // 'connected-ball',
        // 'connected-teardrop'
        // 'suggestion',
        // 'in-progress',
        // 'realized',
        // 'rejected',
        // 'definitive',
        // 'disabled'
      ],
      layerName: 'realisation-processes',

      delayedRepaint: null,
      realisationsSet: new Set(),
      currentMunicipality: null
    };
  },
  computed: {
    ...mapGetters('realisations', ['filteredRecordsByStatus']),
    ...mapGetters('chargingpoints', ['getChargingPointByUUID']),
    ...mapGetters('processes', ['getSelectedProcess']),
    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length;
    },

    shownProcesses() {
      return this.filteredRecordsByStatus.filter(process => {
        // Only show processes which have a status, and are not completed or cancelled
        return this.activeUuid !== process.uuid; // && process.status && process.status.cancelled !== true
      });
    },
    boundToSelectedProcess () {
      return this.shownProcesses.find(process => process.uuid === this.getSelectedProcess?.uuid)
    }
  },
  watch: {
    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0;
      }
      if (ready) {
        this.init();
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer();
      }
    },

    municipalityCode: {
      handler() {
        this.updateLayerData();
      }
    },
    filteredRecordsByStatus: {
      handler() {
        this.updateLayerData();
      }
    }
  },
  created() {
    if (this.loaded) {
      this.init();
    }
  },
  beforeDestroy() {
    this.clearLayer();
  },
  methods: {
    ...mapActions('realisations', ['loadFilteredRecords']),

    /********************************************************************************
     * Setup
     */

    init() {
      // Load the layer icons
      this.loadIcons();
      this.loadFilteredRecords();
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.map.loadImage(require(`@/assets/image/legend/realisation-${name}.png`), (err, image) => {
          if (err) throw err;

          if (!this.map.hasImage(`realisation-${name}`)) {
            this.map.addImage(`realisation-${name}`, image);
          }
          this.iconCounter++;
        });
      });
    },

    /********************************************************************************
     * The events
     */

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer';
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = '';
    },

    /**
     * Emit a click event upon clicking a request icon
     */
    handleClickMarkerEvent(e) {
      if (!e.features.length) return;

      // Cancel other map events
      e.preventDefault();
      e.originalEvent.stopPropagation();

      this.$emit('click', { uuid: e.features[0].properties.uuid });
    },

    /********************************************************************************
     * The Layer
     */

    /**
     * Translate request requests into GeoJSON
     */
    generateGeoJson() {
      const processes = (this.showMapItems && Array.isArray(this.shownProcesses))
        ? this.shownProcesses
        : this.boundToSelectedProcess
          ? [this.boundToSelectedProcess]
          : []

      this.realisationsSet.clear();
      const featureCollection = {
        type: 'FeatureCollection',
        features: processes
          .filter(p => p.MunicipalityCode === this.municipalityCode)
          .map(process => {
            const chargingpointStatus = process.CurrentLocation.Location.chargingpointStatus || '';
            const ballIcon = /realized|in-progress/.test(chargingpointStatus)
            
            const icon = ballIcon || process.status.completed
              ? 'ball'
              : 'teardrop';
            const status = process.status.cancelled
              ? '-cancelled'
              : process.status.onhold
              ? '-onhold'
              : process.status.completed
              ? '-completed'
              : '';

            let coordinates = {
              lat: process.values.Location.Location.Latitude,
              lng: process.values.Location.Location.Longitude
            };
            this.realisationsSet.add(!status ? 'active' : status.replace('-', ''));

            return {
              type: 'Feature',
              properties: {
                uuid: process.uuid,
                icon: `realisation${status}-${icon}`,
                reference: process.case_ref,
                size: 0.05
              },
              geometry: {
                type: 'Point',
                coordinates: [coordinates.lng, coordinates.lat]
              }
            };
          })
      };
      if (this.municipalityCode && this.municipalityCode !== this.currentMunicipality) {
        this.$store.dispatch('chargingpoints/loadActiveFilterItems', {
          filterType: 'realisations',
          filterItems: Array.from(this.realisationsSet),
          municipalityCode: this.municipalityCode
        });
        this.currentMunicipality = this.municipalityCode;
      }

      return featureCollection;
    },

    updateLayerData() {
      if (!this.loaded) return;
      const source = this.map.getSource(this.layerName);

      if (!source) return;
      source.setData(this.generateGeoJson());
    },

    /**
     * Load the request layer
     */
    addLayer() {
      if (!this.map) return;

      this.clearLayer();

      // Add the generated source
      this.map.addSource(this.layerName, {
        type: 'geojson',
        data: this.generateGeoJson()
      });

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id);
      let positionBelow = ['chargingpoints', 'locationpin'];
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : currentLayers.includes(layer) ? layer : null;
      }, null);

      /**
       * The marker icon layer
       */
      this.map.addLayer(
        {
          id: this.layerName,
          type: 'symbol',
          source: this.layerName,
          minzoom: 10,
          layout: {
            'symbol-sort-key': 1,
            'symbol-placement': 'point',
            'symbol-z-order': 'source',
            'icon-allow-overlap': true,
            'icon-image': ['get', 'icon'],
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10, ['get', 'size'], 18, 0.2],
            'icon-offset': [0, -75] // [0, -136] position with stroke only, [0, -70] with text block underneath
          },
          paint: {
            // "icon-color": "",
            'icon-opacity': [
              // transition based on zoom
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0,
              14,
              1
            ]
          }
        },
        positionBelow
      ); // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer(
        {
          id: `${this.layerName}-text`,
          type: 'symbol',
          source: this.layerName,
          minzoom: 14,
          layout: {
            'symbol-sort-key': 1,
            'symbol-placement': 'point',
            'symbol-z-order': 'source',
            // 'text-allow-overlap': true,
            'text-field': ['get', 'reference'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-radial-offset': [
              // transition based on zoom
              'interpolate',
              ['linear'],
              ['zoom'],
              14,
              3.5,
              18.2,
              6
            ],
            'text-variable-anchor': ['bottom'],
            'text-justify': 'center'
          },
          paint: {
            'text-halo-color': '#fff',
            'text-halo-width': 2,
            'text-opacity': ['interpolate', ['linear'], ['zoom'], 14, 0, 14.2, 0.8]
          }
        },
        positionBelow
      );
    },

    /**
     * Clean up the layer
     */
    clearLayer() {
      if (!this.map) return;

      // Clean up first if we must
      let source = this.map.getSource(this.layerName);
      if (source) {
        if (this.map.getLayer(this.layerName)) {
          this.map.removeLayer(this.layerName);
        }
        if (this.map.getLayer(`${this.layerName}-text`)) {
          this.map.removeLayer(`${this.layerName}-text`);
        }

        this.map.removeSource(this.layerName);
      }
    }
  }
};
</script>
