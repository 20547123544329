<template>
  <div class="Realisation">
    <CategorySelectionModal
      v-if="newProcess"
      :selected="category"
      @confirmSelection="handleSetCategory"
    />
    <router-view
      v-else
      :key="$route.path"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CategorySelectionModal from '@/components/realisation/CategorySelectionModal';

export default {
  name: 'Realisation',
  components: {
    CategorySelectionModal
  },
  data() {
    return {
      loadingStatus: {
        pagedWorkflows: {
          error: 0,
          target: 0,
          current: 0
        }
      },
      failed: false,
      missingWorkflows: [],
      defaultFilter: 'actief',
      realisationsListKey: 0,
      realisationUuid: null,
      filteringStorePoints: [
        'chargingpoints/filterChargingPoints',
        'realisations/filterRealisations',
        'requests/filterRequests'
      ],
      category: ''
    };
  },
  computed: {
    ...mapGetters('realisations', [
      'hasRecords',
      'loading',
      'records',
      'lastRefresh',
      'lastOpenedRecord',
      'recordByUuid'
    ]),
    ...mapGetters('user', ['canAccessRequests', 'canAccessRealisationProcesses', 'roleDetailsMerged']),
    ...mapGetters('tenant', ['isCurrentTenant']),
    record() {
      const uuid = this.$route.params?.uuid;
      return this.recordByUuid({ uuid });
    },
    currentStep() {
      return this.record ? this.record.flow.state.filter(step => step.started_at).length.toString() : null;
    },
    newProcess() {
      return this.$route.name === 'NewRealisation' || this.$route.params?.uuid === 'new';
    }
  },

  created() {
    this.$store.dispatch('processes/setCurrentProcessType', {
      processType: 'realisation'
    })

    if (this.newProcess || this.$route.params.step === this.currentStep) return;

    if (this.record && this.$route.params?.uuid && !this.$route.params.step) {
      this.$router.replace({ name: `realisationStep${this.currentStep}`, params: { step: this.currentStep } });
    }
  },

  beforeDestroy() {
    this.resetAllFiltering();
  },
  methods: {
    ...mapActions('realisations', ['loadRecords', 'loadRecordsByCode']),
    ...mapMutations('realisations', ['setLoadingState']),
    ...mapMutations('relations', ['resetRelations', 'addConnection']),
    handleSetCategory(event) {
      this.category = event.category;
      this.$router.replace({ name: 'NewRealisationType', params: { type: event.category } });
    },
    resetAllFiltering() {
      const filters = {
        chargingpoints: [],
        realisations: [],
        requests: []
      };
      this.filteringStorePoints.forEach(store => this.$store.dispatch(store, filters));
      this.$nextTick(() => {
        this.$store.dispatch('chargingpoints/loadSelectedFilterValues', { filterValues: null });
      });
    }
  }
};
</script>
