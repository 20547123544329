<template>
  <b-card
    class="mx-auto mt-3"
    style="width: 325px"
    no-body
  >
    <b-card-header>Start een nieuw realisatie proces</b-card-header>
    <b-card-body>
      <b-card-text>
        Selecteer het soort realisatie proces dat u wilt opstarten.
      </b-card-text>

      <b-input-group class="mt-3">
        <b-select
          v-model="selectedOption"
          :options="typeSelectionOptions"
        />

        <b-input-group-append>
          <b-button
            variant="primary"
            @click="handleConfirmSelection"
          >
            ok
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      selectedOption: null,
    }
  },
  computed: {
    ...mapGetters('tenant', ['getSelectOptionsByName']),
    typeSelectionOptions() {
      return this.getSelectOptionsByName({ name: 'processCategories' })
    },
    defaultCategory() {
      return this.typeSelectionOptions[0].value
    },
  },
  created() {
    this.selectedOption = this.defaultCategory
  },
  methods: {
    handleConfirmSelection() {

      if (! this.selectedOption) {
        return
      }

      this.$emit('confirmSelection', {
        category: this.selectedOption,
      })
    },
  },
}
</script>
