<template>
  <div>
    <h5>Adresgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Straat
        </th>
        <td colspan="2">
          {{ address.street }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Huisnummer
        </th>
        <td colspan="2">
          {{ address.streetNumber }} {{ address.streetNumberSuffix }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Postcode
        </th>
        <td>{{ address.postalCode }}</td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.postalcode } }">
            <b-badge variant="dark">
              {{ requestCountByPostalcode({ postalcode: search.postalcode }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Plaats
        </th>
        <td>
          {{ address.city }}
        </td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.city } }">
            <b-badge variant="dark">
              {{ requestCountByCity({ city: search.city }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th>Gemeente</th>
        <td>{{ municipality }}</td>
      </tr>
      <tr>
        <th>Coördinaten</th>
        <td>{{ lat }}, {{ long }}</td>
      </tr>
    </table>

    <h5>Opmerkingen en overige informatie</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Opmerkingen
        </th>
        <td>{{ remark ? remark : '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Voertuig
        </th>
        <td>{{ vehicle ? vehicle : '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Parkeerplaats
        </th>
        <td>{{ parkingspot ? parkingspot : '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Laadbehoefte
        </th>
        <td>{{ chargingtime ? chargingtime : '-' }}</td>
      </tr>
    </table>

    <h5>Contactgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Naam
        </th>
        <td>{{ contact.name || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Telefoonnummer
        </th>
        <td>{{ contact.phone || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Emailadres
        </th>
        <td>{{ contact.email || '-' }}</td>
      </tr>
    </table>

    <h5>Document:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Voertuig
        </th>
        <td>
          <b-icon
            v-if="vehicleFiles.length"
            class="mr-2"
            icon="download"
          />
          <span v-else>-</span>
          <template v-for="(uuid) in vehicleFiles">
            <FileUuidDownloadLink
              :key="`file_vehicle_${uuid}`"
              :uuid="uuid"
            />
          </template>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Parkeervergunning
        </th>
        <td>
          <b-icon
            v-if="parkingspotFiles.length"
            class="mr-2"
            icon="download"
          />
          <span v-else>-</span>
          <template v-for="(uuid) in parkingspotFiles">
            <FileUuidDownloadLink
              :key="`file_parkingspot_${uuid}`"
              :uuid="uuid"
            />
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObject } from '@/services/validation'
import { getMunicipalityNameByCode } from '@/services/municipalities'
import FileUuidDownloadLink from '@/components/request/FileUuidDownloadLink.vue'

export default {
  components: {
    FileUuidDownloadLink,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('requests', [
      'requestCountByNumberplate',
      'requestCountByPostalcode',
      'requestCountByCity',
    ]),
    search() {
      return this.request.search
    },
    address() {
      return this.request.address
    },
    additional() {
      return this.request.additional
    },
    remark() {
      return this.request.raw.baseData.remark || ''
    },
    municipality() {
     const municipality = this.address.municipality
      if (isObject(municipality)) {
        return municipality.name
      }
      return (!isNaN(municipality) ? getMunicipalityNameByCode({ code: municipality }) : municipality) || ''
    },
    lat(){
      return this.request.coordinates.lat || '-'
    },
    long() {
      return this.request.coordinates.lng || '-'
    },
    contact() {
      return this.request.contact
    },
    vehicle() {
      let vehicle = this.request.additional.vehicle
      if (! vehicle) {
        return false
      }

      return `${vehicle.brand} ${vehicle.model}`.trim()
    },
    parkingspot() {
      let parkingspot = this.request.additional.parkingspot
      if (! parkingspot) {
        return false
      }

      if (parkingspot.permit === 'ja') {
        if (parkingspot.reservedspot === 'ja') {
          return 'Heeft een parkeerplaats op kenteken'
        } else {
          return 'Heeft een parkeervergunning'
        }
      }

      return false
    },
    chargingtime() {
      let context = this.request.additional.context
      if (! context || ! context.chargingtime) {
        return false
      }

      let timeslots = {
        night: 'tussen 0:00 en 07:00',
        morning: 'tussen 07:00 en 12:00',
        afternoon: 'tussen 12:00 en 17:00',
        evening: 'tussen 17:00 en 24:00',
      }

      let chargingtime = ''
      for (let timeslot in timeslots) {
        if (context.chargingtime[timeslot]) {
          chargingtime = chargingtime === '' ? timeslots[timeslot] : `${chargingtime}, ${timeslots[timeslot]}`
        }
      }

      return chargingtime
    },

    /**
     * Files
     */
    vehicleFiles() {
      return this.request.Files?.vehicle || []
    },
    parkingspotFiles() {
      return this.request.Files?.parkingspot || []
    },
  },
}
</script>

<style>

</style>
