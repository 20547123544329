const humanizeDuration = require('humanize-duration')


export const isValidDate = function(date) {
  return date instanceof Date && !isNaN(date)
}

const currentYear = (new Date).getFullYear()

export const msToDate = ({ ms }) => {
  let date = new Date(Number(ms))

  return date.toLocaleDateString(
    'nl-NL',
    {
      year: date.getFullYear() === currentYear ? undefined : 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  )
}

export const periodInDays = ({ duration }) => {
  let dayInMS = 24 * 60 * 60 * 1000
  let start = duration.start
  let end = ! duration.finished ? Date.now() : duration.end
  let diff = end - start

  return Math.round(Math.max(0, diff / dayInMS))
}

export const msDurationToString = ({ ms }) => {
  return humanizeDuration(
    ms,
    { language: 'nl', delimiter: ' en ', largest: 2, round: true },
  )
}

/******************************************************************************
 * The code below is Work In Progress....
 */


/**
 *
 */
export const getWorkingDays = ({ startDate, endDate }) => {
  let result = 0
  let currentDate = startDate

  while (currentDate <= endDate)  {
    let weekDay = currentDate.getDay()
    if (weekDay != 0 && weekDay != 6) {
      result++
    }

    currentDate.setDate(currentDate.getDate()+1)
  }

  return result
}


export const msToNearestWorkingDate = ({ ms }) => {
  let date = new Date(Number(ms))

  // Activity after 18h => early next day
  if (date.getHours() > 17) {
    date.setHours(8)
    date.setDay(date.getDay() + 1)
  }

  // Sunday? => start on monday
  if (date.getDay() === 0) {
    date.setHours(8)
    date.setDay(date.getDay() + 1)
  }
  // Saturday? => start on monday
  if (date.getDay() === 6) {
    date.setHours(8)
    date.setDay(date.getDay() + 2)
  }

  return date
}


// const officeDays = [1,2,3,4,5] // Exclude 0 (sunday) & 6 (saturday)
// const officeHours = [9, 10, 11, 12, 13, 15, 16, 17]

// const isWithinOfficeHours = ({ date }) => {
//   if (! date instanceof Date) throw new Error("date must be a Date object")

//   return (
//     officeDays.includes(date.getDay()) &&
//     officeHours.includes(date.getHours())
//   )
// }

