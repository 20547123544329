<template>
  <div>
    <h5>Adresgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Straat
        </th>
        <td colspan="2">
          {{ address.street }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Huisnummer
        </th>
        <td colspan="2">
          {{ address.streetNumber }} {{ address.streetNumberSuffix }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Postcode
        </th>
        <td>{{ address.postalCode }}</td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.postalcode } }">
            <b-badge variant="dark">
              {{ requestCountByPostalcode({ postalcode: search.postalcode }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Plaats
        </th>
        <td>
          {{ address.city }}
        </td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.city } }">
            <b-badge variant="dark">
              {{ requestCountByCity({ city: search.city }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th>Gemeente</th>
        <td>{{ municipality }}</td>
      </tr>
      <tr>
        <th>Coördinaten</th>
        <td>{{ lat }}, {{ long }}</td>
      </tr>
    </table>

    <h5>Overige informatie</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Merk elektrische auto
        </th>
        <td>{{ brand ? brand : '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Aanschafdatum elektrische auto
        </th>
        <td>{{ purchaseDate ? purchaseDate : '-' }}</td>
      </tr>
    </table>

    <h5>Contactgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Naam
        </th>
        <td>{{ name || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Telefoonnummer
        </th>
        <td>{{ contact.phone || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Emailadres
        </th>
        <td>{{ contact.email || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Adres
        </th>
        <td>{{ contactAddress || '-' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObject } from '@/services/validation'
import { getMunicipalityNameByCode } from '@/services/municipalities'

export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('requests', [
      'requestCountByNumberplate',
      'requestCountByPostalcode',
      'requestCountByCity',
    ]),
    search() {
      return this.request.search
    },
    address() {
      return this.request.address
    },
    additional() {
      return this.request.additional
    },
    remark() {
      return this.request.raw.baseData.remark || ''
    },
    contact() {
      return this.request.contact
    },
    name() {
      let title = isObject(this.contact.title) ? this.contact.title?.title : ''

      return `${title} ${this.contact.firstname} ${this.contact.surname}`.replace('  ', ' ').trim()
    },
    municipality() {
     const municipality = this.address.municipality
      if (isObject(municipality)) {
        return municipality.name
      }
      return (!isNaN(municipality) ? getMunicipalityNameByCode({ code: municipality }) : municipality) || ''
    },
    contactAddress() {
      // let municipality = isObject(this.contact.municipality) ? this.contact.municipality.name : (this.contact.municipality || '')
      const address = this.contact?.address
      if (!address) return 'Geen'
      return `${address.street} ${address.streetNumber}${address.streetNumberSuffix || ''}, ${address.postalCode}, ${address.city}`
    },
    vehicle() {
      return this.request.additional.vehicle || {}
    },
    lat(){
      return this.request.coordinates.lat || '-'
    },
    long() {
      return this.request.coordinates.lng || '-'
    },
    brand() {
      return this.vehicle.brand
    },
    purchaseDate() {
      return this.vehicle.purchaseDate
    },
  },
}
</script>

<style>

</style>
