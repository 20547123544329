<template>
  <div
    v-if="canAccessDebugger"
    class="DebuggerModal"
  >
    <b-card no-body>
      <b-tabs card>
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab
          v-for="tab in tabs"
          :key="'dyn-tab-' + tab.number"
        >
          <template #title>
            Tab {{ tab.number }} <b-icon
              small
              icon="x"
              @click="closeTab(tab.number)"
            />
          </template>

          <div class="d-flex">
            <json-viewer
              v-if="tab.jsonData"
              class="flex-grow-1"
              :value="tab.jsonData"
            />

            <aside class="flex-shrink-0">
              Hello
            </aside>
          </div>
        </b-tab>

        <!-- New Tab Button (Using tabs-end slot) -->
        <template #tabs-end>
          <b-nav-item
            role="presentation"
            href="#"
            @click.prevent="newTab"
          >
            <b>+</b>
          </b-nav-item>
        </template>

        <!-- Render this if no tabs -->
        <template #empty>
          <div class="text-center text-muted">
            There are no open tabs<br>
            Open a new tab using the <b>+</b> button above.
          </div>

          <b-input-group
            class="mt-3"
            prepend="Record by Uuid"
          >
            <b-form-input v-model="recordUuid" />
            <b-input-group-append>
              <b-button
                variant="info"
                :disabled="busy || recordUuid === ''"
                @click="outputRecordByUuid"
              >
                GO
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    JsonViewer,
  },
  data() {
    return {
      busy: false,
      tabs: [],
      tabCounter: 0,
      recordUuid: false,
    }
  },
  computed: {
    ...mapGetters('user', [
      'securityCheck',
    ]),
    ...mapGetters('realisations', [
      'recordByUuid',
    ]),
    routeUuid() {
      return this.$route.params.uuid
    },
    canAccessDebugger() {
      return this.securityCheck({
        attribute: 'canAccessDebugger',
      })
    },
  },
  created() {
    this.recordUuid = this.routeUuid
  },
  methods: {
    closeTab(x) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].number === x) {
          this.tabs.splice(i, 1)
        }
      }
    },
    newTab() {
      this.tabs.push({
        number: this.tabCounter++,
        jsonData: {},
      })
    },


    outputRecordByUuid() {
      let record = this.recordByUuid({
        uuid: this.recordUuid,
      }) || {}

      console.log(record)


      this.tabs.push({
        number: this.tabCounter++,
        jsonData: record,
      })
    },
  },
}
</script>

<style lang="scss">
.DebuggerModal {
  position: fixed;
  bottom: 22px;
  left: 200px;
  right: 25px;
  z-index: 99;
  top: 151px;

  max-height: calc(100% - 173px);

  .card {
    height: 100%;


    .tab-content {
      height: calc(100% - 54px);
    }
    .jv-container .jv-code {
      height: calc(100% - 60px);
    }
    .jv-container .jv-code > .jv-node {
      height: calc(100%);
      overflow: scroll;
    }
  }

  .nav-tabs svg {
    color: red;
  }

  aside {
    width: 200px;
  }
}
</style>
