<template>
  <div class="SingleSelectFilter">
    <label
      v-if="label"
      :for="fieldId"
    >{{ label }}</label>
    <b-button-close
      v-if="value"
      class="btn-sm"
      @click="resetFilter"
    />
    <b-select
      :id="fieldId"
      :disabled="disabled"
      :options="options"
      :value="value"
      @input="handleInput"
    />
  </div>
</template>

<script>

import SingleStringFilter from './SingleStringFilter.vue'

export default {
  name: 'SingleSelectFilter',
  mixins: [SingleStringFilter],
  computed: {
    disabled() {
      return (! Array.isArray(this.options)) || this.options.length === 0
    },
    options() {
      return [{ value: false, text: '---' }]
    },
  },
  watch: {
    /**
     * De-select first and only option (which re-enables the input)
     *  When the only option is already selected, it can't be selected again
     *  This means the input event does not trigger...
     */
    disabled() {
      if (! this.disabled) {
        this.value = ''
      }
    },
  },
}
</script>
