<template>
  <div class="RequestControls">
    <CategoryMenuFilter
      :filter-config="filterConfig"
    />
  </div>
</template>

<script>

import CategoryMenuFilter from '../filters/CategoryMenuFilter.vue'
import TableState from '../mixins/TableState.vue'

/**
 * This component implements the permament controls of the realisation index
 */
export default {
  name: 'RequestControls',
  components: {
    CategoryMenuFilter,
  },
  mixins: [TableState],
  computed: {
    filterConfig() {
      return this.config.filters.category || {}
    },
  },
  watch: {
    activeFilters: {
      deep: true,
      handler() {
        let categoryFilter = this.activeFilters.find(filter => filter.category === 'category')

        if (! categoryFilter) {
          this.setTableLayout({ layout: 'default' })
          return
        }

        let option = this.filterConfig.options
          .find(option => option.value === categoryFilter.value)
        if (! option) {
          this.setTableLayout({ layout: 'default' })
          return
        }

        this.setTableLayout({ layout: option.layout || 'default' })
      },
    },
  },
}
</script>

<style lang="scss">
.RequestControls {
  padding-left: 8px;
}

</style>
