import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import version from './version'
import user from './user'
import requests from './requests'
import realisations from './realisations'
import relations from './relations'
import chargingpoints from './chargingpoints'
import monitoring from './monitoring'
import tenant from './tenant'
import overlay from './overlay'
import files from './files.js'
import realisation from './realisation.js'
import processes from './processes.js'
import assets from './assets.js'

import EVtables from './EVtables'

import debug from './debug'

/**
 *
 */
export default new Vuex.Store({
  state: {
    /**
     *
     */
    loaded: false,
  },

  getters: {
    loaded: state => state.loaded,
  },
  actions: {
  },
  mutations: {
    setLoadedState(state, { loaded }) {
      state.loaded = loaded
    },
  },

  modules: {
    chargingpoints,
    realisations,
    requests,
    relations,
    version,
    user,
    monitoring,
    tenant,
    overlay,
    debug,
    EVtables,
    files,
    realisation,
    processes,
    assets
  },
})
