<template>
  <div class="ProgressCell">
    {{ value }} {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    label () {
      return typeof this.value === 'number' ? this.value === 1 ? 'dag' : 'dagen' : ''
    },
  },
}
</script>

<style lang="scss">

</style>
