
/**
 * Load all components from a folder
 *  Uses file name as component name
 *
 * Usage:
  loadAllComponentsFromFolder({
    ComponentContext: require.context('@/components/Table/cells', true, /\.vue$/i)
  })
 *
 * Note: can't be refactored to only accept '@/components/Table/cells' due to how require works
 *
 * @returns {object}
 */
export const loadAllComponentsFromFolder = ({ ComponentContext }) => {
  return ComponentContext.keys().reduce((components, componentFilePath) => {
    const componentName = componentFilePath.split('/').pop().split('.')[0]
    components[componentName] = ComponentContext(componentFilePath).default
    return components
  }, {})
}


/**
 * Go over the active filters
 *  Remove all filters from this category in place
 *  We cannot assign a new array to activeFilters
 */
export const removeAllActiveFiltersByCategory = ({ activeFilters, category }) => {
  activeFilters
    /**
     * We derrive our instruction input from the achiveFilter array,
     * but don't want to modify it while generating our instruction input
     */
    .slice()
    /**
     * Map the filter to it's index if it's our category. Otherwise set to null
     */
    .map(
      (filter, index) => filter.category === category ? index : null,
    )
    // Discard all entries that resolved to null
    .filter(index => index !== null)
    /**
     * Remove in place by index using splice.
     *  Keep in mind the offset. With each iteration the array index changes by 1
     *  We need to adjust our index at the same pace, for which we use
     *  the index our of indexes as offset
     */
    .forEach(
      (index, offset) => activeFilters.splice((index - offset), 1),
    )
}

export const commonFilterWrapper = ({ callback }) => {
  return ({ records, filter }) => {
    let result = {
      hidden: records.hidden,
      visible: [],
    }

    const evaluate = record => {
      // e.g. if (! record.reference.toLowerCase().includes(filter.value)) {
      if (! callback({ record, value: filter.value })) {
        record.filteredOut = true
        record.filterReason = filter.id
        result.hidden.push(record)
      } else {
        result.visible.push(record)
      }
    }

    records.visible.forEach(evaluate)
    return result
  }
}

export const noFilter = ({ records }) => {
  return records
}
