export default {
  benchmarkMenu: [
    { name: 'Gemeente', target: 'getMunicipalityOptions' },
    { name: 'Netbeheerder', target: 'gridOperators' },
    { name: 'Type realisatieproces', target: 'getCategoryOptions' },
    { name: 'Aantal palen per RP', target: 'recordsByNumberOfChargingStations' }
  ],

  LocationPickerDiameter: 250,

  chargePointOperatorField: {
    type: 'string',
    title: 'CPO',
    format: 'select',
    options: [
      { value: 'TotalEnergies', text: 'TotalEnergies' },
      { value: 'Engie', text: 'Engie' }
    ],
    disabled: false,
    placeholder: 'Selecteer CPO'
  },

  supportEmailAddress: 'support@evtools.be'
};
