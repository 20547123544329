<template>
  <div class="GORALLocationControls p-1 px-3">
    <div class="filters">
      <!-- <MultiSelectFilter
        class="GORalProactiveFilter"
        :filter-config="proactiveConfig"
      /> -->
      <MultiSelectFilter
        class="GORALStatusFilter"
        :filter-config="statusConfig"
      />
      <Toggle
        :value="false"
        :disable="disabled"
        label="Toon alleen door workflow gegenereerde locaties"
        size="middle"
        class="toggle"
        @change="$event => loadWorkflowLocations($event)"
      />
    </div>
    <TableActiveFilters />
  </div>
</template>

<script>

import MultiSelectFilter from '../filters/MultiSelectFilter.vue'
import TableState from '../mixins/TableState.vue'
import TableActiveFilters from '@/components/Table/TableActiveFilters.vue'
import Toggle from '@/components/common/Toggle.vue'
import { mapGetters, mapActions } from 'vuex'

/**
 * This component implements the permament controls of the rvb index
 */
export default {
  name: 'GORALLocationControls',
  components: {
    MultiSelectFilter,
    TableActiveFilters,
    Toggle,
  },
  mixins: [TableState],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      canAddMapLocation: false,
    }
  },
  computed: {
    ...mapGetters('user', ['roleDetailsMerged']),
    multiMunicipalityUser() {
      return this.roleDetailsMerged.length !== 1 || this.roleDetailsMerged.includes('*')
    },
    proactiveConfig() {
      return this.config.filters.proactive || {}
    },
    municipalityConfig() {
      return this.config.filters.municipalities || {}
    },
    statusConfig() {
      return this.config.filters.status || {}
    },
  },
  created () {
    this.loadWorkflowLocations()
  },
  methods: {
    ...mapActions('chargingpoints', ['loadWorkflowLocations']),
  },
}
</script>

<style lang="scss" scoped>
.GORALLocationControls {
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  background: rgba(0, 0, 0, 0.05);

  .filters {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    .GORalProactiveFilter {
      width: 120px;
    }
    .AccessibleMunicipalityFilter {
      width: 200px;
    }

  }

  label:not(.toggle) {
    margin-bottom: 0;
    font-size: 80%;
  }
  &.TableControls__permanent {
    flex-flow: column wrap;
    padding-bottom: 0.5em !important;
    .TableActiveFilters {
      margin-top: 0.5em;
    }
  }
  .toggle {
    align-self: flex-end;
    padding-bottom: 0.5em;
  }
}

</style>
