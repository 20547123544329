<template>
  <div />
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRealisationProcesses',
    ]),
  },
  created() {
    if (this.canAccessRequests) {
      this.$router.push({
        name: 'RequestList',
      })
    }
    else if (this.canAccessRealisationProcesses) {
      this.$router.push({
        name: 'ProcessList',
      })
    } else {
      // TODO: Show error
    }
  },
}
</script>

<style>

</style>
