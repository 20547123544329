<template>
  <div class="ListView">
    <TableManager
      v-if="!loading"
      ref="TableManager"
      class="mx-3"
      :loading="loading"
      :source-records="requests"
      category="requests"
    />

    <div class="mt-3 text-center">
      <div v-if="!loading">
        <router-link
          v-if="lastOpenedRequest"
          class="text-muted"
          :to="{ name: 'RequestDetail', params: { uuid: lastOpenedRequest } }"
        >
          <b-icon
            icon="arrow-left-circle"
            class="mr-1"
          />
          Ga terug naar de laatst geopende aanvraag
        </router-link>

        <div class="text-muted mt-2">
          Laatst bijgewerkt om {{ lastRefresh }}
        </div>
        <b-link
          class="text-muted"
          @click="handleRefresh"
        >
          <b-icon
            icon="arrow-repeat"
            class="mr-1"
          />
          Nu bijwerken
        </b-link>
      </div>
      <div v-else>
        <div v-if="failed">
          <b-alart
            :show="true"
            variant="danger"
          >
            Niet alles kon in 1 keer goed ingeladen worden.
          </b-alart>
          <b-link
            class="text-muted"
            @click="handleRefresh"
          >
            <b-icon
              icon="arrow-repeat"
              class="mr-1"
            />
            Probeer opnieuw
          </b-link>
        </div>
        <div v-else>
          Bezig met verversen...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import TableManager from '@/components/Table/TableManager';
import GeocoderCheck from '@/components/mixins/GeocoderCheck.vue';

export default {
  name: 'RequestList',
  components: {
    TableManager
  },
  mixins: [GeocoderCheck],
  data() {
    return {
      loadingStatus: {
        pagedRequests: {
          error: 0,
          target: 0,
          current: 0
        }
      },
      failed: false,
      missingRequests: []
    };
  },
  computed: {
    ...mapGetters('requests', ['requests', 'hasRequests', 'loading', 'lastRefresh', 'lastOpenedRequest']),
    ...mapGetters('realisations', ['records']),
    ...mapGetters('user', ['canAccessRequests', 'canAccessRealisationProcesses', 'roleDetailsMerged']),
    ...mapGetters('tenant', ['isCurrentTenant', 'getMunicipalityOptions']),

    usesPagedLoader() {
      return false;
      // return !['amsterdam'].includes(process.env.VUE_APP_TENANT);
    }
  },
  async created() {
    if (!this.canAccessRequests) {
      this.$router.push({
        name: 'Dashboard'
      });
    } else {
      if (!this.hasRequests) {
        await this.handleRefresh();
        await this.runGeocoder();
      } else {
        this.matchRequestToRealisations();
      }
    }
  },
  methods: {
    ...mapActions('requests', ['loadRequests', 'loadRequestsByCode']),
    ...mapMutations('requests', ['setLoadingState']),
    ...mapMutations('relations', ['resetRelations', 'addConnection']),

    handleRefresh: async function () {
      const token = await this.$auth.getTokenSilently();
      this.setLoadingState({ loading: true });
      if (!this.usesPagedLoader) {
        await this.loadRequests({ token });

        this.matchRequestToRealisations();
      } else {

        let codes =
          this.missingRequests.length !== 0
            ? this.missingRequests
            : this.getMunicipalityOptions.filter(muni => muni.disabled === false).map(muni => muni.value);

        if (!this.roleDetailsMerged.includes('*')) {
          codes = codes.filter(code => this.roleDetailsMerged.includes(code));
        }

        this.missingRequests = [];
        this.loadingStatus.pagedRequests.error = 0;
        this.loadingStatus.pagedRequests.target = codes.length;

        await Promise.all(
          codes.map(code => {
            return this.loadRequestsByCode({ token, code })
              .then(() => {
                this.loadingStatus.pagedRequests.current++;
              })
              .catch(() => {
                this.loadingStatus.pagedRequests.error++;
                this.missingRequests.push(code);
              });
          })
        );

        if (this.missingRequests.length === 0) {
          this.matchRequestToRealisations();
          this.setLoadingState({ loading: false });
          this.failed = false;
        } else {
          this.failed = true;
        }
      }
    },

    /**
     * Go through the realisation process objects and connect the request objects
     */
    matchRequestToRealisations() {
      if (!this.canAccessRequests && !this.canAccessRealisationProcesses) return;

      this.resetRelations();
      (this.records || []).forEach(record => {
        (record.requestUuids || []).forEach(uuid => {
          this.addConnection({
            requestUuid: uuid,
            realisationUuid: record.uuid
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
.ListView {
  // max-width: 1200px;
  margin: 0 auto;
  padding: 18px 0 30px;

  .ListView__controls {
    display: grid;
    grid-template-columns: 1fr 1em minmax(300px, 500px);
    margin-bottom: 20px;

    &--search {
      grid-column: 3;
      height: 34px; // Avoid vertical stretching when not enough horizontal room

      input {
        height: 34px; // Avoid twitching of height
      }
    }

    &--filter {
      display: block;
      text-align: left;
      grid-column: 1;

      > * {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .ListView__item {
    display: grid;
    // grid-template-columns: 15ch 2fr 3fr 1fr repeat(2, 0.8fr);
    grid-template-columns: 1fr 3fr 2fr repeat(2, 1fr) 2fr;
    align-items: center;
    gap: 20px;
    text-align: left;
    grid-template-rows: 1fr;
    grid-auto-columns: auto;
    cursor: pointer;

    > *:first-child {
      justify-self: start;
    }

    &--head {
      font-weight: bold;
      cursor: default;
    }

    &:not(&--head):hover {
      color: #ec0000;
    }
  }

  .pagination {
    .page-link {
      color: #212529;
    }

    .page-item.active .page-link {
      color: white;
      background-color: #212529 !important;
    }
  }
}
</style>
