<script>
import MultiSelectFilterVue from './MultiSelectFilter.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MunicipalityFilter',
  mixins: [MultiSelectFilterVue],
  computed: {
    ...mapGetters('user', ['roleDetailsMerged']),
    ...mapGetters('tenant', ['getMunicipalityOptions']),
    municipalityOptions() {
      let options = this.getMunicipalityOptions;
      if (!this.roleDetailsMerged.includes('*')) {
        options = options.filter(option => {
          return this.roleDetailsMerged.includes(option.value);
        });
      }

      return options.reduce((options, option) => {
        option.disabled = false;
        // option.value = option.text
        options.push(option);
        return options;
      }, []);
    },

    /**
     * Values that are selectable
     */
    enabledOptionValues() {
      return this.getMunicipalityOptions.map(option => option.value);
    },

    labelsByValue() {
      return this.getMunicipalityOptions.reduce((result, option) => {
        result[option.value] = option.text;
        return result;
      }, {});
    }
  },
  methods: {
    /**
     * Get the label associated with the value
     */
    getLabelByValue({ value }) {
      return this.labelsByValue[value];
    }
  }
};
</script>
