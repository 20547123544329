/*global google */

import { Loader } from '@googlemaps/js-api-loader';

import { formatPostalCode } from '@/helpers/string';

let loaded = false;
const endpoint = 'https://maps.googleapis.com/maps/api/geocode/json';

export const initGoogleAPILoader = () => {
  const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_PUBLIC_API_KEY,
    version: 'weekly'
  });
  loader.load().then(() => {
    loaded = true;
  });
};

const componentsToAddressObject = (result, component) => {
  if (component.types.includes('administrative_area_level_2')) {
    result.municipality = component.long_name;
  } else if (component.types.includes('administrative_area_level_1')) {
    result.province = component.long_name;
  } else if (component.types.includes('country')) {
    result.country = component.long_name;
  } else if (component.types.includes('postal_code')) {
    result.postalCode = formatPostalCode(component.long_name);
  } else if (component.types.includes('route')) {
    result.street = component.long_name;
  }
  // This consists of number + suffix...
  else if (component.types.includes('street_number')) {
    result.street_number = component.long_name;
  } else if (component.types.includes('locality')) {
    result.city = component.long_name;
  }

  return result;
};

/**
 * May be triggered in realtime based on user interaction
 *  https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding
 */
export const dynamicReverseGeocode = async ({ lat, lng }) => {
  const latlng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  };
  // console.log(lat, lng)
  const validTypes = ['street_address', 'premise', 'subpremise'];

  return new Promise((resolve, reject) => {
    if (!loaded) {
      reject('Geocoder is not (yet) available');
    } else {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK' && results.length !== 0) {
          results = results.filter(result => validTypes.some(type => result.types.includes(type)));

          // console.log(results.map(result => {
          //   return {
          //     address: result.formatted_address,
          //     lat: result.geometry.location.lat(),
          //     lng: result.geometry.location.lng()
          //   }
          // }))

          if (results.length !== 0) {
            resolve(results[0].address_components.reduce(componentsToAddressObject, {}));
          } else {
            reject('not found');
          }
        } else {
          reject(status);
        }
      });
    }
  });
};

/**
 * May be triggered in realtime based on user interaction
 *  https://developers.google.com/maps/documentation/javascript/geocoding
 */
export const dynamicGeocode = async ({ address }) => {
  // console.log("dynGeo", address)

  const validTypes = ['street_address', 'premise', 'subpremise', 'route']; // route ??

  return new Promise((resolve, reject) => {
    if (!loaded) {
      reject('Geocoder is not (yet) available');
    } else {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: encodeURIComponent(address) }, (results, status) => {
        if (status === 'OK' && results.length !== 0) {
          // console.log(results)

          // Don't accept results that only indicate a general area
          results = results.filter(result => validTypes.some(type => result.types.includes(type)));
          if (results.length && results[0].geometry && results[0].geometry.location) {
            resolve({
              lng: results[0].geometry.location.lng(),
              lat: results[0].geometry.location.lat()
            });
          } else {
            reject('not found');
          }
        } else {
          reject(status);
        }
      });
    }
  });
};

/**
 * Intended for one time usage per location, not realtime based on user input
 *   https://developers.google.com/maps/documentation/geocoding/overview#before-you-begin
 */
export const staticGeocode = async ({ components, iteration }) => {
  let endpoint = 'https://maps.googleapis.com/maps/api/geocode/json';

  // Ensure iteration is a number above 0
  iteration = parseInt(iteration, 10);
  if (!Number.isInteger(iteration) || iteration < 1) {
    iteration = 1;
  }

  if (
    iteration === 1 &&
    (!Object.prototype.hasOwnProperty.call(components, 'streetSuffix') ||
      components.streetSuffix.trim() === '' ||
      parseInt(components.streetSuffix) == components.streetSuffix)
  ) {
    iteration = 2;
  }

  if (iteration > 4) {
    return {
      result: null,
      iteration
    };
  }

  // console.log('iteration', iteration)

  let componentSegment = '';
  let addressSegment = '';

  // Iteration 1: include suffix if not numeric.
  if (iteration === 1) {
    componentSegment = `&components=${encodeURIComponent(
      `country:NL|postal_code:${components.postalCode}|route:${components.street}`
    )}`;
    addressSegment = `&address=${encodeURIComponent(
      `${components.street} ${components.streetNumber} ${components.streetSuffix}`
    )}`;
  }

  // Iteration 2: exclude suffix
  if (iteration === 2) {
    componentSegment = `&components=${encodeURIComponent(
      `country:NL|postal_code:${components.postalCode}|route:${components.street}`
    )}`;
    addressSegment = `&address=${encodeURIComponent(`${components.street} ${components.streetNumber}`)}`;
  }

  // Iteration 3: ignore postalCode, use street + number
  if (iteration === 3) {
    componentSegment = `&components=${encodeURIComponent('country:NL')}`;
    addressSegment = `&address=${encodeURIComponent(
      `${components.street} ${components.streetNumber}, ${components.city}`
    )}`;
  }

  // Iteration 4: exclude number & suffix
  if (iteration === 4) {
    componentSegment = `&components=${encodeURIComponent(`country:NL|postal_code:${components.postalCode}`)}`;
    addressSegment = `&address=${encodeURIComponent(components.street)}`;
  }

  let response = await fetch(
    `${endpoint}?key=${process.env.VUE_APP_GOOGLE_PUBLIC_API_KEY}&language=nl${addressSegment}${componentSegment}`
  );

  response = await response.json();

  // console.log(response)
  // console.log(response.results)

  if (iteration === 4 && response.status === 'ZERO_RESULTS') {
    return {
      result: null,
      iteration
    };
  } else if (iteration < 4 && response.status === 'ZERO_RESULTS') {
    return await staticGeocode({ components, iteration: iteration + 1 });
  } else if (response.status === 'OK' && response.results.length !== 0) {
    let validTypes = ['street_address', 'premise', 'subpremise'];

    if (iteration === 3) {
      validTypes.push('route');
    }

    let results = response.results.filter(result => result.types.some(type => validTypes.includes(type)));

    if (results.length !== 0) {
      return {
        iteration,
        result: results[0].geometry.location // = {lat, lng}
      };
    } else if (iteration < 4) {
      return await staticGeocode({ components, iteration: iteration + 1 });
    } else {
      return {
        result: null,
        iteration
      };
    }
  } else if (response.status === 'OK') {
    return {
      result: null,
      iteration
    };
  }

  // Not OK. Try again at a later time
  return {
    result: false,
    iteration
  };
};

export async function getCoordinatesFromAddress({ address }) {
  const requestAddress = Object.values(address).join()
  const country = process.env.VUE_APP_TENANT === 'vlaanderen' ? 'Belgium' : 'Netherlands'
  const url = `${endpoint}?language=nl&address=${encodeURIComponent(requestAddress)}+${country}&key=${
    process.env.VUE_APP_GOOGLE_PUBLIC_API_KEY
  }`;
  try {
    const api = await fetch(url);
    const res = api.ok && (await api.json());

    const NLBEonly = res.results.find(r => r.address_components.some(c => ['NL', 'BE'].includes(c.short_name)));

    return [NLBEonly.geometry.location.lng, NLBEonly.geometry.location.lat];
  } catch (err) {
    console.log(err);
  }
}
