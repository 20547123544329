<template>
  <label
    :class="['ToggleSwitch', { disabled }, { isLegend }]"
  >
    <span
      v-if="label"
      class="label"
    >{{ label }}</span>
    <input
      v-model="localValue"
      :disabled="disabled"
      type="checkbox"
      @change="$event => handleChange($event.target.checked)"
    >
    <span :class="['slider round success', size]" />
  </label>
</template>

<script>

export default {
  // Component mounted from mapbox.js as instance.$el //
  name: 'Toggle',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: '',
    },
    isLegend: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      localValue: false,
    }
  },
  watch: {
    value (value) {
      this.localValue = value
    },
  },
  created () {
    this.localValue = this.value
  },
  methods: {
    handleChange (value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss">

/* The switch - the box around the slider */
.ToggleSwitch {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 0;

  /* Hide default HTML checkbox */
  input {
    display: none;
  }
  .label {
    cursor: pointer;
  }

  /* The slider */
  .slider {
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 30px;
    height: 17px;
    order: -1;

    &:after {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: 17px;

      &:after {
        border-radius: 50%;
      }
    }
    &.middle {
      transform: scale(1.3);
    }
    &.large {
      transform: scale(1.5);
    }
    transform-origin: center left;
  }

  input {
    &:checked + .slider {
      &.default {
        background-color: #444;
      }
      &.primary {
        background-color: #2196F3;
      }
      &.success {
        background-color: #8bc34a;
      }
      &.info {
        background-color: #3de0f5;
      }
      &.warning {
        background-color: #FFC107;
      }
      &.danger {
        background-color: #f44336;
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .slider:after {
      transform: translateX(13px);
    }
  }


  &.disabled {
    .slider {
      cursor: not-allowed;
      opacity: 0.6;
    }


  }
  &.layer-category {
    transform: scale(0.8) translateY(3px);
  }
  &.isLegend {
    gap: 0.5em;
    margin-right: 0;
  }
}
</style>
