/* eslint-disable no-unused-vars */

import store from '@/store';

// eslint-disable-next-line no-unused-vars
const DDMMYYYY = ({ date }) => {
  return new Date(date).toLocaleDateString('es-CL');
};

const config = {
  useStreetCabinetUploadField: true,

  requestMapShowActiveRedMarker: false,

  visibleRequestsByRealisationStatuses: {
    completed: false,
    cancelled: true,
    onhold: true
  },

  assetManagement: {
    columns: []
  },
  // Enable the possibility to reset or cancel already saved date
  canResetDateValue: true,

  finUnit: [
    'spv-evnet-nl',
    'friesland-bv',
    'spv-b1',
    'spv001',
    'spv002',
    'spv003',
    'spv004',
    'spv005',
    'spv006',
    'spv007',
    'spv008',
    'spv009'
  ],

  preparationDateFields: {},

  /**
   * Asset management record table object
   */

  assetManagementFields: ({ record }) => {
    const location = record.values.Location.Location;
    const currentLocation = record.CurrentLocation;
    const municipality = store.getters['tenant/getMunicipalityOptions'].find(
      municipality => municipality.value === record.Municipality
    )?.text;

    const getContractorByUuid = store.getters['tenant/getContractorByUuid'];
    const additionalCoordinates = location.AdditionalCoordinates?.map(coords => Object.values(coords).join(', '));

    return {
      uuid: { value: record.uuid, hidden: true },
      history: { value: record.case_ref, label: 'Geschiedenis gekoppelde processen', link: record.uuid },
      locationId: { value: record.locationId?.full, label: 'Locatie ID' },
      streetAndNumber: { value: `${location.StreetName} ${location.HouseNumber}`, label: 'Straatnaam + huisnummer' },
      postalCode: { value: location.PostalCode, label: 'Postcode' },
      city: { value: location.CityName, label: 'Plaatsnaam' },
      municipality: { value: municipality, label: 'Gemeente' },
      currentStep: { value: record.getCurrentFlowStatus()?.step, label: 'Actuele stap' },
      chargerIds: {
        value: record.values.Location.ChargerIds.map(c => c.Identifier).join(', '),
        label: 'Laadpaal ID\'s'
      },
      numberOfChargingStations: {
        value: Number(currentLocation.ParkingSpots.NumberOfChargingStations),
        label: 'Aantal laadpalen'
      },
      numberOfParkingSpots: {
        value: Number(currentLocation.ParkingSpots.NumberOfParkingSpots),
        label: 'Aantal gereserveerde parkeervakken'
      },
      chargingStationVersion: { value: currentLocation.CPOAdvice.ChargingStationVersion, label: 'Type laadpaal' },
      powerType: { value: currentLocation.CPOAdvice.PowerType, label: 'Type power' },
      connectionValue: { value: currentLocation.GridConnection?.ConnectionValue, label: 'Type aansluiting' },
      gridOperator: { value: currentLocation.GridOperator, label: 'Netbeheerder' },
      gridOperatorNumber: { value: currentLocation.GridConnection?.GridOperatorNumber, label: 'Netbeheerdernummer' },
      EAN: { value: record.values.Location.DateOfCommissioning?.EAN, label: 'EAN' },
      contractor: { value: getContractorByUuid({ uuid: currentLocation.Contractor })?.label, label: 'Aannemer' },
      dateActive: { value: record.values.Location.DateOfCommissioning?.DateOfCommissioning, label: 'Actief sinds' },
      domain: { value: 'Publiek', label: 'Domein' },
      finUnit: { value: currentLocation.FinUnit, label: 'SPV' },
      coordinates: { value: `${location.Latitude}, ${location.Longitude}`, label: 'Coördinaten' },
      additionalCoordinates: { value: additionalCoordinates?.join(', '), label: 'Extra coördinaten' }
    };
  },

  operatorsByMunicipalityCode: {}
};
export default config;
