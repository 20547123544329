//import RealisationProcesses from './configs/RealisationProcesses.js';
import RequestsTable from './configs/RequestsTable.js';
//import GORALLocationsMap from './configs/GORALLocationsMap.js';

/**
 * TODO:
 *  - set & get list of suitable table configs (uuids) in tenant config
 *  - implement sorting & filtering on request count
 *  - implement sorting & filtering on related realisation
 *  - abstract filter & mapping functions to named references
 *  - load configs from an external source (e.g. FaunaDB)
 *  - find a way to deal with a mix of model structurs (e.g. from different municipalities)
 *  - account for differences in access at object level, instead of categorical level
 *  - Turn into a modules
 *    - make it possible to register cell, control & filter components
 */

const configurations = {
  // Realisation Proceses
  //'3406eb6e-f42a-4e9d-914a-5a11e308f867': RealisationProcesses,

  // Requests
  'de3dae3b-1531-4d17-85f1-03a1605b3724': RequestsTable,

  // /***
  //  * GO-RAL spec (same as 3406eb6e-f42a-4e9d-914a-5a11e308f867)
  //  */
  // 'ec1e913e-d333-4d0d-bc76-5d4d8b0e3358': RealisationProcesses,

  // /**
  //  * GO-RAL Locations map filter
  //  */
  // '9632abf9-362d-4faa-a27b-d9b725dfa6c4': GORALLocationsMap
};

export const configs = configurations;
