<template>
  <div>
    <span v-if="relatedRealisationUuid">
      {{ relatedRealisationCaseRef }}
    </span>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
  },
  computed: {
    ...mapGetters('realisations', [
      'recordByUuid',
    ]),
    ...mapGetters('relations', [
      'realisationUuidByRequestUuid',
    ]),
    connectedProcess () {
      return this.value?.caseRef
    },
    relatedRealisationUuid() {
      return this.connectedProcess || this.realisationUuidByRequestUuid({ uuid: this.value })
    },
    relatedRealisationCaseRef() {
      return this.connectedProcess || this.recordByUuid({ uuid: this.relatedRealisationUuid })?.case_ref
    },
  },
}
</script>

<style>

</style>
