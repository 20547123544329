<template>
  <div v-if="!isOpen && isAccepted">
    <div v-if="!connectedProcessUuid && canStartRealisationProcess">
      <StartProcessButton
        context="request"
        :query="query"
        :disabled="disabled"
      />

      <!-- <b-button variant="primary" @click="handleStartRealisationProcess">
        Start een realisatieproces
      </b-button> -->
    </div>
    <div v-else-if="connectedProcessUuid && canAccessRealisationProcesses">
      <b-button
        variant="primary"
        @click="handleOpenRealisationProcess"
      >
        Naar het {{ processRouteName || 'realisatieproces' }}
      </b-button>
    </div>
  </div>
</template>

<script>

const uuid = require('uuid')

import StartProcessButton from '@/components/realisation/StartProcessButton.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'RealisationLink',
  components: {
    StartProcessButton,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('realisations', [
      'processByRequestUuid',
    ]),
    ...mapGetters('requests', [
      'statusOptionById',
    ]),
    ...mapGetters('tenant', [
      'getMunicipalityOptions',
    ]),
    ...mapGetters('user', [
      'canAccessRealisationProcesses',
      'canStartRealisationProcess',
    ]),
    ...mapGetters('processes', ['getSelectedProcess']),
    uuid() {
      return this.$route.params.uuid
    },
    status() {
      return this.statusOptionById({ id: this.request.status })
    },
    isOpen() {
      return this.status.id === 2
    },
    isAccepted() {
      return this.status.id === 3
    },
    connectedProcess () {
      return this.request.connectedProcess
    },
    connectedProcessUuid() {
      if (this.connectedProcess) {
        return this.connectedProcess.uuid
      }
      return this.processByRequestUuid({ uuid: this.uuid })
    },
    isRealisationProcess () {
      return !this.connectedProcess || this.connectedProcess.processType.acronym === 'RP'
    },
    routeName () {
      return this.isRealisationProcess ? 'Realisation' : 'Process'
    },
    processRouteName () {
      return this.connectedProcess?.processType?.label.toLowerCase()
    },
    /**
     * Whether the loaded request is lacking coordinates
     */
    hasCoordinates() {
      return this.request && this.request.coordinates && this.request.coordinates.lat
    },
    municipalityCode() {
      return (
        this.request.geographyCode ||
        this.request.address.municipality?.code ||
        (!isNaN(Number(this.request.address.municipality)) && this.request.address.municipality) || // Sometimes comes as a city name
        this.request.municipalityCode ||
        this.request.raw.MunicipalityCode ||
        this.request.raw.Municipality ||
        this.request.raw.municipalityCode
      );
    },
    query() {
      const ams = process.env.VUE_APP_TENANT === 'amsterdam'
      const code = ams ? '0363' : this.municipalityCode

      let query = {
        requests: this.uuid,
        ...(code ? { code } : {}),
        city: this.request.address.city
      }
      if (this.hasCoordinates) {
        query.lat = this.request.coordinates.lat
        query.lng = this.request.coordinates.lng
      }
      return query
    },
  },
  methods: {
    handleStartRealisationProcess () {
      this.$store.dispatch('chargingpoints/setActiveMunicipality', { code: '' });
      let query = {
        aanvragen: this.uuid,
      }
      if (this.hasCoordinates) {
        query.lat = this.request.coordinates.lat
        query.lng = this.request.coordinates.lng
      }

      this.$router.push({
        name: 'Realisation',
        params: {
          uuid: uuid.v4(), // generate a v4 uuid for the new record
        },
        query,
      })
    },
    handleOpenRealisationProcess() {
      this.$router.push({
        name: this.routeName,
        params: {
          uuid: this.connectedProcessUuid,
        },
      })
    },
  },
}
</script>

<style>

</style>
