<template>
  <div class="MenuGeneratorLink">
    <router-link
      class="link"
      :to="item.to"
    >
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuGeneratorLink',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.MenuGeneratorLink {
  margin-bottom: 0.4rem;
}
</style>
