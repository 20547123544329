<template>
  <div class="Comment-Status">
    <button
      class="Comment-Status-Button"
      title="Later"
      :disabled="isDemo"
      @click="$emit('update-status', COMMENT_STATUS.TODO)"
    >
      <BIconClockFill :variant="`${status === 'todo' ? 'warning' : 'lightgray'}`" />
    </button>
    <button
      class="Comment-Status-Button"
      title="Afgehandeld"
      :disabled="isDemo"
      @click="$emit('update-status', COMMENT_STATUS.DONE)"
    >
      <BIconCheckCircleFill :variant="`${status === 'done' ? 'success' : 'lightgray'}`" />
    </button>
    <button
      class="Comment-Status-Button"
      title="Negeren"
      :disabled="isDemo"
      @click="$emit('update-status', COMMENT_STATUS.IGNORE)"
    >
      <BIconXCircleFill :variant="`${status === 'ignore' ? 'darkgray' : 'lightgray'}`" />
    </button>
  </div>
</template>

<script>
import { BIconClockFill, BIconCheckCircleFill, BIconXCircleFill } from 'bootstrap-vue'

import { COMMENT_STATUS } from '@/../shared/valueholders/commentStatuses'

export default {
  components: {
    BIconClockFill,
    BIconCheckCircleFill,
    BIconXCircleFill,
  },
  props: {
    isDemo: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    status: {
      type: String,
      required: false,
      default: () => 'new',
    },
  },
  created() {
    this.COMMENT_STATUS = COMMENT_STATUS
  },
}
</script>

<style lang="scss">
.Comment {
  &-Status {
    display: flex;
    justify-content: flex-end;

    &-Button {
      background: none;
      border: none;
      padding: 0;
      margin: 0 0.4rem;
      font-size: 1.2rem;
    }
  }
}
</style>
