import store from '@/store';

/**
 * Realisation
 */
class Realisation {
  constructor({ ref, data }) {
    data = this.commonStructure({ ref, data });
    data.tenant = 'vlaanderen';

    //
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }

  generateSearchData({ model, data }) {
    let municipalityName = '';
    if (data.Municipality) {
      let municipalityOption = store.getters['tenant/getMunicipalityOptions'].find(
        option => option.value === data.Municipality
      );
      if (municipalityOption) {
        municipalityName = municipalityOption.text;
      }
    }

    return {
      City: model.CurrentLocation ? model.CurrentLocation.Location.CityName : '',
      Municipality: municipalityName
    };
  }

  generateAddressData = ({ model }) => {
    const CurrentLocation = model.CurrentLocation;
    const location = CurrentLocation.Location;
    const municipality = store.getters['tenant/getMunicipalityOptions'].find(m => m.value === CurrentLocation.Municipality)?.text
    const municipalityName = municipality && `(${municipality})`;
    const HouseNumberSuffix = location.HouseNumberSuffix ? ` ${location.HouseNumberSuffix}` : '';

    if (location?.StreetName) {
      return `${location.StreetName} ${(location.HouseNumber + HouseNumberSuffix).trim()}, ${
        location.CityName
      } ${municipalityName}`.replace('  ', ' ');
    }
    return '';
  };
}

export default Realisation;
