import { render, staticRenderFns } from "./BlockRepeaterItem.vue?vue&type=template&id=5a07c860&scoped=true"
import script from "./BlockRepeaterItem.vue?vue&type=script&lang=js"
export * from "./BlockRepeaterItem.vue?vue&type=script&lang=js"
import style0 from "./BlockRepeaterItem.vue?vue&type=style&index=0&id=5a07c860&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a07c860",
  null
  
)

export default component.exports