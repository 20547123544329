<template>
  <div class="RealisationProcessStatus">
    <h4 class="pb-1">
      Gemiddeld aantal dagen doorlooptijd realisatieprocessen
    </h4>
    <div
      v-if="selectedSource"
      class="card"
    >
      <DataTable
        :value="selectedSource"
        :paginator="paginator"
        :rows="perPage"
        :expanded-rows.sync="expandedRows"
        data-key="value"
        responsive-layout="scroll"
        class="p-datatable-sm"
        :auto-layout="true"
        :sort-field="sortField"
        :sort-order="1"
        :row-class="expandedRow"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows-per-page-options="[10, 20, 50]"
        current-page-report-template="{first} tot {last} van {totalRecords}"
        @row-expand="handleRowExpand"
        @row-collapse="handleRowCollapse"
        @sort="handleSorting"
      >
        <div class="p-datatable-header">
          <div class="filter">
            <div>Benchmark</div>
            <Dropdown
              v-model="selectedBenchmark"
              :options="benchmarkMenu"
              option-label="name"
              placeholder="Selecteer benchmark"
            />
          </div>
          <div class="filter">
            <div>Filter op aanmaakdatum:</div>
            <Dropdown
              v-model="filters.date"
              :options="startDateFilter"
              option-label="name"
              placeholder="Selecteer aanmaakdatum"
            />
          </div>
          <div class="filter">
            <div>Filter op status:</div>
            <Dropdown
              v-model="filters.status"
              :options="statusFilter"
              option-label="name"
              placeholder="Selecteer realisatistatus"
            />
          </div>
        </div>
        <Column
          :expander="true"
          :header-style="{ width: '3em' }"
        />
        <Column
          field="label"
          :sortable="isSortable"
          :header="selectedBenchmark.name"
          body-class="benchmark"
        >
          <template #body="slotProps">
            <div @click="handleColumnClick({ slotProps })">
              {{ slotProps.data.label }}
            </div>
          </template>
        </Column>
        <Column
          v-for="column of columns"
          :key="column.field"
          :field="column.field"
          :body-class="`column ${column.field === 'total' ? 'total' : ''}`"
          header-class="column-header"
          :sortable="isSortable"
        >
          <template #header>
            <div
              v-tooltip.top="tooltipContent({ column })"
              :class="['column-title', { disableTooltip }]"
              @mousedown="disableTooltip = true"
            >
              {{ column.header }}
            </div>
          </template>
          <template #body="{ data }">
            <Skeleton v-if="!isDataLoaded" />
            <span
              v-else
              :class="{ sorted: isColumnSortable({ column }) }"
              :style="setColumnBackgroundColor({ column, data })"
            >
              {{ getColumnValue({ column, data }) }}
            </span>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div :class="['realisations', { showDetails }]">
            <h5 class="pb-1">
              {{ expansionTitle({ data: slotProps.data }) }}
            </h5>
            <DataTable
              ref="expandedTable"
              :value="slotProps.data.sourceRecords"
              paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rows-per-page-options="[5, 10, 20, 50]"
              current-page-report-template="{first} tot {last} van {totalRecords}"
              :paginator="slotProps.data.sourceRecords.length > 5"
              :rows="5"
              :row-hover="true"
              class="p-datatable-sm expanded"
              @row-click="handleRowClick"
            >
              <Column
                field="name"
                header="Referentie"
                :sortable="expandedRows.length > 1"
              />
              <Column
                field="address"
                header="Adres"
                :sortable="expandedRows.length > 1"
                :header-class="'address'"
                footer-style="text-align:right"
              >
                <!-- <template #footer>
                  Totaal gemiddeld hoog:
                </template> -->
              </Column>
              <Column header="Looptijd van stappen in dagen">
                <template #body="{ data }">
                  <Timeline
                    :value="data.steps"
                    layout="horizontal"
                    align="top"
                  >
                    <template #opposite="slotProps">
                      Stap {{ slotProps.index + 1 }}
                    </template>
                    <template #marker="slotProps">
                      <div
                        class="timeline-marker"
                        :style="`background: ${slotProps.item.stepData.background}`"
                      />
                    </template>
                    <template #content="slotProps">
                      <span :style="`color: ${slotProps.item.stepData.background}`">
                        {{ slotProps.item.days }}
                      </span>
                    </template>
                  </Timeline>
                </template>
                <!-- <template #footer>
                  <div class="footer">
                    <span v-for="step in availableSteps" :key="step.value">
                      <span v-if="isStepVisible({ step })" v-tooltip.top="step.label">
                        {{ getStepTotalAverage({ step }) }}
                      </span>
                    </span>
                  </div>
              </template> -->
              </Column>
            </DataTable>
          </div>
        </template>
        <ColumnGroup type="footer">
          <Row>
            <Column :header-style="{ width: '3rem' }" />
            <Column
              footer="Totaal gemiddeld:"
              :colspan="1"
              footer-style="text-align:right"
            />
            <!-- footerColumns -->
            <Column
              v-for="column of columns"
              :key="column.field"
              :footer="getTotalAverage({ column })"
            />
          </Row>
        </ColumnGroup>
        <template #empty>
          Geen resultaten!
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Timeline from 'primevue/timeline';
import Skeleton from 'primevue/skeleton';
import Tooltip from 'primevue/tooltip';

import { benchmarkMenu } from '@/config';
import { getGridOperators } from '@/services/gridoperators';
import RealisationProcesses from '@/components/Table/configs/RealisationProcesses';

export default {
  name: 'ProcessTimeInSteps',
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Dropdown,
    Timeline,
    Skeleton
  },
  directives: {
    tooltip: Tooltip
  },
  data() {
    return {
      sortField: 'total',
      minMaxTotalValue: [],
      disableTooltip: false,
      benchmarkMenu,
      gridOperators: getGridOperators(),
      recordsByNumberOfChargingStations: [],
      selectedSource: null,
      selectedBenchmark: null,
      filters: {
        date: { value: null, name: 'Alles' },
        status: { value: null, name: 'Alles' }
      },
      startDateFilter: [
        { value: null, name: 'Alles' },
        { value: '2021', name: '2021' },
        { value: '2022', name: '2022' },
        { value: '2023', name: '2023' }
      ],
      statusFilter: [],
      filteredRecords: [],
      columns: [
        { field: 'nrOfSourceRecords', header: '# RP\'s', notStepColumn: true },
        { field: 'total', header: 'Totaal' }
      ],
      perPage: 10,
      expandedRows: [],
      showDetails: false,
      isDataLoaded: false
    };
  },

  computed: {
    ...mapGetters('realisations', ['records']),
    ...mapGetters('tenant', ['getWorkflowSpecByName', 'getEnabledContractors', 'getCategoryOptions', 'getMunicipalityOptions']),

    steps() {
      let spec = this.getWorkflowSpecByName({ name: 'realisation' });
      return spec ? spec.steps : [];
    },

    availableSteps() {
      return this.steps.map(step => {
        return {
          value: step.uuid,
          label: `${step.short || step.step}: ${step.label}`,
          counts: new Set()
        };
      });
    },

    paginator() {
      return this.selectedSource.length > this.perPage;
    },

    hasActiveFilters() {
      return Object.keys(this.filters).some(filter => this.filters[filter].value);
    },

    footerColumns() {
      return this.columns.filter(column => column.field !== 'nrOfSourceRecords');
    },
    minTotal() {
      return Math.min(...this.minMaxTotalValue);
    },

    maxTotal() {
      return Math.max(...this.minMaxTotalValue);
    },

    isSortable() {
      return this.selectedSource.length > 1;
    }
  },

  watch: {
    selectedBenchmark: {
      handler(benchmark) {
        this.setSelectedSource({ benchmark: this[benchmark.target] });
      }
    },

    filters: {
      immediate: true,
      deep: true,
      handler(filters) {
        this.filteredRecords = this.records;

        this.handleFilterChange({ filters });
      }
    }
  },

  created() {
    this.availableSteps.map((step, index) => {
      this.columns.push({
        field: step.value,
        header: `Stap ${index + 1}`,
        tooltip: step.label
      });
    });

    this.statusFilter = RealisationProcesses.filters.category.options
      .map(filter => {
        if (!['gemeente', 'cpo'].includes(filter.value)) {
          return {
            value: filter.value === 'all' ? null : filter.value,
            name: filter.text
          };
        }
      })
      .filter(Boolean);

    this.records.map(record => {
      const group = record.values.Location.ParkingSpots.NumberOfChargingStations;
      if (!this.recordsByNumberOfChargingStations.some(item => item.value === group)) {
        this.recordsByNumberOfChargingStations.push({ value: group, label: group });
      }
    });
  },
  methods: {
    expandedRow(data) {
      return this.expandedRows?.some(row => row.value === data.value) && 'expanded-row';
    },
    getColumnValue({ column, data }) {
      if (column.notStepColumn) {
        return data[column.field];
      }

      const stepValue = data.sourceRecords.some(record => record.steps.some(step => step.uuid === column.field));
      const totalValue = column.field === 'total';
      const value = stepValue || totalValue ? data[column.field] : (data[column.field] = -1);

      return value < 0 ? '-' : value;
    },

    getTotalAverage({ column }) {
      // Footer values
      let totals = [];
      this.selectedSource.map(source => totals.push(source[column.field]));

      const totalsAverage = totals.filter(value => value >= 0);

      return totalsAverage.length ? Math.round(totalsAverage.reduce((a, b) => a + b, 0) / totalsAverage.length) : '-';
    },

    getStepTotalAverage({ step }) {
      return Math.max(...step.counts);
    },

    isStepVisible({ step }) {
      const rows = this.$refs.expandedTable.dataToRender || [];
      return rows.some(row => row.steps?.some(rowStep => rowStep.uuid === step.value));
    },

    getStepDays({ stepUuid, source }) {
      const availableStep = this.availableSteps.findIndex(step => step.value === stepUuid);
      let steps = [];
      let average = 0;
      let duration = 0;

      source.sourceRecords.map(record => {
        const step = record.steps.find(s => s.uuid === stepUuid);

        if (step) {
          duration = step.days;

          if (duration >= 0) {
            steps.push(duration);
          }
        }
      });

      if (steps.length) {
        average = Math.round(steps.reduce((a, b) => a + b, 0) / steps.length);
        this.availableSteps[availableStep].counts.add(isFinite(average) ? average : 0);
      }

      return {
        [stepUuid]: steps.length ? average : 0
      };
    },

    millisecondsToDays(milliseconds) {
      return Math.round(milliseconds / 1000 / 60 / 60 / 24);
    },

    getPropertyPath({ record }) {
      const path = {
        getMunicipalityOptions: record.MunicipalityCode,
        gridOperators: record.CurrentLocation.GridOperator,
        getEnabledContractors: record.CurrentLocation.Contractor,
        recordsByNumberOfChargingStations: record.values.Location.ParkingSpots.NumberOfChargingStations,
        getCategoryOptions: record.processType
      };

      return path[this.selectedBenchmark.target];
    },

    async setSelectedSource({ benchmark }) {
      this.minMaxTotalValue = [];
      this.availableSteps.map(step => step.counts.clear());

      this.isDataLoaded = benchmark.length < 10;
      this.selectedSource = benchmark.map(source => {
        let total = 0;
        const steps = {};
        const value = source.value || source.uuid;
        const label = source.label || source.text;
        const sourceRecords = [];
        const nrOfSourceRecords = 0;

        return { label, value, total, ...steps, sourceRecords, nrOfSourceRecords };
      });

      // Wait until call stack and the render queue are done
      setTimeout(() => {
        this.selectedSource.map(source => {
          source.sourceRecords = this.setSourceRecords({ value: source.value });
          source.nrOfSourceRecords = source.sourceRecords.length;

          this.availableSteps.forEach(step => {
            const obj = this.getStepDays({ stepUuid: step.value, source });
            source.total += obj[step.value];
            source[step.value] = obj[step.value];
          });
          this.minMaxTotalValue.push(source[this.sortField]);
        });

        this.selectedSource = this.selectedSource.filter(source => source.sourceRecords.length);

        this.isDataLoaded = true;
      });
    },
    // Filter functions
    date(record) {
      const value = this.filters.date.value;
      return value ? String(new Date(record.flow.state[0].started_at).getFullYear()) === value : true;
    },

    status(record) {
      const value = this.filters.status.value;
      return value ? !!RealisationProcesses.dataMapper(record).categories[value] : true;
    },

    handleFilterChange({ filters }) {
      this.expandedRows = [];
      Object.keys(filters).map(filter => {
        this.filteredRecords = this.filteredRecords.filter(this[filter]);
      });

      if (!this.hasActiveFilters) {
        this.filteredRecords = this.records;
      }

      if (!this.selectedBenchmark) {
        this.selectedBenchmark = this.benchmarkMenu[0];
      } else {
        this.setSelectedSource({ benchmark: this[this.selectedBenchmark.target] });
      }
    },

    setSourceRecords({ value }) {
      return this.filteredRecords
        .filter(record => this.getPropertyPath({ record }) === value)
        .map(sourceRecord => {
          return {
            value,
            uuid: sourceRecord.uuid,
            name: sourceRecord.case_ref,
            address: sourceRecord.address,
            events: sourceRecord.Events,
            flow: sourceRecord.flow,
            steps: sourceRecord.flow.state
              .filter(step => step.started_at)
              .map(step => {
                const completedOrRunning =
                  step.completed_at ||
                  RealisationProcesses.dataMapper(sourceRecord).cancelledStartEventTimeStamp ||
                  Date.now();

                const days = this.millisecondsToDays(completedOrRunning - step.started_at);

                return {
                  stepLabel: this.availableSteps.find(availableStep => availableStep.value === step.uuid)?.label,
                  days,
                  uuid: step.uuid,
                  stepData: {}
                };
              })
          };
        });
    },

    handleRowClick(event) {
      this.$router.push({ name: 'Realisation', params: { uuid: event.data.uuid } });
    },

    handleColumnClick({ slotProps }) {
      if (this.expandedRows?.some(row => row.value === slotProps.data.value)) {
        this.handleRowCollapse();
      } else {
        this.handleRowExpand(slotProps);
      }
    },

    handleRowExpand(event) {
      this.expandedRows = event.data.sourceRecords;
      this.showDetails = false;

      this.expandedRows.map(row => {
        row.steps.map(rowStep => {
          rowStep.stepData = this.getStepData({
            value: rowStep.days,
            stepCounts: this.availableSteps.find(step => step.value === rowStep.uuid)?.counts
          });
        });
      });

      setTimeout(() => {
        this.showDetails = true;
      });
    },

    handleRowCollapse() {
      this.showDetails = false;
      this.expandedRows = null;
    },

    expansionTitle({ data }) {
      const chargingpoints = this.selectedBenchmark.target === 'recordsByNumberOfChargingStations';
      return `Realisatieprocessen van ${
        chargingpoints ? 'benchmark' : ''
      } ${this.selectedBenchmark.name.toLowerCase()} ${data.label}`;
    },

    tooltipContent({ column }) {
      return { disabled: !column.tooltip, value: column.tooltip };
    },

    handleColumnColor({ value }) {
      const minHue = 70,
        maxHue = 0;
      const percent = (value - this.minTotal) / (this.maxTotal - this.minTotal);
      const columnColor = 'hsl(' + (percent * (maxHue - minHue) + minHue) + ', 90%, 40%)';
      return columnColor;
    },

    getStepData({ value, stepCounts }) {
      if (stepCounts) {
        const min = Math.min(...stepCounts);
        const max = Math.max(...stepCounts);

        const minHue = 70,
          maxHue = 0;
        let percent = (value - min) / (max - min);

        percent = percent > 1 ? 2 : isFinite(percent) ? (percent < 0 ? 0 : percent) : 0;

        const columnColor = 'hsl(' + (percent * (maxHue - minHue) + minHue) + ', 90%, 40%)';
        return {
          background: columnColor
        };
      }
    },

    handleSorting(event) {
      if (event.sortField === 'nrOfSourceRecords') {
        return (this.sortField = null);
      }

      this.minMaxTotalValue = [];
      this.sortField = event.sortField;
      this.disableTooltip = false;

      this.selectedSource.map(source => this.minMaxTotalValue.push(source[event.sortField]));
    },

    isColumnSortable({ column }) {
      return column.field === this.sortField && this.selectedSource.length > 1 && this.maxTotal !== this.minTotal;
    },

    setColumnBackgroundColor({ column, data }) {
      return this.isColumnSortable({ column })
        ? `background: ${this.handleColumnColor({ value: data[this.sortField] })}`
        : '';
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep * {
  &:focus {
    box-shadow: none !important;
  }
}
.RealisationProcessStatus {
  .pb-1 {
    margin-bottom: 0.7em;
  }

  ::v-deep .p-datatable {
    font-family: Avenir, Helvetica, Arial, sans-serif;

    .p-datatable-thead {
      > tr {
        > th {
          width: auto;
          white-space: nowrap;
          .p-column-title {
            margin-right: 0.5em;
          }
        }
      }
    }
    .p-datatable-tbody > tr > td {
      border-color: #cdd1d6;
    }
    .p-datatable-header {
      padding: 1em;
      display: flex;
      .filter {
        margin-right: 1em;
        .p-dropdown {
          min-width: 235px;
          margin-top: 0.5em;
        }
      }
    }

    .column-header {
      padding: 0;
      .column-title {
        padding: 0.5em;
        display: inline-flex;
        line-height: 2em;
      }
    }
    .p-sortable-column-icon {
      margin-left: 0;
    }

    .p-dropdown {
      height: auto;
    }
    .p-dropdown-items-wrapper {
      max-height: fit-content !important;
    }

    tr {
      td:not(.benchmark) {
        text-align: center;
        &.column {
          width: 100px;
          position: relative;
          padding: 0;
          &.total {
            background-color: rgba(33, 37, 41, 0.5);
            color: white;
          }
          > span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &.sorted {
              color: white;
            }
          }
        }
      }
      .benchmark {
        padding: 0;
        > div {
          padding: 0.5rem 0.5rem;
          line-height: 2;
          width: 100%;
          cursor: pointer;
        }
      }
      &.expanded-row {
        > td {
          background: #f8f8f8f8;
          .p-row-toggler {
            background: #ddd;
            .p-row-toggler-icon {
              color: black;
              font-weight: 700;
            }
          }
        }
      }
      .p-row-toggler-icon {
        font-size: 0.6rem;
      }
    }
    .realisations {
      margin: -50% 1em 1em;
      opacity: 0;
      transition: margin-top 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s ease-out 0.1s;
      .expanded {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        tr {
          th {
            &:first-child {
              width: 160px;
              padding-left: 1em;
            }
            &:last-child {
              width: 60%;
            }
          }
          td {
            text-align: left;
            &:first-child {
              padding-left: 1em;
            }
          }
        }
        tbody {
          tr {
            cursor: pointer;
            &:hover {
              background: #f8f9fa;
              color: inherit;
            }
          }
        }
        .footer {
          display: flex;
          > span {
            flex: 0 1 12%;
          }
        }
      }
      &.showDetails {
        opacity: 1;
        margin-top: 1em;
      }
    }

    .p-timeline-horizontal {
      //font-size: 13px !important;
      .p-timeline-event {
        flex: 0 1 12% !important;
        &:last-child {
          white-space: nowrap;
        }
      }
      .p-timeline-event-opposite,
      .p-timeline-event-content {
        padding: 0.2em 0;
        flex: 0;
      }
      .p-timeline-event-opposite {
        margin-left: -0.3em;
      }
      .timeline-marker {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: lightgrey;
      }
    }
    tr.p-datatable-row-expansion {
      > td {
        //overflow: hidden;
        text-align: left;
      }
    }
    .p-skeleton {
      overflow: hidden;
      width: calc(100% - 1em) !important;
      background-color: #f2f4f6;
      position: absolute;
      left: 0.5em;
      top: 1em;
    }

    // .p-sortable-column {
    //   &:focus {
    //     box-shadow: none;
    //   }
    //   &.p-highlight {
    //     color: #00B46B;
    //     .p-sortable-column-icon {
    //       color: #00B46B;
    //     }
    //   }
    // }
  }
  // .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  //   background: rgba(0, 180, 107, 0.18);
  //   border-color: rgba(0, 180, 107, 0.18);
  // }
}

.disableTooltip {
  pointer-events: none;
}
</style>
