<script>

import { mapGetters, mapMutations } from 'vuex'

import { staticGeocode } from '@/services/geocoder'

export default {
  name: 'GeocoderCheck',
  computed: {
    ...mapGetters('requests', [
      'requestsWithoutCoordinates',
    ]),
    ...mapGetters('user', [
      'canAccessRequests',
    ]),
  },
  methods: {
    ...mapMutations('requests', [
      'updateRequest',
      'replaceRequest',
    ]),

    /**
     * Geocode requests
     *  TODO: Temporary solution while Request form doesn't have a Geocoder.
     */
    runGeocoder: async function() {
      if (! this.canAccessRequests) return

      for (let i = 0; i < this.requestsWithoutCoordinates.length; i++) {
        let request = this.requestsWithoutCoordinates[i]
        let coordinates = await staticGeocode({ components: request.address })

        let iteration = coordinates.iteration
        coordinates = coordinates.result

        if (coordinates || coordinates === null) {
          if (coordinates === null) {
            coordinates = {
                lat: null,
                lng: null,
                nomatch: true,
                iteration,
              }
          } else {
            coordinates = Object.assign({ nomatch: false, iteration }, coordinates)
          }
          const payload = {
            ref: request.ref,
            data: {
              source: 'geocoder',
              coordinates,
            },
          }
          const token = await this.$auth.getTokenSilently()
          const api = await fetch('/api/requestcoordinates', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          })
          if (api.ok) {
            const response = await api.json()

            this.replaceRequest({
              request: response.data.record,
            })
          }
        }
      }
    },
  },
}
</script>
