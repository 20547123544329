import Request from '@/models/Request';
import { deepmerge } from '@/../shared/helpers/object';

const statusOptions = [
  // id: 0 = onvolledig
  // id: 1 = automatisch afgewezen
  {
    id: 2,
    name: 'open',
    label: 'opnenen',
    icon: 'file-earmark-fill',
    variant: 'dark'
  },
  {
    id: 3,
    name: 'geaccepteerd',
    label: 'accepteren',
    icon: 'check-circle-fill',
    variant: 'success'
  },
  {
    id: 4,
    name: 'afgewezen',
    label: 'afwijzen',
    icon: 'x-circle-fill',
    variant: 'danger'
  },
  {
    id: 5,
    name: 'verwijderd',
    label: 'verwijderen',
    icon: 'trash-fill',
    variant: 'warning'
  }
];

const state = {
  loading: false,
  lastRefresh: '',
  searchterm: '',
  currentPage: 1,
  requests: [],
  initialRequests: [],
  activeStatusId: 2, // open
  lastOpenedRequest: null,
  filteredRequestsByRealisationStatus: [],
  requestsByCompletedRealisation: [],
  counts: {
    total: 0,
    current: 0
  },
};

const getters = {
  loading: state => state.loading,
  lastRefresh: state => state.lastRefresh,
  searchterm: state => state.searchterm,
  currentPage: state => state.currentPage,
  lastOpenedRequest: state => state.lastOpenedRequest,
  requests: state => state.requests.sort((a, b) => (a.counter > b.counter ? -1 : 1)),
  filteredRequests: state => state.filteredRequests,
  hasRequests: state => state.requests.length !== 0,
  requestsByStatus:
    (state, getters) =>
    ({ status }) =>
      getters.requests.filter(request => request.status === status),
  requestsByCode: state => ({ code }) =>
    state.requests.filter(request => {
      const municipalityCode =
        request.geographyCode ||
        request.address.municipality?.code ||
        (!isNaN(Number(request.address.municipality)) && request.address.municipality) ||
        request.municipalityCode ||
        request.raw.municipalityCode ||
        request.raw.MunicipalityCode ||
        request.raw.Municipality

      return code === municipalityCode;
    }),
  requestsByActiveStatus: (state, getters) => getters.requestsByStatus({ status: state.activeStatusId }),
  /**
   * Filtering requests connected to realisation with given status
   */
  requestsByRealisationStatus: state => state.filteredRequestsByRealisationStatus,
  requestsByCompletedRealisation: state => state.requestsByCompletedRealisation,

  /**
   * Requests without coordinates
   */
  requestsWithoutCoordinates: (state, getters) =>
    getters.requests.filter(request => request.coordinates.lat === null && request.coordinates.nomatch !== true),

  /**
   * Detail
   */
  hasRequest:
    state =>
    ({ uuid }) => {
      return Array.isArray(state.requests) && state.requests.filter(request => request.uuid === uuid).length > 0;
    },
  requestByUuid:
    state =>
    ({ uuid }) => {
      const request = state.initialRequests.filter(request => request.uuid === uuid);
      return request.length ? request[0] : null;
    },

  requestCountByPostalcode:
    state =>
    ({ postalcode }) => {
      return state.requests.filter(request => request.search.postalcode === postalcode).length;
    },
  requestCountByNumberplate:
    state =>
    ({ numberplate }) => {
      return state.requests.filter(request => request.search.numberplate === numberplate).length;
    },
  requestCountByCity:
    state =>
    ({ city }) => {
      return state.requests.filter(request => request.search.city === city.toLowerCase()).length;
    },

  /**
   * Status options (kept outside of store)
   */
  statusOptions: () => statusOptions,
  statusOptionById:
    () =>
    ({ id }) => {
      let option = statusOptions.filter(option => option.id === id);
      return option.length ? option[0] : {};
    },
  activeStatus: (state, getters) => getters.statusOptionById({ id: state.activeStatusId }),
  activeStatusId: state => state.activeStatusId,
  activeStatusName: (state, getters) => getters.activeStatusOption.name,

  reasonOptions: (state, getters, rootState, rootGetters) => {
    const reasons = rootGetters['tenant/getReasonOptions'];
    return reasons;
  },
  getRequestCounts: state => state.counts
};
const actions = {
  replaceRequest ({ commit }, { request }) {
    commit('replaceRequest', { request })
  },
  resetInitialRequests ({ state }) {
    state.requests = state.initialRequests
  },
  loadRequestsByCompletedRealisation({ commit, rootGetters }, { code }) {
    const realisations = rootGetters['realisations/recordsByCode']({ code }).filter(
      record => record.requestUuids.length && record.status.completed
    );

    commit('setRequestsByCompletedRealisation', { realisations });
  },
  loadRequests: async ({ state, commit, dispatch }, { token, after }) => {
    if (!after) {
      state.requests = state.requests = []
    }

    const api = await fetch('/api/list', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ after }),
    });

    const response = await api.json();

    state.requests = [...state.requests, ...response.data.requests]
    state.counts.total = response.data.count
    state.counts.current += response.data.requests.length

    if (response.data.after) {
      return await dispatch('loadRequests', { token, after: response.data.after })
    }

    const requests = state.requests.map(request => {
      return new Request({ data: request.data, ref: request.ref['@ref'].id });
    });

    commit('setRequests', { requests });
    commit('setLoadingState', { loading: false });

  },
  loadRequestsByCode: async ({ commit }, { token, code }) => {
    const api = await fetch('/api/list', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        codes: [code]
      })
    });
    if (!api.ok) {
      throw new Error('failed');
    }

    const response = await api.json();
    const requests = response.data.requests.map(request => {
      return new Request({ data: request.data, ref: request.ref['@ref'].id });
    });

    commit('replaceRecordsByCode', {
      requests,
      code
    });
  },
  loadRequestCoordinates({ commit }, { coordinates, ref }) {
    commit('updateRequestCoordinates', { coordinates, ref });
  },
  filterRequests({ commit, rootGetters }, { filters, selectedRealisation }) {
    commit('setFilteredRequests', { filters, selectedRealisation, rootGetters });
  }
};
const mutations = {
  setLoadingState(state, { loading }) {
    state.loading = loading;
  },
  setRequests(state, { requests }) {
    state.requests = state.initialRequests = requests;
    const date = new Date(Date.now());
    state.lastRefresh = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
      -2
    )}:${`0${date.getSeconds()}`.slice(-2)}`;
  },
  replaceRecordsByCode(state, { requests, code }) {
    state.requests = (state.requests || []).filter(record => record.getMunicipality() !== code).concat(requests);

    state.initialRequests = state.requests;

    const date = new Date(Date.now());
    state.lastRefresh = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
      -2
    )}:${`0${date.getSeconds()}`.slice(-2)}`;
  },

  setActiveStatus(state, { status }) {
    state.activeStatusId = status;
  },
  setSearchterm(state, { term }) {
    state.searchterm = term;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },

  setLastOpenedRequest(state, { uuid }) {
    state.lastOpenedRequest = uuid;
  },
  setRequestsByCompletedRealisation(state, { realisations }) {
    state.requestsByCompletedRealisation = realisations;
  },
  /**
   * Update a request in the local store
   */
  updateRequest(state, { ref, data }) {
    // Get current request entry by ref
    let requestIndex = state.requests.findIndex(request => request.ref === ref);
    if (requestIndex < 0) return;

    // Obtain the current Request object
    let request = state.requests[requestIndex];

    // Keep track of tag changes
    // TODO: Implement a better solution when switching to Central API / WebSocket
    data = data || {};
    if (!data.Tags) {
      data.Tags = request.getTags();
    }

    // Create a new Request object
    request = new Request({
      ref,
      data: deepmerge(request.raw, data)
    });

    // Out with the old, in with the new
    state.requests.splice(requestIndex, 1, request);
  },

  replaceRequest(state, { request }) {
    const recordIndex = state.initialRequests.findIndex(current => current.uuid === request.data.uuid);
    const requestIndex = state.requests.findIndex(current => current.uuid === request.data.uuid);
    if (recordIndex !== -1) {
      const replacedRQ = new Request({ ref: request.ref['@ref'].id, data: request.data })
      state.initialRequests.splice(recordIndex, 1, replacedRQ);
    }
    if (requestIndex !== -1) {
      state.requests[requestIndex].connectedProcess = request.data.connectedProcess
    }
  },

  updateRequestCoordinates(state, { coordinates, ref }) {
    const requestIndex = state.requests.findIndex(request => request.ref === ref);
    if (requestIndex !== -1) {
      state.requests[requestIndex].coordinates = coordinates;
    }
  },

  updateRequestComments(state, { ref, comments }) {
    let index = state.requests.findIndex(record => record.ref === ref);
    if (index < 0) return;

    let request = state.requests[index];

    request.comments = comments;
  },

  updateRequestByRealisationStatus(state, { requests }) {
    state.filteredRequestsByRealisationStatus = requests;
  },

  setFilteredRequests(state, { filters, selectedRealisation, rootGetters }) {
    if (!filters?.activeMunicipality) return

    state.requests = state.initialRequests.filter(request => {
      const municipalityCode =
        request.address.municipality?.code ||
        (!isNaN(Number(request.address.municipality)) && request.address.municipality) ||
        request.municipalityCode ||
        request.raw.municipalityCode ||
        request.raw.MunicipalityCode ||
        request.raw.Municipality ||
        request.geographyCode;

      return filters.activeMunicipality === municipalityCode;
    });

    const values = Object.values(filters).some(filter => Array.isArray(filter) && filter.length);
    const types = filters.requests?.map(rp => rp.type);
    const selectedProcess = rootGetters['processes/getSelectedProcess']?.uuid

    if (!values) {
      return (state.requests = state.initialRequests);
    }

    if (values && !types.length) {
      return (state.requests = []);
    }

    state.requests = types.length
      ? state.requests.filter(request => {
          const connectedProcess = selectedProcess && (request.connectedProcess?.uuid === selectedProcess)
          const connectedRealisation = rootGetters['relations/realisationUuidByRequestUuid']({ uuid: request.uuid })
          const connectedToSelectedRP = (selectedRealisation === connectedRealisation) || connectedProcess // Both are uuid's //
          const connected = selectedProcess
            ? request.connectedProcess && (request.connectedProcess?.uuid !== selectedProcess)
            : (!!connectedRealisation && !connectedToSelectedRP) || request.connectedProcess?.uuid &&
              (!state.requestsByCompletedRealisation.some(rec => rec.requestUuids.includes(request.uuid)));

          const statuses = {
            open: 2,
            get accepted() {
              if (this.completed || connected || connectedToSelectedRP) return 0;
              return 3;
            },
            rejected: 4,
            removed: 5,
            connected,
            connectedToSelectedRP,
            completed: state.requestsByCompletedRealisation.some(rec => rec.requestUuids.includes(request.uuid))
          };

          return types.some(type => statuses[type] === request.status || statuses[type] === true);
        })
      : state.initialRequests;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
