export const locale = {
    startsWith: 'Begint met',
    contains: 'Bevat',
    notContains: 'Bevat niet',
    endsWith: 'Eindigt met',
    equals: 'Gelijk aan',
    notEquals: 'Niet gelijk aan',
    noFilter: 'Geen filter',
    lt: 'Kleiner dan',
    lte: 'Kleiner dan of gelijk aan',
    gt: 'Groter dan',
    gte: 'Groter dan of gelijk aan',
    dateIs: 'Datum is',
    dateIsNot: 'Datum is niet',
    dateBefore: 'Datum is eerder',
    dateAfter: 'Datum is later',
    clear: 'Wis',
    apply: 'Toepassen',
    matchAll: 'EN',
    matchAny: 'OF',
    addRule: 'Extra Voorwaarde',
    removeRule: 'Wis Voorwaarde',
    filterByArrayEquals: 'filterByArrayEquals',
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Cancel',
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyFilterMessage: 'No results found',
    emptyMessage: 'No available options',
  }
