<template>
  <div style="display:none" />
</template>

<script>
import MapBoxMixin from '@/components/common/MapBoxMixin'
import { mapGetters } from 'vuex'

import { turf } from '@/services/turf'
import { numberWithDots } from '@/helpers/number'

export default {
  name: 'LayerRequestChargerLine',
  mixins: [MapBoxMixin],
  props: {

    /**
     * The active Request
     */
    request: {
      type: Object,
      default: null,
    },

  },
  computed: {
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID',
    ]),
    ...mapGetters('monitoring', [
      'getSelectedChargerUUID',
      'getAllMonitoringData',
    ]),
    requestCoordinates() {
      let coordinates = this.request ? (this.request?.coordinates || null) : null
      return coordinates ? [coordinates.lng, coordinates.lat] : null
    },
    chargingpoints() {
      if ([...this.getAllMonitoringData.keys()].length > 0) {
        return [...this.getAllMonitoringData.keys()].map(id => this.getChargingPointByUUID({ uuid: id }))
      }

      if (this.getSelectedChargerUUID) {
        return [this.getChargingPointByUUID({ uuid: this.getSelectedChargerUUID })]
      }

      return []
    },
    hasSufficientPoints() {
      return !!this.requestCoordinates && this.chargingpoints.length > 0 && this.chargingpoints.every(chargingpoint => chargingpoint.data.coordinates || null)
    },
    geojson() {
      return {
        'type': 'geojson',
        'data': {
          type: 'FeatureCollection',
          features: this.chargingpoints.map(chargingpoint => {
            const from = this.requestCoordinates
            const to = chargingpoint.data.coordinates

            return {
              'type': 'Feature',
              'properties': {
                'distance': `${numberWithDots(Math.ceil(turf.distance(from, to, { unit: 'kilometers' }) * 1000))} meter`,
              },
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  chargingpoint.data.coordinates,
                  this.requestCoordinates,
                ],
              },
            }
          }),
        },
      }
    },
  },
  watch: {
    request() {
      this.drawLine()
    },
    hasSufficientPoints() {
      this.drawLine()
    },
    chargingpoints() {
      this.drawLine()
    },
    loaded() {
      this.drawLine()
    },
    map() {
      this.drawLine()
    },
  },
  created() {
    this.drawLine()
  },
  methods: {

    drawLine() {
      if (!this.loaded || !this.map) return

      // remove lines if no chargingpoints are selected
      if (!this.hasSufficientPoints && this.map.getSource('charger-request-line')) {
        if (this.map.getLayer('charger-request-line')) {
          this.map.removeLayer('charger-request-line')
        }
        if (this.map.getLayer('charger-request-line-text')) {
          this.map.removeLayer('charger-request-line-text')
        }

        return
      }

      if (!this.hasSufficientPoints) {
        return
      }

      const source = this.map.getSource('charger-request-line')

      if (source) {
        source.setData(this.geojson.data)
      } else {
        this.map.addSource('charger-request-line', this.geojson)
      }

      if (!this.map.getLayer('charger-request-line')) {

        let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
        let positionBelow = ['chargingpoints-monitoring', 'chargingpoints', 'realisation-processes', 'realisation-processes-text', 'requests', 'requests-text', 'locationpin']

        positionBelow = positionBelow.reduce((result, layer) => {
          return result ? result : (currentLayers.includes(layer) ? layer : null)
        }, null)

        this.map.addLayer({
          'id': 'charger-request-line',
          'type': 'line',
          'source': 'charger-request-line',
          'minzoom': 10,
          'paint': {
            'line-color': 'rgb(55, 55, 00)',
            'line-width': 2,
          },
          'layout': {
            'line-join': 'round',
            'line-cap': 'round',
          },
        }, positionBelow)

        this.map.addLayer({
          'id': 'charger-request-line-text',
          'type': 'symbol',
          'source': 'charger-request-line',
          'layout': {
            'symbol-placement': 'line',
            'text-font': ['Open Sans Regular'],
            'text-field': ['get', 'distance'],
            'text-offset': [0, -1],
            'text-size': 12,
          },
          'paint': {},
        }, positionBelow)
      }
    },
  },
}
</script>

<style>

</style>
