<template>
  <div class="ProfileCard pt-4">
    <p class="d-flex align-items-center justify-content-between px-4">
      <strong>
        Uw gebruikersprofiel
      </strong>
      <span
        class="ProfileCard__logout d-inline-flex"
        @click="logout"
      >
        <span>Uitloggen</span>
        <SvgIcon
          class="ml-2"
          icon="sign-out-regular"
        />
      </span>
    </p>
    <p class="d-flex align-items-center px-4">
      <SvgIcon
        icon="user-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        {{ username }}
      </span>
    </p>
    <p
      v-if="email || role"
      class="d-flex align-items-center px-4"
    >
      <SvgIcon
        icon="envelope-regular"
        :has-fill="false"
        class="mr-3"
      />
      <span class="d-flex flex-wrap flex-shrink-1">
        {{ email || role }}
      </span>
    </p>

    <PasswordBox @processing="handleProcessing" />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'
import PasswordBox from '@/components/profile/PasswordBox.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ProfileCard',
  components: { PasswordBox, SvgIcon },
  computed: {
    ...mapGetters('overlay', [
      'isOverlayVisible',
    ]),
    username() {
      return this.$auth.user.name
    },
    email() {
      return this.$auth.user.email
    },
    role () {
      return this.$auth.user['https://evtools.nl/identity']?.category
    }
  },
  watch: {
    isOverlayVisible() {
      if (! this.isOverlayVisible) {
        this.maybeClose()
      }
    },
  },
  // created () {
  //   console.log(this.$auth)
  // },
  methods: {
    ...mapMutations('overlay', [
      'hideOverlay',
      'freezeOverlay',
      'unfreezeOverlay',
    ]),
    maybeClose() {
      if (this.busy) return

      this.hideOverlay()
    },
    handleProcessing({ busy }) {
      if (busy) {
        this.freezeOverlay()
      } else {
        this.unfreezeOverlay()
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">
.ProfileCard {
  position: relative;
  background: white;
  z-index: 999999;
  opacity: 0.999;
  min-width: 450px;
  max-width: 450px;

  p, .SvgIcon.SvgIcon {
    font-size: 1.15rem;
    cursor: auto;
  }
  &__logout, &__logout .SvgIcon {
    // position: absolute;
    // top: 1.5rem;
    // right: 1rem;
    cursor: pointer !important;

    &:hover {
      color: #b04300
    }
  }
}
</style>
