<template>
  <div
    v-if="loaded"
    class="DetailView"
  >
    <div class="DetailView__wrapper">
      <div class="DetailView__main">
        <h2 class="h3">
          Referentie: {{ request.case_ref }}
        </h2>

        <Tags
          v-if="showTagsPanel"
          :record="request"
        />

        <Information :request="request" />
        <!-- <b-alert
          :show="!hasEqualCityAndMunicipality"
          variant="info"
        >
          De opgegeven gemeente <strong>{{ requestMunicipality }}</strong>
          is niet gelijk aan de behandelende gemeente
          <strong>{{ activeMunicipality }}</strong> ({{ municipalityCode }}).
        </b-alert> -->
      </div>

      <div class="DetailView__sidebar">
        <RealisationLink
          :request="request"
          :disabled="!hasValidCoordinates"
          class="realisation-link"
        />

        <Judgement
          v-if="canManageRequest"
          :request="request"
          :judgement-mode="judgementMode"
          @judgementMode="handleJudgementModeChange"
        />

        <SummaryTable
          :request="request"
          @judgementMode="handleJudgementModeChange"
        />

        <MapRequest
          :uuid="uuid"
          @open="handleOpenModal"
          @activeMunicipality="handleActiveMunicipality"
        />
      </div>

      <TheComments
        :record="request"
        :type="RECORD_TYPE.REQUEST"
      />
    </div>

    <b-modal
      id="file-error"
      title="Foutmelding"
    >
      <p>Het bestand kon niet worden geopend. Dit kan diverse oorzaken hebben, waaronder:</p>
      <ul>
        <li>Het bestand bestaat niet (meer)</li>
        <li>Het bestand komt niet door de veiligheidsscan</li>
      </ul>
      <template #modal-footer="{ ok }">
        <b-button
          size="sm"
          @click="ok()"
        >
          OK
        </b-button>
      </template>
    </b-modal>

    <MapRequestModal
      v-if="getMonitoringConfig.enabled"
      :uuid="uuid"
      :show="showModal"
      @close="handleCloseModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import MapRequest from '@/components/map/MapRequest';
import Information from '@/components/request/Information';
import SummaryTable from '@/components/request/SummaryTable.vue';
import Judgement from '@/components/request/Judgement.vue';
import RealisationLink from '@/components/request/RealisationLink.vue';
import MapRequestModal from '@/components/map/MapRequestModal.vue';
import Tags from '@/components/Tags.vue';
import TheComments from '@/components/Comments';
import { isValidLatitude, isValidLongitude } from '@/services/validation'

import { RECORD_TYPE } from '@/../shared/valueholders/recordTypes';

export default {
  name: 'Detail',
  components: {
    TheComments,
    MapRequest,
    Information,
    SummaryTable,
    Judgement,
    RealisationLink,
    MapRequestModal,
    Tags
  },
  data() {
    return {
      showModal: false,
      judgementMode: false,
      municipalityCode: null,
      hasEqualCityAndMunicipality: true,
      requestMunicipality: null,
      activeMunicipality: null
    };
  },
  computed: {
    ...mapGetters('requests', ['hasRequest', 'requestByUuid']),
    ...mapGetters('user', ['canAccessRequests', 'canManageRequest']),
    ...mapGetters('tenant', ['getEnabledTags', 'getMonitoringConfig', 'getMunicipalityOptions']),

    loaded() {
      return this.request !== null;
    },
    uuid() {
      return this.$route.params.uuid;
    },
    request() {
      return this.requestByUuid({ uuid: this.uuid });
    },
    hasValidCoordinates () {
      const { lat, lng } = this.request.coordinates
      return isValidLatitude(lat) && isValidLongitude(lng)
    },

    /**
     * Only show the tag panel if there are tags connected to the request
     * or if there are tags that can be connected to the request
     */
    showTagsPanel() {
      return this.canManageRequest && (this.getEnabledTags.length || this.request.hasTags());
    }
  },

  created() {
    this.RECORD_TYPE = RECORD_TYPE;
    /**
     * Navigate to dashboard view if the current user has no municipality access
     *  TODO: Use tenant config to check for access & redirect
     */
    if (!this.canAccessRequests) {
      this.$router.push({ name: 'Dashboard' });

      /**
       * Navigate to list view if the request isn't available (yet)
       */
    } else if (!this.hasRequest({ uuid: this.uuid })) {
      this.$router.push({ name: 'RequestList' });
    } else {
      // If the request has not yet been judged, open the judgement UI
      this.judgementMode = this.request.status === 2;

      //
      this.setLastOpenedRequest({
        uuid: this.uuid
      });
    }
  },
  methods: {
    ...mapMutations('requests', ['setLastOpenedRequest']),
    goBack() {
      this.$router.push({
        name: 'RequestList'
      });
    },
    handleOpenModal() {
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },

    handleJudgementModeChange({ mode }) {
      this.judgementMode = !!mode;
    },
    handleActiveMunicipality (code) {
      this.municipalityCode = code
      this.activeMunicipality = this.getMunicipalityOptions.find(mun => mun.value === code)?.text
      this.requestMunicipality = this.request.address.municipality || this.request.address.city
      this.hasEqualCityAndMunicipality = this.activeMunicipality.toLowerCase().trim() === this.requestMunicipality.toLowerCase().trim()
    }
  }
};
</script>

<style lang="scss">
.DetailView {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  padding: 18px 0 30px;

  .DetailView__wrapper {
    box-sizing: content-box;
    position: relative;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr auto;
  }

  .DetailView__main,
  .DetailView__sidebar {
    padding-top: 30px;
  }

  .DetailView__main {
    a {
      cursor: pointer;
    }
    h2 {
      margin-bottom: 30px;
    }
    table {
      th {
        width: 225px;
      }
      td:nth-child(3n) {
        text-align: right;
        min-width: 40px;
      }
    }
    // table:nth-of-type(2) {
    //   th {
    //     width: 80%;
    //   }
    // }
  }

  .DetailView__sidebar {
    position: sticky;
    top: 0;
    align-self: start;

    width: 400px;

    select,
    input {
      width: 400px;
    }
  }

  .status:not(:last-child) {
    margin-right: 5px;
  }
  .realisation-link {
    margin-bottom: 1em;
  }
}
</style>
