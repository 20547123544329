<template>
  <div
    v-if="instruction"
    class="Instruction"
  >
    <div>
      {{ instruction }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    instruction: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">

.Instruction {
  font-size: 16px;
  position: relative;
  margin-bottom: 1.5em;
}
</style>
