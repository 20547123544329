import Location from './Location';

import { isObject } from '@/services/validation';
import { getMunicipalityNameByCode } from '@/services/municipalities';
// import { capitalize } from '@/helpers/string'

import AmsterdamRealisation from '@/models/amsterdam/Realisation';
import PNCRealisation from '@/models/park-n-charge/Realisation';
import GORRealisation from '@/models/go-ral/Realisation';
import DHARealisation from '@/models/den-hartog/Realisation';
import OPCRealisation from '@/models/opcharge/Realisation';
import VLARealisation from '@/models/vlaanderen/Realisation';
import DCHRealisation from '@/models/dutch-charge/Realisation';
import PWGRealisation from '@/models/power-go/Realisation';
import OCHRealisation from '@/models/orange-charging/Realisation';
import EVTRealisation from '@/models/evtools/Realisation';
import STPRealisation from '@/models/stella-power/Realisation';
import MKERealisation from '@/models/mick-e/Realisation';
import PVTRealisation from '@/models/private/Realisation';

const leadingZero = num => `0${num}`.slice(-2);

// let currentYear = (new Date()).getFullYear()
const formatTime = date =>
  [date.getHours(), date.getMinutes()] // , date.getSeconds()
    .map(leadingZero)
    .join(':');

//import { formatDate, formatShortDate } from '@/helpers/date.js'

/******************************************************************************
 * Constructors
 */
const RealisationByTenant = {
  amsterdam: AmsterdamRealisation,
  'park-n-charge': PNCRealisation,
  'go-ral': GORRealisation,
  'den-hartog': DHARealisation,
  opcharge: OPCRealisation,
  vlaanderen: VLARealisation,
  'dutch-charge': DCHRealisation,
  'power-go': PWGRealisation,
  'orange-charging': OCHRealisation,
  'stella-power': STPRealisation,
  'mick-e': MKERealisation,
  evtools: EVTRealisation,
  private: PVTRealisation
};

const Realisation = RealisationByTenant[process.env.VUE_APP_TENANT] || function () {};

/******************************************************************************
 * Shared methods
 */
Realisation.prototype.getRef = function () {
  return this.ref || null;
};
Realisation.prototype.isUnsavedRecord = function () {
  return this.ref === null;
};
Realisation.prototype.getModelName = function () {
  return 'Realisation';
};
Realisation.prototype.setTags = function ({ Tags }) {
  this.Tags = Tags;
};
Realisation.prototype.hasTags = function () {
  return !!(Array.isArray(this.Tags) && this.Tags.length !== 0);
};
Realisation.prototype.getTags = function () {
  return Array.isArray(this.Tags) ? this.Tags : [];
};
Realisation.prototype.getTagLabels = function () {
  const tenantTags = this.$store.getters['tenant/getTags'];
  const tags = this.getTags() || [];
  const tagLabels = tags.map(tag => tenantTags.find(t => t.uuid === tag)?.label);

  return tagLabels;
};
Realisation.prototype.getCaseRef = function () {
  return this.case_ref;
};
Realisation.prototype.getLocation = function () {
  return this.values.Location;
};
Realisation.prototype.hasDocOfType = function ({ doctype }) {
  return Array.isArray(this.Docs[doctype]) && this.Docs[doctype].length !== 0;
};
Realisation.prototype.getNextDocTypeId = function ({ doctype }) {
  let ids = (this.Docs[doctype] || []).map(doc => {
    return isObject(doc) ? doc.id : doc;
  });
  return ids.length ? Math.max(...ids) + 1 : 1;
};
Realisation.prototype.nextLocationVersion = function () {
  // console.log(this.history)
  return Math.max(...this.history.map(location => location.version)) + 1;
};

/******************************************************************************
 * Process Type
 */
Realisation.prototype.setCategory = function ({ category }) {
  this.category = category;
};
Realisation.prototype.getCategory = function () {
  return this.category || null;
};
Realisation.prototype.hasCategory = function () {
  return this.getCategory() !== null;
};
Realisation.prototype.getCategoryLabel = function () {
  return (
    this.$store.getters['tenant/getSelectOptionLabelByValue']({
      name: 'processCategories',
      value: this.category
    }) || ''
  );
};
Realisation.prototype.setProcessType = function () {
  return {
    label: 'Realisatieproces',
    value: 'realisation',
    acronym: 'RP',
    routeType: 'realisatie'
  };
};

/******************************************************************************
 * Events
 */
Realisation.prototype.calculateStepDuractions = function ({ Events, case_ref }) {
  //let debug = false

  if (case_ref === 'GOR-RP-22-74') {
    //console.log("calc", case_ref, Events)
    //debug = true
  }

  return Events.reduce((durations, Event) => {
    //if (debug) console.log(durations)
    //if (debug) console.log(Event)

    if (Event.type === 'step-start') {
      //if (debug) console.log("start")

      let uuid = Event?.meta?.uuid || null;
      if (uuid !== null) {
        let current = durations[uuid] || false;

        //if (debug) console.log(current)

        // Create a new entry if there is no current data,
        // or if the start is after the currently registered end
        if (!current || Event.ts > current.end) {
          //if (debug) console.log("no current / ts greater than to current end")

          durations[uuid] = {
            start: Math.max(durations[uuid]?.start || 0, Event.ts),
            end: 0,
            finished: false
          };
        } else {
          //if (debug) console.log("current and ts equal or lower than current end")

          durations[uuid].start = Math.max(durations[uuid].start, Event.ts);
        }
      }
    } else if (Event.type === 'step-end') {
      //if (debug) console.log('end')

      let uuid = Event?.meta?.uuid || null;
      if (uuid !== null) {
        let current = durations[uuid] || false;

        //if (debug) console.log(current)

        // If there already an entry...
        if (current) {
          // Only accept end dates after or equal to the registred start date
          if (current.start <= Event.ts) {
            //if (debug) console.log("start is equal to or after end")

            // Always register the latest end date
            durations[uuid].end = Math.max(durations[uuid].end, Event.ts);
            durations[uuid].finished = true;
          } else {
            //if (debug) console.log("ignore")
          }
        } else {
          //if (debug) console.log("set or replace")

          durations[uuid] = {
            start: 0,
            end: Event.ts,
            finished: true
          };
        }
      }
    }

    //if (debug) console.log(durations)

    return durations;
  }, {});
};

Realisation.prototype.getStepDuractionByUuid = function ({ uuid }) {
  return (
    this.processDurationByStep[uuid] || {
      start: 0,
      end: 0,
      finished: false
    }
  );
};

Realisation.prototype.getCurrentStepDuraction = function () {
  const currentFlowStatus = this.getCurrentFlowStatus();
  if (!currentFlowStatus || !currentFlowStatus.uuid) {
    return 0;
  }
  return this.getStepDuractionByUuid({
    uuid: currentFlowStatus.uuid
  });
};

/******************************************************************************
 * Data formatting at contruction that is shared between all tenants
 */

if (!Realisation.prototype.generateSearchData) {
  Realisation.prototype.generateSearchData = () => {
    return {};
  };
}
if (!Realisation.prototype.generateAddressData) {
  Realisation.prototype.generateAddressData = ({ model }) => {
    const CurrentLocation = model.CurrentLocation;
    const location = CurrentLocation.Location;
    const municipality = getMunicipalityNameByCode({ code: CurrentLocation.Municipality });
    const municipalityName = municipality && `(${municipality})`;
    const HouseNumberSuffix = location.HouseNumberSuffix ? ` ${location.HouseNumberSuffix}` : '';
    const evNet = model.CurrentLocation.FinUnit === 'spv-evnet-nl' ? '(Intern)' : '';

    if (location?.StreetName) {
      return `${location.StreetName} ${(location.HouseNumber + HouseNumberSuffix).trim()}, ${
        location.CityName
      } ${municipalityName} ${evNet}`.replace('  ', ' ');
    }
    return '';
  };
}
if (!Realisation.prototype.generateMunicipalityData) {
  Realisation.prototype.generateMunicipalityData = ({ data }) => {
    return data.MunicipalityCode || data.Municipality || null;
  };
}

if (!Realisation.prototype.getFlowUuid) {
  Realisation.prototype.getFlowUuid = function () {
    return this.flow && this.flow.uuid ? this.flow.uuid : false;
  };
}

if (!Realisation.prototype.statusByFlow) {
  Realisation.prototype.statusByFlow = function ({ flow }) {
    if (!Array.isArray(flow.state)) return;
    let steps =
      this.$store.getters['tenant/getWorkflowSpecByName']({
        name: 'realisation'
      })?.steps || [];

    // most recently started step that has not been completed
    const state = Array.isArray(flow.state) ? flow.state : [];
    let uuid = state
      .filter(step => !step.completed_at)
      .sort((a, b) => a.started_at - b.started_at)
      .map(step => step.uuid)
      .shift();

    // There was no uncompleted step. Perhaps the process was completed.
    if (!uuid) {
      // Something could have gone horribly wrong with the flow state
      if (flow.state.length === 0) {
        return {
          step: 1,
          label: 'Onbekend'
        };
      }

      // Was the final step completed?
      let final = steps.find(step => step.final === true);
      if (final && state.find(step => step.uuid === final.uuid)) {
        return {
          step: final.step || final.short,
          uuid: final.uuid,
          version: final.version,
          label: 'Afgerond'
        };
      }

      // If not, return the details of the step that was completed last
      uuid =
        state
          .filter(step => step.completed_at)
          .sort((a, b) => {
            // Note the assumption that the later step in the flow follows after the former
            return b.completed_at === a.completed_at ? -1 : b.completed_at - a.completed_at;
          })[0].uuid || null;
    }

    let step = steps.find(step => step.uuid === uuid);
    if (!step) {
      // Perhaps we got a case of mixed flows?
      return {
        step: 1,
        label: 'Onbekend'
      };
    }

    return {
      step: step.step || step.short,
      uuid: step.uuid,
      version: step.version,
      label: step.label
    };
  };
}

if (!Realisation.prototype.getCurrentFlowStatus) {
  Realisation.prototype.getCurrentFlowStatus = function () {
    return this.statusByFlow({
      flow: this.flow
    });
  };
}

if (!Realisation.prototype.orderAvailableFlowSteps) {
  Realisation.prototype.orderAvailableFlowSteps = function () {
    return this.flow.state.filter(state => state.started_at).sort((a, b) => a.started_at - b.started_at);
  };
}
if (!Realisation.prototype.nextAvailableFlowStep) {
  Realisation.prototype.nextAvailableFlowStep = function ({ currentUuid }) {
    let order = this.orderAvailableFlowSteps();
    // console.log("order", order)
    let index = order.findIndex(state => state.uuid === currentUuid);
    return order[index + 1] ? order[index + 1] : order[0];
  };
}
if (!Realisation.prototype.previousAvailableFlowStep) {
  Realisation.prototype.previousAvailableFlowStep = function ({ currentUuid }) {
    let order = this.orderAvailableFlowSteps();
    let index = order.findIndex(state => state.uuid === currentUuid);
    return order[index - 1] ? order[index - 1] : order[0];
  };
}

if (!Realisation.prototype.commonStructure) {
  Realisation.prototype.commonStructure = function ({ ref, data }) {
    let model = {};

    // console.log("new Location", ref, data)

    let updated_at = 'Onbekend';
    let updated_at_short = '';
    if (data.updated_at) {
      let recordDate = new Date(data.updated_at);
      // es-CL = DD-MM-YYYY
      updated_at = `${recordDate.toLocaleDateString('es-CL')} ${formatTime(recordDate)}`;

      // old data
      updated_at_short = {
        date: recordDate
          .toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' })
          .replace('. ', ' \''),
        time: formatTime(recordDate)
      };
      // if (currentYear > recordDate.getFullYear()) {
      //   updated_at_short = recordDate.toLocaleDateString("nl-NL", { day: 'numeric', month: 'short', year: 'numeric' })
      // } else {

      // }
    }
    // console.log(data.Locations)
    // The first Location is the current Location
    const CurrentLocation = data.Locations?.length ? data.Locations[0] : {};

    try {
      let status = data.status ? data.status : null;

      // Fallback...
      if (status === null) {
        let steps =
          this.$store.getters['tenant/getWorkflowSpecByName']({
            name: 'realisation'
          })?.steps || [];

        status = {
          step: steps[0].short || steps[0].step,
          uuid: steps[0].uuid,
          version: steps[0].version,
          label: steps[0].label,
          completed: false,
          cancelled: false,
          onhold: false,
          vkb: false
        };
      }

      // Go by process flow
      if (data.flow) {
        // console.log("flow available... !", data.flow, ref)

        status = Object.assign(status, this.statusByFlow({ flow: data.flow }));
        // console.log(status)
      }

      let statusMetaDefaults = {
        onhold: {
          reason: '',
          label: '',
          comment: ''
        },
        cancelled: {
          reason: '',
          label: '',
          comment: ''
        },
        onholdReason: '',
        onholdLabel: '',
        onholdComment: '',
        cancelledReason: '',
        cancelledLabel: '',
        cancelledComment: ''
      };
      let statusMeta = statusMetaDefaults;

      if (data.statusMeta) {
        // detect v2 structure
        // if (data.statusMeta.onhold) {
        //   ['onhold', 'cancelled'].forEach(status => {
        //     if (! data.statusMeta[status]) return

        //     ['reason', 'comment', 'label'].forEach(prop => {
        //       let metaProp = `${status}${capitalize(prop)}`
        //       statusMeta[metaProp] = data.statusMeta[status][prop] || ''
        //     })
        //   })
        // } else {

        // }
        statusMeta = Object.assign(statusMetaDefaults, data.statusMeta);
      }
      if (CurrentLocation) {
        //console.log(data)
        CurrentLocation.Municipality = data.MunicipalityCode || data.Municipality || null;
        CurrentLocation.MunicipalityCode = CurrentLocation.Municipality;
      }

      model = {
        ref: ref,
        uuid: data.uuid,
        category: data.category || null,
        processType: data.processType || this.setProcessType(),
        raw: JSON.parse(JSON.stringify(data)),

        case_ref: data.case?.full || '',

        status,
        statusMeta,
        locationId: data.locationId || null,
        isAsset: data.isAsset || null,

        /**
         * Step 1 only for now
         */
        values: {
          Location: CurrentLocation ? new Location({ data: CurrentLocation }) : new Location({ data: {} })
        },

        CurrentLocation,

        history: data.Locations ? data.Locations.map(data => new Location({ data })) : [],

        Docs: data.Docs
          ? {
              LocationProposition: data.Docs.LocationProposition || [],
              StreetCabinet: data.Docs.StreetCabinet || [],
              ParkingSpots: data.Docs.ParkingSpots || [],
              Underground: data.Docs.Underground || [],
              Broker: data.Docs.Broker || [],
              GridOperator: data.Docs.GridOperator || [],
              CPOAdditional: data.Docs.CPOAdditional || [],
              TrafficDecision: data.Docs.TrafficDecision || [],
              DeliveryDocumentation: data.Docs.DeliveryDocumentation || [],
              SideView: data.Docs.SideView || [],
              TopView: data.Docs.TopView || []
            }
          : {
              ParkingSpots: [],
              Underground: [],
              Broker: [],
              GridOperator: [],
              CPOAdditional: [],
              TrafficDecision: [],
              DeliveryDocumentation: [],
              SideView: [],
              TopView: [],
              StreetCabinet: []
            },

        /**
         * Connected requests
         */
        requestUuids: data.requestUuids || [],
        relationalChanges: {
          removed: [],
          added: []
        },

        /**
         * Tags
         */
        Tags: data.Tags || [],

        /**
         * TODO: Remarks etc.
         */
        Events: data.Events || [],

        /**
         * Calculated based on events
         */
        processDurationByStep: {},

        /**
         * Comments
         */
        comments: data.comments || [],

        /**
         * The process flow information
         */
        flow: data.flow || null,

        updated_at,
        updated_at_short
      };
      //console.log(model.values)
      model.processDurationByStep = this.calculateStepDuractions({ Events: model.Events, case_ref: model.case_ref });

      model.Municipality = this.generateMunicipalityData({ model, data });
      model.MunicipalityCode = model.Municipality;
      model.address = this.generateAddressData({ model, data });
      model.search = this.generateSearchData({ model, data });
    } catch (e) {
      console.log(e);
      console.log('Failed to construct data model');
    }

    return model;
  };
}

export default Realisation;
