

/****************************************************************************
 * Charger Location Object Construction
 *
 * TODO: Add data structure specification
 */

/**
 * Custom Constructors per Tenant
 */
const ChargerLocationConstructorsByTenant = {}

/**
 * Select the constructor by Tenant, or use the default
 */
const ChargerLocation = ChargerLocationConstructorsByTenant[process.env.VUE_APP_TENANT]
  /**
  * Turn the plain object into a ChargerLocation object
  */
  || function ChargerLocation({ data }) {
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
  }


/****************************************************************************
 * Priority / Phase Information methods
 *
 * Sometimes timeframe in which a Charger Locations is to be realised is determined
 * well ahead. The following methods provide relevant details.
 *
 * The details differ in structure depending on the agreements and / or external systems
 *
 * @typedef {Object} LocationPhaseInformation
 * @property {string} full - The full phase string
 * @property {string|null} year - The year in which the location is to be realized
 * @property {string|null} period - The period of the year in which the location is to be realized
 * @property {string|number|null} priority - The priority rating (within the planned period) of the location
 */

 if (! ChargerLocation.prototype.hasPhaseInformation) {
    /**
     * Whether priority / phase information has been specified
     *
     * @returns {Boolean}
     */
    ChargerLocation.prototype.hasPhaseInformation = function() {
      let year = this.data?.meta?.import?.proactive_year
      return !! (year && year !== '0')
    }
  }
  if (! ChargerLocation.prototype.getPhaseInformation) {
    /**
     * Returns the Phase information of the Charger Location
     *
     * @returns {LocationPhaseInformation|null}
     */
    ChargerLocation.prototype.getPhaseInformation = function() {
      if (! this.hasPhaseInformation()) return null

      let year = this.data?.meta?.import?.proactive_year
      let period = this.data?.meta?.import?.proactive_period
      let extra = !! (this.data?.mate?.import?.proactive_extra || false)

      return {
        full: `${year}-${period}${extra ? ' extra' : ''}`,
        year,
        period,
        extra,
        priority: null,
      }
    }
  }

  if (! ChargerLocation.prototype.getFullPaseValue) {

    /**
     * Returns the full phase value of a Charger Location
     *
     * @returns {String}
     */
     ChargerLocation.prototype.getFullPaseValue = function() {
      let info = this.getPhaseInformation()
      return info ? info.full : ''
    }
  }


export default ChargerLocation
