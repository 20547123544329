<template>
  <div class="TableSingleStringFilter">
    <label
      v-if="label"
      :for="fieldId"
    >{{ label }}</label>
    <b-button-close
      v-if="value"
      class="btn-sm"
      @click="resetFilter"
    />
    <b-input
      :id="fieldId"
      :value="value"
      @input="handleInput"
    />
  </div>
</template>

<script>

import BaseFilter from '../mixins/BaseFilter.vue'

/**
 * A simple input field, with logic directed at a single active filter within the filter category
 *
 * Note: this component is used as a mixin by other fields
 */
export default {
  name: 'SingleStringFilter',
  mixins: [BaseFilter],
  data() {
    return {
      // TODO: Load from localstorage, through inject from TableManager ?
      value: '',
    }
  },
  computed: {
    /**
     * There can only be one filter in this category
     */
    fieldFilterIndex() {
      return this.activeFilters.findIndex(
        filter => filter.category === this.filterConfig.id,
      )
    },
    fieldFilter() {
      let index = this.fieldFilterIndex
      return (index !== -1) ? this.activeFilters[index] : false
    },

    label() {
      return this.filterConfig.label || false
    },
    fieldId() {
      return `${this.filterConfig.id}-filter`
    },
  },
  watch: {
    /**
     * Feedback loop that also reacts when the filter value is changed elsewhere
     */
    fieldFilter() {
      if (this.fieldFilter) {
        this.value = this.fieldFilter.value
      } else {
        this.value = ''
      }
    },
  },
  created() {
    this.setup()
  },
  methods: {
    setup() {
      if (this.fieldFilter && this.fieldFilter.value) {
        this.value = this.fieldFilter.value
      }
    },
    resetFilter() {
      this.handleInput('')
    },

    /**
     * Handle filter input
     */
    handleInput(value) {
      let label = value

      // Handle single value select field labels
      if (value && Array.isArray(this.options)) {
        let option = this.options.find(option => option.value === value)
        if (option) {
          label = option.text
        }
      }

      // Check whether the filter is already active
      let filter = this.fieldFilter
      if (filter) {
        // remove filter
        if (value === '') {
          this.activeFilters.splice(this.fieldFilterIndex, 1)

        // update filter
        } else {
          filter.label = label
          filter.value = value
        }
      } else if (value) {
        // Create active filter entry
        this.activeFilters.push({
          category: this.filterConfig.id,
          categoryLabel: this.filterConfig.label,
          label: label,
          value: value,
        })
      }
    },

  },
}
</script>
