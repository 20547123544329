<template>
  <div>
    <span>Pas de gewenste filters toe en download de gefilterde lijst.</span>
    <b-button
      v-for="(config, index) in exportTypes"
      :key="`export_btn_${index}`"
      size="sm"
      :variant="config.btn.variant || 'success'"
      @click="downloadExcel({ config })"
      v-text="config.btn.label"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import TableState from '@/components/Table/mixins/TableState';
import { deepClone } from '@/helpers/object';
import { mapGetters } from 'vuex';

export default {
  name: 'TableExport',
  mixins: [TableState],
  data() {
    return {
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      colors: {
        Afgerond: '008000',
        Geannuleerd: 'FF0000',
        'On hold': 'ffc107'
      }
    };
  },
  computed: {
    ...mapGetters('tenant', ['getTenant']),
    exportTypes() {
      return this.config.export;
    }
  },
  methods: {
    getDateStamp() {
      // Add date(now) to the file name //
      const date = Date.now();
      return new Date(date).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
    },
    generateExcelData({ config }) {
      // Make a deep-copy of to remove reactivity for reassigning values //
      const records = deepClone(this.records)?.filter(r => !r.filteredOut);
      return config.formatRecords(records);
    },
    worksheetStyle(worksheet) {
      return worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.height = 20;
        row.eachCell(cell => {
          cell.alignment = {
            indent: 1,
            vertical: 'middle',
            horizontal: 'left'
          };
          cell.font = {
            color: { argb: this.colors[cell.value] || '000000' }
          };

          if (rowNumber === 1) {
            row.height = 25;
            cell.font = {
              size: 12,
              bold: true
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'EEEEEE' }
            };
          } else if (cell.value.url) {
            cell.value = {
              text: cell.value.text,
              hyperlink: cell.value.url,
              tooltip: 'Ga naar de relisatieproces'
            };
            cell.font = {
              underline: true,
              color: { argb: 'FF0000FF' }
            };
          }
        });
      });
    },
    async downloadExcel({ config }) {
      const { default: ExcelJS } = await import('exceljs'); // Dynamically import exceljs
      const workbook = new ExcelJS.Workbook();
      const acronym = config.file.acronym;
      const fileName = config.file.name;

      const worksheet = workbook.addWorksheet(fileName, {
        views: [{ state: 'frozen', ySplit: 1 }]
      });

      // Add columns from config //
      worksheet.columns = config.worksheetColumns;

      worksheet.addRows(this.generateExcelData({ config }));
      this.worksheetStyle(worksheet);

      const buffer = await workbook.xlsx.writeBuffer({ base64: true });
      saveAs(
        new Blob([buffer], { type: this.contentType }),
        `${this.getDateStamp()}-${this.getTenant.tenantAcronym}-${acronym}.xlsx`
      );
    }
  }
};
</script>
