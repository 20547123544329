/**
 * Import Dependency
 */

/**
 * Import API
 */

import { checkStatus, returnJson } from '@/helpers/api'
// import Vue from "vue";
import { Bugfender } from '@bugfender/sdk'

/**
 * Declare Variable
 */
const state = {
  locationsData: [],
  monitoringData: new Map(),
  selectedChargerUUID: null,

  /**
   * The selected connection point to show
   */
  selected: [],
}

const getters = {
  getSelectedChargerUUID: state => state.selectedChargerUUID,

  getLocationDataByUUID: state => ({ uuid }) => {
    let dataset = state.locationsData
      .find(location => location.uuid === uuid) || null

    return (!dataset || dataset.nodata) ? null : dataset
  },
  getSelectedLocationData: (_, getters) => {
    return getters['getLocationDataByUUID']({ uuid: getters['getSelectedChargerUUID'] })
  },
  getAllLocationdata: state => state.locationsData,
  getSelectedLocationUUIDs: state => state.selected || [],
  getSelectedLocationsWithData: (state, getters) => getters['getSelectedLocationUUIDs']
    .filter(uuid => getters['getLocationDataByUUID']({ uuid }))
    .map(uuid => getters['getLocationDataByUUID']({ uuid })),
  getMonitoringDataByUuid: state => ({ uuid }) => {
    return state.monitoringData.has(uuid) ? state.monitoringData.get(uuid) : null
  },
  getSelectedChargerMonitoringData: (_, getters) => {
    const uuid = getters['getSelectedChargerUUID']
    return getters['getMonitoringDataByUuid']({ uuid })
  },
  getAllMonitoringData: state => state.monitoringData,
  getSelectedChargerUuids: state => (state.monitoringData.size ? [...state.monitoringData.keys()] : []),
  getSelectedChargersWithData: (state) => [...state.monitoringData.values()],
}

const actions = {
  async fetchConnectionPointsByChargingpoint({ commit }, { chargingpoint }) {
    if (!chargingpoint) return null

    let code = chargingpoint?.data.code || null
    let uuid = chargingpoint?.data.uuid || null

    if ((!uuid) || (!code)) return null

    const token = await this.$auth.getTokenSilently()

    return await fetch(`${process.env.VUE_APP_MONITORING_API_URL}/location`, {
      method: 'POST',
      headers: {
        authorization: 'Bearer ' + token,
        'x-from': 'workflow',
      },
      body: JSON.stringify({ uuid, code }),
    })
      .then(await checkStatus)
      .then(returnJson)
      .then(response => {
        let locationData = { nodata: true }

        if (response.data?.length) {
          locationData = response.data[0]
          locationData.nodata = false
        }

        // Add some reference data related to the charger the monitoring data belongs to
        locationData.chargerNumber = `${chargingpoint?.data.code}-${chargingpoint?.data.properties.id}` || null
        locationData.evse = chargingpoint?.data.properties.evse || null
        locationData.operator = chargingpoint?.data.properties.operator || null
        locationData.uuid = chargingpoint?.data.properties.uuid || null
        locationData.coordinates = chargingpoint?.data.coordinates || null

        commit('addLocationData', { locationData }) // aggregated data
      })
      .catch(Bugfender.error)
  },
  async fetchLastMonthsUsageReport({ commit }, { chargingpoint, numberOfMonths, multipleSelect }) {
    if (!chargingpoint) return null

    let code = chargingpoint?.data.code || null
    let uuid = chargingpoint?.data.uuid || null

    if (!uuid || !code) return null

    const token = await this.$auth.getTokenSilently()
    const res = await fetch(
      `/api/reports-last-months-by-uuid/${code}/${uuid}?months=${numberOfMonths}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    const { data } = await res.json()
    commit('addMonitoringData', { data, uuid: uuid, multipleSelect })
    return data
  },
  addOrRemoveSelectedCharger({ dispatch, commit, getters, rootGetters }, { uuid }) {
    if (getters['getSelectedLocationUUIDs'].includes(uuid)) {
      commit('removedSelected', { uuid })
      return
    }

    commit('addSelected', { uuid })

    if (!getters['getLocationDataByUUID']({ uuid })) {
      dispatch('fetchConnectionPointsByChargingpoint', {
        chargingpoint: rootGetters['chargingpoints/getChargingPointByUUID']({ uuid }),
      })
    }
  },
  toggleSelectedCharger({ dispatch, commit, getters, rootGetters }, { uuid }) {
    if (getters['getSelectedChargerUuids'].includes(uuid)) {
      return
    }
    commit('addSelected', { uuid })
    dispatch('fetchLastMonthsUsageReport', {
      chargingpoint: rootGetters['chargingpoints/getChargingPointByUUID']({ uuid }),
      numberOfMonths: 3,
      multipleSelect: true,
    })
  },
  addSelectedCharger({ dispatch, commit, getters, rootGetters }, { uuid }) {
    commit('addSelected', { uuid })

    if (!getters['getMonitoringDataByUuid']({ uuid })) {
      dispatch('fetchLastMonthsUsageReport', {
        chargingpoint: rootGetters['chargingpoints/getChargingPointByUUID']({ uuid }),
        numberOfMonths: 3,
      })
    }
  },
  removedSelectedCharger({ commit }, { uuid }) {
    commit('removedSelected', { uuid })
  },
}

const mutations = {
  addLocationData: function (state, { locationData }) {
    let index = state.locationsData.findIndex(stateLocationData => locationData.uuid === stateLocationData.locationUuid)
    if (index === -1) {
      state.locationsData.push(locationData)
    }
  },
  addMonitoringData: function (state, { data, uuid, multipleSelect }) {
    const newMap = multipleSelect ? new Map([...state.monitoringData]) : new Map()
    newMap.set(uuid, data)
    state.monitoringData = newMap
  },
  setSelectedChargerUUID(state, { uuid }) {
    state.selectedChargerUUID = uuid
  },
  setPrevSelectedChargerUUID(state) {
    if (state.monitoringData.size > 0) {
      state.selectedChargerUUID = [...state.monitoringData.keys()].pop()
    }
  },
  addSelected(state, { uuid }) {
    state.selected.push(uuid)
  },
  removedSelected(state, { uuid }) {
    state.selected = state.selected.filter(selected => selected !== uuid)

    if (state.monitoringData.has(uuid)) {
      state.monitoringData.delete(uuid)
      state.monitoringData = new Map([...state.monitoringData])
    }
  },
  clearSelected(state) {
    state.selectedChargerUUID = null
    state.selected = []
    state.monitoringData = new Map()
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
