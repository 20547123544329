<template>
  <div v-if="value.length === 0">
    0 tags
  </div>
  <div
    v-else
    class="TagsCell"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <span>{{ value.length }} tags</span>

    <div
      v-if="hover"
      class="popup"
    >
      <b-badge
        v-for="(tagLabel,index) in tagLabels"
        :key="`tag_${index}`"
      >
        {{ tagLabel }}
      </b-badge>
      <div
        v-if="remaining"
        class="remaining"
      >
        En nog {{ remaining }} tags
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'TagsCell',
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
  },
  data() {
    return {
      maxShown: 4,
      hover: false,
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getLabelsByTagUuid',
    ]),
    remaining() {
      let count = this.value.length
      return count > (this.maxShown + 1) ? (count - this.maxShown) : 0
    },
    tagLabels() {
      let uuids = this.remaining === 0
        ? this.value
        : this.value.slice(0, this.maxShown)

      return uuids.map(uuid => this.getLabelsByTagUuid[uuid])
    },
  },
}
</script>

<style lang="scss">
.TagsCell {
  position: relative;


  .popup {
    position: absolute;
    background: $gray-300;
    border-radius: 4px;
    padding: 0.5rem;
  }
  .remaining {
    display: inline-block;
    white-space: nowrap;
    font-size: 75%;
    font-weight: bold;
    color: $gray-700
  }

}
</style>
