import store from '@/store';
import { getMunicipalityNameByCode } from '@/services/municipalities';

/**
 * Realisation
 */
let Realisation = function ({ ref, data }) {
  data = this.commonStructure({ ref, data });
  data.tenant = 'go-ral';

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key];
  });
};

Realisation.prototype.generateAddressData = function ({ model }) {
  const CurrentLocation = model.CurrentLocation;
  const municipality = getMunicipalityNameByCode({ code: CurrentLocation.Municipality });
  const municipalityName = municipality && `(${municipality})`;

  if (CurrentLocation.Location && CurrentLocation.Location.chargingpointUuid) {
    let location = this.$store.getters['chargingpoints/getChargingPointByUUID']({
      uuid: CurrentLocation.Location.chargingpointUuid
    });
    if (!location) return 'Geen locatie gekozen';

    location = location.data.address;
    let number = `${location.number || ''} ${location.suffix || ''}`.trim();
    return `${location.street || ''} ${number}, ${location.city || ''} ${municipalityName}`.trim().trim(',');
  }

  return 'Geen locatie gekozen';
};

Realisation.prototype.generateSearchData = ({ model, data }) => {
  let municipalityName = '';
  if (data.Municipality) {
    let municipalityOption = store.getters['tenant/getMunicipalityOptions'].find(
      option => option.value === data.Municipality
    );
    if (municipalityOption) {
      municipalityName = municipalityOption.text;
    }
  }

  return {
    City: model.CurrentLocation ? model.CurrentLocation.Location.CityName : '',
    Municipality: municipalityName
  };
};

export default Realisation;
