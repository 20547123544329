<template>
  <div>
    <span v-if="address">
      {{ address }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('tenant', ['isCurrentTenant']),
    ...mapGetters('chargingpoints', ['getChargingPointByUUID']),
    address() {
      if (
        !['go-ral', 'den-hartog', 'dutch-charge', 'power-go', 'orange-charging', 'stella-power', 'mick-e'].includes(process.env.VUE_APP_TENANT)
      ) {
        return this.value;
      }

      try {
        let location = this.getChargingPointByUUID({
          uuid: this.value
        });
        location = location.data.address;
        let number = `${location.number || ''} ${location.suffix || ''}`.trim();
        return `${location.street || ''} ${number}, ${location.city || ''}`.trim().trim(',');
      } catch (e) {
        return '-';
      }
    }
  }
};
</script>

<style></style>
