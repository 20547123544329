import Vue from 'vue';
import File from '@/models/File.js';

const state = {
  filesByUuid: {},
  isLoadingByUuid: {},
  hasFailedByUuid: {}
};

const getters = {
  isFileAvailable:
    state =>
    ({ uuid }) =>
      !!state.filesByUuid[uuid],

  getFileByUuid:
    state =>
    ({ uuid }) =>
      state.filesByUuid[uuid] || null,

  getFileFailedToLoad:
    state =>
    ({ uuid }) =>
      !!state.hasFailedByUuid[uuid] || false,

  getFileIsLoading:
    state =>
    ({ uuid }) =>
      !!state.isLoadingByUuid[uuid] || false
};
const actions = {
  loadFilesByChargingPointRequest: async ({ getters, commit }, { uuid }) => {
    console.log(getters, commit, uuid);
  },

  async loadFileByUuid({ getters, commit }, { uuid }) {
    if (!uuid) return;

    if (getters.isFileAvailable({ uuid })) {
      return;
    }

    if (getters.getFileIsLoading({ uuid })) {
      return;
    }

    commit('setFileIsLoadingByUuid', {
      uuid,
      loading: true
    });

    try {
      const token = await this.$auth.getTokenSilently();
      const api = await fetch(`${process.env.VUE_APP_CBAPI_BASE_URL}/v1/${process.env.VUE_APP_TENANT}/files/${uuid}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'X-API-KEY': process.env.VUE_APP_CBAPI_API_KEY
        }
      }).catch(() => {
        //
      });

      if (!api.ok) {
        commit('setFileHasFailedStatusByUuid', {
          uuid,
          failed: true
        });
        commit('setFileIsLoadingByUuid', {
          uuid,
          loading: false
        });

        return false;
      }

      const response = await api.json();

      commit('setFileByUuid', {
        file: response.data
      });

      if (getters.getFileFailedToLoad({ uuid })) {
        commit('setFileHasFailedStatusByUuid', {
          uuid,
          failed: false
        });
      }

      commit('setFileIsLoadingByUuid', {
        uuid,
        loading: false
      });
    } catch (e) {
      commit('setFileHasFailedStatusByUuid', {
        uuid,
        failed: true
      });
      commit('setFileIsLoadingByUuid', {
        uuid,
        loading: false
      });
    }
  }
};
const mutations = {
  setFileByUuid(state, { file }) {
    Vue.set(state.filesByUuid, file.uuid, new File(file));
  },

  setFileHasFailedStatusByUuid(state, { uuid, failed }) {
    Vue.set(state.hasFailedByUuid, uuid, !!failed);
  },

  setFileIsLoadingByUuid(state, { uuid, loading }) {
    Vue.set(state.isLoadingByUuid, uuid, !!loading);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
