<template>
  <component
    :is="infoComponent"
    v-if="infoComponent !== null"
    :request="request"
  />
  <div v-else>
    Unknown tenant
  </div>
</template>

<script>
import AMInformation from '@/components/request/amsterdam/Information.vue';
import PNCInformation from '@/components/request/park-n-charge/Information.vue';
import GORALInformation from '@/components/request/go-ral/Information.vue';
import OPCInformation from '@/components/request/opcharge/Information.vue';
import VLAInformation from '@/components/request/vlaanderen/Information.vue';
import DHAInformation from '@/components/request/den-hartog/Information.vue';
import DCHInformation from '@/components/request/dutch-charge/Information.vue';
import PWGInformation from '@/components/request/power-go/Information.vue';
import OCHInformation from '@/components/request/orange-charging/Information.vue';
import EVTInformation from '@/components/request/evtools/Information.vue';
import STPInformation from '@/components/request/stella-power/Information.vue';
import MKEInformation from '@/components/request/mick-e/Information.vue';

export default {
  components: {
    PNCInformation,
    GORALInformation,
    OPCInformation,
    AMInformation,
    VLAInformation,
    DHAInformation,
    DCHInformation,
    PWGInformation,
    OCHInformation,
    STPInformation,
    MKEInformation,
    EVTInformation
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    infoComponent() {
      switch (process.env.VUE_APP_TENANT) {
        case 'park-n-charge':
          return 'PNCInformation';
        case 'go-ral':
          return 'GORALInformation';
        case 'den-hartog':
          return 'DHAInformation';
        case 'dutch-charge':
          return 'DCHInformation';
        case 'power-go':
          return 'PWGInformation';
        case 'orange-charging':
          return 'OCHInformation';
        case 'stella-power':
          return 'STPInformation'
        case 'mick-e':
          return 'MKEInformation'
        case 'opcharge':
          return 'OPCInformation';
        case 'amsterdam':
          return 'AMInformation';
        case 'vlaanderen':
          return 'VLAInformation';
        case 'evtools':
          return 'EVTInformation';
      }

      return null;
    }
  }
};
</script>
