<script>
import MultiSelectFilter from './MultiSelectFilter.vue'

// TODO: REMOVE THIS COMPONENT ?
export default {
  name: 'GORalProactiveFilter',
  mixins: [MultiSelectFilter],
  computed: {
    /**
     * Values that are selectable
     */
    enabledOptionValues() {
      return this.filterConfig.options.map(option => option.value)
    },

    labelsByValue() {
      return this.filterConfig.options.reduce((result, option) => {
        result[option.value] = option.text
        return result
      }, {})
    },
  },
  methods: {
    /**
     * Get the label associated with the value
     */
    getLabelByValue({ value }) {
      return this.labelsByValue[value]
    },
  },
}
</script>
