

import { getMunicipalityNameByCode } from '@/services/municipalities'

/**
 * Realisation
 */
let Realisation = function ({
  ref, data,
}) {

  data = this.commonStructure({ ref, data })
  data.tenant = 'opcharge'

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Realisation.prototype.generateSearchData = ({ model }) => { // , data
  return {
    City: model.CurrentLocation ? model.CurrentLocation.Location.CityName: '',
    Municipality: getMunicipalityNameByCode({ code: model.Municipality }) || '',
  }
}


export default Realisation
