import store from '@/store';

/**
 * Realisation
 */
let Realisation = function ({ ref, data }) {
  data = this.commonStructure({ ref, data });
  data.tenant = 'evtools';

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key];
  });
};

Realisation.prototype.generateSearchData = ({ model, data }) => {
  let municipalityName = '';
  if (data.Municipality) {
    let municipalityOption = store.getters['tenant/getMunicipalityOptions'].find(
      option => option.value === data.Municipality
    );
    if (municipalityOption) {
      municipalityName = municipalityOption.text;
    }
  }

  return {
    City: model.CurrentLocation ? model.CurrentLocation.Location.CityName : '',
    Municipality: municipalityName
  };
};

export default Realisation;
