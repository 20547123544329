export const checkStatus = async (response) => {
  if (response.ok) {
    return Promise.resolve(response)
  } else {
    const body = await response.json()

    if (body.error_description) {
      return Promise.reject(new Error(body.error_description))
    }

    if (body.message) {
      return Promise.reject(new Error(body.message))
    }

    return Promise.reject(new Error('no error message'))
  }
}

export const returnJson = (response) => {
  return response.json()
}
