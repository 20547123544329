
/**
 * Amsterdam Realisation
 */

 let Realisation = function ({
  ref, data,
}) {

  data = this.commonStructure({ ref, data })
  data.tenant = 'amsterdam'

  //
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Realisation.prototype.generateMunicipalityData = () => {
  return '0363'
}

Realisation.prototype.generateAddressData = ({ model }) => {
  const CurrentLocation = model.CurrentLocation
  const location = CurrentLocation.Location
  const HouseNumberSuffix = location.HouseNumberSuffix ? ` ${location.HouseNumberSuffix }` : ''

  if (location?.StreetName) {
    return `${location.StreetName} ${(location.HouseNumber + HouseNumberSuffix).trim()}`.replace('  ', ' ')
  }
  return ''
}

Realisation.prototype.generateSearchData = () => {
  return {
    City: 'Amsterdam',
    Municipality: 'amsterdam',
  }
}


export default Realisation
