<template>
  <div v-if="judgementMode">
    <h4 class="mb-3">
      Beoordeling {{ isEditing ? 'herzien' : '' }}:
    </h4>

    <div class="mb-3">
      <b-button
        v-for="judgementStatus in judgementOptions"
        :key="judgementStatus.name"
        class="status"
        size="sm"
        :disabled="busy"
        :variant="judgement === judgementStatus.id ? judgementStatus.variant : 'light'"
        @click="changeJudgementStatus({ status: judgementStatus.id })"
      >
        <b-icon :icon="judgementStatus.icon" />
        {{ judgementStatus.label }}
      </b-button>

      <b-button
        v-if="isEditing"
        key="cancel-editing-judgement"
        v-b-tooltip.hover
        class="status cancel-editing-judgement float-right"
        variant="light"
        size="sm"
        title="annuleren"
        @click="cancelEditingJudgement"
      >
        <b-icon icon="x" />
      </b-button>
    </div>

    <!-- judgement 4: reject request -->
    <template v-if="isJudgementWithReasons">
      <b-form-select
        v-model="reason"
        class="mb-4"
        :options="reasonsForJudgement"
        :select-size="4"
        :disabled="busy"
      />
    </template>
    <template v-if="isJudgementWithReasons && isCustomReason">
      <b-form-textarea
        v-model="remarks"
        :state="remarkIsValid"
        :disabled="busy"
        :placeholder="reasonPlaceholder"
        :trim="true"
        class="mb-3"
      />
    </template>

    <b-alert
      class="mb-3"
      dismissible
      :show="feedback !== ''"
      :variant="feedbackVariant"
      @dismissed="feedback = ''"
    >
      {{ feedback }}
    </b-alert>
    <!-- Show button only if judgment is defined -->
    <b-button
      v-if="judgement"
      class="mt-3 mb-3"
      :disabled="!allowConfirmation || busy"
      size="sm"
      variant="dark"
      @click="confirmJudgementStatus"
    >
      <template v-if="judgement === 5">
        <b-icon icon="trash-fill" />
        <span class="ml-2"> Bevestigen het verwijderen </span>
      </template>
      <template v-else>
        <b-icon icon="envelope" />
        <span class="ml-2"> Bevestig en verstuur de beoordeling </span>
      </template>
    </b-button>
  </div>
</template>

<script>
/**
 * Judgement of a request is a uniform mechanism for all tenants
 *  What differs per tenant is the list of reasons for rejection
 *  Some rejection reasons allow for an explanation (see remark watcher for input validation)
 */

import { multilineUnicodeString, getInvalidCharacters } from '@/services/validation';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Judgement',
  props: {
    request: {
      type: Object,
      required: true
    },
    judgementMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      /**
       * Form state
       **/
      busy: false,
      feedback: '',
      feedbackVariant: 'danger',

      /**
       * Form input
       */
      judgement: null,
      reason: null,
      remarks: '',

      remarkIsValid: null
    };
  },
  computed: {
    ...mapGetters('requests', ['statusOptions', 'statusOptionById', 'reasonOptions']),

    status() {
      return this.statusOptionById({ id: this.request.status });
    },
    isEditing() {
      return this.request.editJudgement;
    },
    isOpen() {
      return this.status.id === 2 || this.isEditing;
    },
    isAccepted() {
      return this.status.id === 3;
    },

    /**
     * Judgement
     */
    judgementOptions() {
      // status 2 = open for judgement
      return this.statusOptions.filter(option => option.id !== 2);
    },

    /**
     * Check whether all conditions are met
     */
    allowConfirmation() {
      // Only accept one of the available judgement options
      if (!this.judgementOptions.find(option => option.id === this.judgement)) {
        return false;
      }

      if (
        this.isEditing &&
        this.judgement === this.request.status && // don't allow re-evaluations with same status
        this.request.status === 4 &&
        this.reason === this.request.judgement.reason // except if reason for rejection has changed
      ) {
        return false;
      }

      return !this.isJudgementWithReasons || (this.isValidReason && (!this.isCustomReason || this.remarkIsValid));
    },
    isValidReason() {
      return !!this.reasonsForJudgement.find(reason => reason.value === this.reason);
    },
    /**
     * Filter the judgement reasons by the selected judgement
     */
    reasonsForJudgement() {
      return this.reasonOptions.filter(reason => (reason.status || null) === this.judgement);
    },
    /**
     * Does the judgement option have a list of reasons to further specify the judgement?
     */
    isJudgementWithReasons() {
      return this.reasonsForJudgement.length !== 0;
    },
    isCustomReason() {
      let reason = this.reasonOptions.find(reason => reason.value === this.reason);
      return reason ? !!reason.custom : false;
    },
    reasonPlaceholder() {
      let reason = this.reasonOptions.find(reason => reason.value === this.reason);
      return reason ? reason.placeholder || 'Een toelichting' : 'Een toelichting';
    }
  },
  watch: {
    remarks(remarks) {
      if (remarks === '') {
        this.remarkIsValid = null;
      } else {
        this.remarkIsValid = remarks.length <= 4000 && multilineUnicodeString(remarks);

        if (!multilineUnicodeString(remarks)) {
          this.feedback = `Uw invoer bevat karakters die omwille van veiligheid niet geaccepteerd worden: ${getInvalidCharacters(
            remarks
          )}`;
        } else if (remarks.length > 4000) {
          this.feedback = `De tekst is te lang! (${remarks.length}/4000 tekens)`;
        } else {
          this.feedback = '';
        }
      }
    }
  },
  methods: {
    ...mapMutations('requests', ['updateRequest', 'replaceRequest']),

    changeJudgementStatus({ status }) {
      this.judgement = status === this.judgement ? null : status;
    },

    /**
     * Update the request
     */
    async confirmJudgementStatus() {
      if (this.allowConfirmation && !this.busy) {
        this.busy = true;

        try {
          // fix: reason with id 0 is evaluated to false
          let reasonLabel = !Number.isNaN(this.reason)
            ? this.reasonOptions.filter(reason => reason.value === this.reason)
            : null;
          reasonLabel = reasonLabel && reasonLabel.length ? reasonLabel[0].short : '';

          const payload = {
            ref: this.request.ref,
            data: {
              isCorrection: this.request.editJudgement,
              judgement: this.judgement,
              judgementLabel: this.statusOptionById({ id: this.judgement }).name,
              reason: this.isJudgementWithReasons ? this.reason : null,
              reasonLabel: this.isJudgementWithReasons ? reasonLabel : '',
              remarks: this.isJudgementWithReasons && this.isCustomReason ? this.remarks : ''
            }
          };
          const token = await this.$auth.getTokenSilently();
          const api = await fetch('/api/judgement', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
          });
          const response = await api.json();

          if (response.statusCode !== 200) {
            throw new Error('Something went wrong');
          }

          // Update the request in the local store
          this.replaceRequest({
            request: response.data.record
          });

          // this.updateRequest({
          //   ref: payload.ref,
          //   data: {
          //     status: {
          //       id: payload.data.judgement,
          //       label: payload.data.judgementLabel
          //     },
          //     judgement: {
          //       isCorrection: this.request.editJudgement,
          //       reason: payload.data.reason,
          //       reasonLabel: payload.data.reasonLabel,
          //       remarks: payload.data.remarks,
          //       processed_at: response.data.timestamp
          //     },
          //   }
          // })

          this.$emit('judgementMode', { mode: false });
        } catch (e) {
          console.log(e);
          this.feedbackVariant = 'danger';
          this.feedback = 'Er ging iets fout. Data niet opgeslagen.';
        }
        this.busy = false;
      }
    },
    cancelEditingJudgement() {
      this.$emit('judgementMode', { mode: false });

      // this.updateRequest({
      //   ref: this.request.ref,
      //   data: {
      //     editJudgement: false,
      //   }
      // })
    }
  }
};
</script>

<style lang="scss">
.cancel-editing-judgement {
  &:hover {
    cursor: pointer;
  }
}
</style>
