<template>
  <div class="Chart Chart--column">
    <div class="Chart__Header">
      <div class="Chart__Header__Icon">
        <!-- <SvgIcon icon="chart-bar-regular" /> -->
      </div>
      {{ title }}
    </div>
    <GChart
      v-if="hasData"
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
    />
    <p
      v-else
      class="my-4"
      style="color: gray;"
    >
      Geen data beschikbaar
    </p>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
// import SvgIcon from "@/components/common/SvgIcon"
import { animationDuration, labelColor, thresholdConfiguration } from '@/helpers/charts'
// import chartMixins from "@/mixins/monitoring/chartMixins"
import { wholeNumber } from '@/helpers/number'
import GoogleChartEntry from '@/models/GoogleChartEntry'

export default {
  name: 'ColumnChart',
  components: { GChart }, // SvgIcon,
  // mixins: [ chartMixins ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        limit: .6,
      }),
    },
  },
  computed: {
    hasData(){
      return Object.keys(this.data).length > 0
    },
    chartOptions() {
      return {
        legend: { position: 'none' },
        hAxis: { textStyle: { bold: true, color: labelColor } },
        vAxis: { format: 'percent', textStyle: { bold: true, color: labelColor }, viewWindow: { max: 1, min: 0 } },
        bar: { groupWidth: '60%' },
        height: 140,
        width: 266,
        chartArea: { left: 30, top: 10, right: 20 },
        series: {
          1: thresholdConfiguration,
        },
        animation: {
          duration: animationDuration,
        },
        tooltip: { isHtml: true },
        ignoreBounds: true,
      }
    },
    chartData() {
      /*
      * role: 'annotation' = text on bar
      * role: 'tooltip', first for tooltip of the bars, second for tooltip of the threshold line
      */
      let chartSerieLabels = [
        'Maand',
        this.title,
        { role: 'annotation' },
        { role: 'tooltip', p: { html: true } },
        { role: 'style' },
        'Limiet',
        { role: 'tooltip', p: { html: true } },
      ]

      const result = [
        chartSerieLabels,
      ]

      const metricsByMonth = this.data
      const metrics = Object.entries(metricsByMonth).sort((metrics1, metrics2) => {
        const monthMetrics1 = parseInt(metrics1[0].split('-')[1])
        const monthMetrics2 = parseInt(metrics2[0].split('-')[1])
        return monthMetrics1 - monthMetrics2
      }).map(arr => arr[1])

      metrics
        .forEach((entry) => {
          const wholeNumberValue = wholeNumber(entry.occupancy_rate * 100)

          const preppedEntry = GoogleChartEntry.create(
              entry.label,
              entry.occupancy_rate,
              `${wholeNumberValue}%`,
              `<strong>${entry.label}</strong> <br /> Bezettingsgraad: <strong>${wholeNumberValue}%</strong>`,
            )

          preppedEntry.addLimit(this.options.limit, `${wholeNumber(this.options.limit * 100)}%`)

          result.push(
            preppedEntry.toArrayWithLimit(),
          )
        })

      return result
    },
  },
}
</script>
