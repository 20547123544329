export const chargingPointStatuses = ({ status }) => {
  if (!status) return
    return {
      'alert': 'Aandachtspunt',
      'rejected': 'Afgekeurd',
      'rejected-private': 'Afgekeurd privaat',
      'suggestion': 'Voorgesteld',
      'suggestion-private': 'Voorgesteld privaat',
      'definitive': 'Gevalideerd',
      'definitive-private': 'Gevalideerd privaat',
      'in-progress': 'In voorbereiding',
      'in-progress-private': 'In voorbereiding privaat',
      'realized': 'Gerealiseerd',
      'realized-private': 'Gerealiseerd privaat',
      'realized-proprietary': 'Gerealiseerd (eigen)',
      'realized-private-proprietary': 'Gerealiseerd privaat (eigen)',
    }[status.trim()] || 'onbekend'
  }
