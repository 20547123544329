<template>
  <b-overlay
    v-if="layout"
    :show="isBusy"
    class="Table"
    :style="tableStyle"
  >
    <TableControls />

    <template v-if="visualisation === 'table'">
      <div
        ref="filters"
        :class="['filters-container', { 'sticky': hasActiveFilters, 'elevated': isElevated }]"
      >
        <TableFilters @refresh="refresh" />
      </div>
      <b-list-group
        v-if="visibleEntries.length"
      >
        <TableHeader />
        <TableRow
          v-for="entry in visibleEntries"
          :key="entry.uuid"
          :record-data="entry"
        />
      </b-list-group>

      <div v-else>
        Er zijn geen resultaten die voldoen aan uw criteria.
      </div>

      <div
        v-if="resultCount > pageSize"
        class="mt-3"
      >
        <b-pagination
          class="pagination"
          :value="currentPage"
          :total-rows="resultCount"
          :per-page="pageSize"
          align="center"
          @change="setCurrentPage"
        />
      </div>
    </template>

    <template v-else-if="visualisation === 'slot'">
      <slot />
    </template>
  </b-overlay>
</template>

<script>


import TableControls from './TableControls.vue'
import TableFilters from './TableFilters'
import TableHeader from './TableHeader.vue'
import TableRow from './TableRow.vue'

import TableState from './mixins/TableState.vue'
import FilterLogic from './mixins/FilterLogic.vue'

export default {
  name: 'Table',
  components: {
    TableControls,
    TableFilters,
    TableHeader,
    TableRow,
  },
  mixins: [TableState, FilterLogic],
  props: {
    /**
     * Whether records are being (asynchronously) loaded
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // TODO: Show spinner after 75 ms sort / filter / refresh actions, for a minimum of 300 ms
      // busy: true,

      /**
       * Current index page
       */
      currentPage: 1,

      /**
       * Sorted and filtered records
       */
      filteredAndSortedEntries: [],
      isElevated: false,
    }
  },
  computed: {
    hasActiveFilters () {
      return this.activeFilters.filter(filter => !filter.hidden).length
    },
    /**
     * Loading records is 1
     */
    isBusy() {
      return this.loading
    },
    /**************************************************************************
     * Sorting
     */
    sortingOptions() {
      // doesn't do anything, except force recompute
      let sortingOptions = this.activeSorting
        .filter(sortingOption => sortingOption.direction !== null)

      /**
       * Obtain applicable sorting configurations
       */
      sortingOptions = sortingOptions.map(config => {
        return {
          property: config.property,
          direction: config.direction,
          sortBy: this.getSortingOptionsByProperty({ property: config.property }),
        }
      })

      let defaultSorting = this.getSortingOptionsByProperty({ property: 'default' })

      /**
       * Always add default sorting as fallback
       */
      sortingOptions.push({
        property: 'default',
        direction: defaultSorting.direction || 'down',
        sortBy: defaultSorting,
      })

      return sortingOptions
    },

    /**************************************************************************
     * Pagination
     */

    /**
     * Number of results, after filtering
     */
    resultCount() {
      return this.filteredAndSortedEntries.length
    },

    /**
     * Entries visible on the current page
     */
    visibleEntries() {
      if (this.filteredAndSortedEntries.length === 0) {
        return this.filteredAndSortedEntries
      }
      return this.filteredAndSortedEntries
        .slice(
          (this.currentPage - 1) * this.pageSize,
          this.currentPage * this.pageSize,
        )
    },


    /**************************************************************************
     * Configuration
     */
    tableStyle() {
      let styles = {
        'min-width': this.config.minWidth || '920px', // 960 - 40 scrollbar
      }
      if (this.config.maxWidth) {
        styles['max-width'] = this.config.maxWidth
      }

      return styles
    },

    visualisation() {
      return this.config.visualisation || 'table'
    },

    /**
     * Number of visible rows
     */
    pageSize() {
      return this.settings.pageSize // TODO: turn into setting
    },

    /**
     * Calculate the number of pages
     *  used to check whether currentPage is still visiible
     */
    pageCount() {
      return Math.ceil(this.filteredAndSortedEntries.length / this.pageSize)
    },
  },
  watch: {
    /**
     * Refresh the table presentation
     */
    activeSorting: {
      deep: true,
      handler() {
        this.refresh()
      },
    },
    // Refresh only if on record view when filtering is used (goral e.g) //
    activeFilters: {
      handler() {
        this.filterAndRefresh()
      },
    },
    /**
     * If the currentPage is set beyond the max page, reset to 1
     *
     *  The currentPage is reset on every recalculation of filteredAndSortedEntries,
     *  but that does not include changes to pageSize.
     *
     *  This watcher is used to account for that variable,
     *  and anything we may have missed.
     */
    pageCount() {
      if (this.pageCount < this.currentPage) {
        this.currentPage = 1
      }
    },
    /**
     * Re-render when the config changes
     */
    config() {
      this.filterAndRefresh()
    },
  },
  mounted() {
    if (this.config) {
      this.filterAndRefresh()
    }
  },
  methods: {
    /**
     * When the records are renewed, we have to re-evaluate them by the active filters
     */
    filterAndRefresh() {
      this.applyFilters()
      this.$nextTick(() => {
        this.refresh()
      })
    },

    /**
     * Refresh the sorted and filtered records
     *  Has to be triggered 'manually' to avoid unintended waste of computing power
     */
    refresh() {
      /**
       * Sending out a signal
       */
      this.$emit('refresh', {
        filters: this.tableState.activeFilters,
        sorting: this.tableState.activeSorting,
        settings: this.tableState.settings,
      })


      let totals = null
      let filtered = null
      let filteredRecords = this.tableState.records
        .slice() // avoid manipulating original array

        // First apply filter results
        .filter(record => record.filteredOut !== true)


      // The input data may be translated after filtering
      if (this.config.dataPresentationMapper) {
        filtered = this.config.dataPresentationMapper({
          records: filteredRecords,
        })
      } else {
        filtered = filteredRecords
      }

      if (this.config.totalMapper) {
        totals = this.config.totalMapper({
          records: filteredRecords,
          filtered,
        })
      }

      //
      let filteredAndSorted = filtered
        // then sort the remaining records
        .sort(
          this.multiSort({ sortingOptions: this.sortingOptions }),
        )

      if (totals) {
        filteredAndSorted.push(totals)
      }

      this.filteredAndSortedEntries = filteredAndSorted

      // Reset the page to the 1st
      // pageCount watcher is right most of the time, but cannot catch all factors.
      this.currentPage = 1
    },

    /**
     * Sort by multiple fields
     *  Uses every to exit as soon as there is a sorting result
     */
    multiSort({ sortingOptions }) {
      return (a, b) => {
        let finalResult = null

        const sortByOption = option => {
          let result = null
          let type = option.sortBy.type
          let property = option.sortBy.sortBy

          if (type === 'string') {
            result = a[property]?.localeCompare(b[property])
          } else {
            result = ( a[property] - b[property] )
          }

          if (result !== 0 && option.direction === 'down') {
            result = -1 * result
          }

          finalResult = result

          return result === 0
        }

        // exit on first conclusive sorting result
        sortingOptions.every(sortByOption)

        return finalResult
      }
    },

    /**
     * Set the current page
     */
    setCurrentPage(page) {
      this.currentPage = page
    },

    /**
     * Get sorting options
     */
    getSortingOptionsByProperty({ property }) {
      return this.config.sorting[property] || null
    },
  },
}
</script>

<style lang="scss">
.Table {
  display: grid;
  margin: 0 auto;

  .pagination {
    .page-link {
      color: #212529;
    }

    .page-item.active .page-link {
      color: white;
      background-color: #212529 !important;
    }
  }
}
.filters-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: white;
  border-bottom: none;
  padding: 1em 1em 0;
  &.sticky {
    position: sticky;
    top: 2.2em;
    z-index: 3;
    padding-bottom: 0.5em;
    &.elevated {
      box-shadow: rgb(17 17 26 / 10%) 0px 2px 8px;
    }
  }
}
</style>
