import store from '@/store'
const isVlaanderen = process.env.VUE_APP_TENANT === 'vlaanderen'

const primaryGridOperatorByMunicipalityCode = {
  '1507': 'Enexis',
  '1509': 'Liander',
  '1525': 'Liander',
  '1581': 'Stedin',
  '1586': 'Liander',
  '1598': 'Liander',
  '1621': 'Stedin',
  '1640': 'Enexis',
  '1641': 'Enexis',
  '1652': 'Enexis',
  '1655': 'Enexis',
  '1658': 'Enexis',
  '1659': 'Enexis',
  '1667': 'Enexis',
  '1669': 'Enexis',
  '1674': 'Enexis',
  '1676': 'DNWG (Stedin)',
  '1680': 'Enexis',
  '1681': 'Enexis',
  '1684': 'Enexis',
  '1685': 'Enexis',
  '1690': 'Enexis',
  '1695': 'DNWG (Stedin)',
  '1696': 'Liander',
  '1699': 'Enexis',
  '1700': 'Enexis',
  '1701': 'Enexis',
  '1702': 'Enexis',
  '1705': 'Liander',
  '1706': 'Enexis',
  '1708': 'Enexis',
  '1709': 'Enexis',
  '1711': 'Enexis',
  '1714': 'Stedin',
  '1719': 'Enexis',
  '1721': 'Enexis',
  '1723': 'Enexis',
  '1724': 'Enexis',
  '1728': 'Enexis',
  '1729': 'Enexis',
  '1730': 'Enexis',
  '1731': 'Enexis',
  '1734': 'Liander',
  '1735': 'Enexis',
  '1740': 'Liander',
  '1742': 'Enexis',
  '1771': 'Enexis',
  '1773': 'Enexis',
  '1774': 'Enexis',
  '1783': 'Westland',
  '1842': 'Westland',
  '1859': 'Liander',
  '1876': 'Liander',
  '1883': 'Enexis',
  '1884': 'Liander',
  '1891': 'Liander',
  '1892': 'Stedin',
  '1894': 'Enexis',
  '1895': 'Enexis',
  '1896': 'Enexis',
  '1900': 'Liander',
  '1901': 'Stedin',
  '1903': 'Enexis',
  '1904': 'Stedin',
  '1911': 'Liander',
  '1916': 'Stedin',
  '1924': 'DNWG (Stedin)',
  '1926': 'Stedin',
  '1930': 'Stedin',
  '1931': 'Stedin',
  '1940': 'Liander',
  '1942': 'Liander',
  '1945': 'Liander',
  '1948': 'Enexis',
  '1949': 'Liander',
  '1950': 'Enexis',
  '1952': 'Enexis',
  '1954': 'Enexis',
  '1955': 'Liander',
  '1959': 'Enexis',
  '1960': 'Liander',
  '1961': 'Stedin',
  '1963': 'Stedin',
  '1966': 'Enexis',
  '1969': 'Enexis',
  '1970': 'Liander',
  '1978': 'Stedin',
  '1979': 'Enexis',
  '0677': 'Stedin',
  '0352': 'Stedin',
  '0279': 'Liander',
  '0344': 'Stedin',
  '0632': 'Stedin',
  '0880': 'Liander',
  '0189': 'Enexis',
  '0302': 'Liander',
  '0715': 'Stedin',
  '0537': 'Liander',
  '0358': 'Liander',
  '0824': 'Enexis',
  '0523': 'Stedin',
  '0547': 'Liander',
  '0317': 'Stedin',
  '0301': 'Liander',
  '0664': 'Stedin',
  '0299': 'Liander',
  '0233': 'Liander',
  '0717': 'DNWG (Stedin)',
  '0180': 'Enexis',
  '0153': 'Enexis',
  '0327': 'Stedin',
  '0086': 'Liander',
  '0845': 'Enexis',
  '0014': 'Enexis',
  '0935': 'Enexis',
  '0678': 'Stedin',
  '0277': 'Liander',
  '0513': 'Stedin',
  '0098': 'Liander',
  '0928': 'Enexis',
  '0847': 'Enexis',
  '0164': 'Enexis',
  '0861': 'Enexis',
  '0216': 'Liander',
  '0762': 'Enexis',
  '0406': 'Liander',
  '0451': 'Liander',
  '0385': 'Liander',
  '0268': 'Liander',
  '0243': 'Liander',
  '0294': 'Liander',
  '0882': 'Enexis',
  '0106': 'Enexis',
  '0177': 'Enexis',
  '0637': 'Stedin',
  '0285': 'Liander',
  '0203': 'Liander',
  '0606': 'Stedin',
  '0200': 'Liander',
  '0744': 'Enexis',
  '0246': 'Liander',
  '0737': 'Liander',
  '0267': 'Liander',
  '0373': 'Liander',
  '0439': 'Liander',
  '0356': 'Stedin',
  '0856': 'Enexis',
  '0530': 'Stedin',
  '0420': 'Liander',
  '0405': 'Liander',
  '0479': 'Liander',
  '0629': 'Liander',
  '0840': 'Enexis',
  '0546': 'Liander',
  '0917': 'Enexis',
  '0363': 'Liander',
  '0289': 'Liander',
  '0088': 'Liander',
  '0335': 'Stedin',
  '0209': 'Liander',
  '0399': 'Liander',
  '0743': 'Enexis',
  '0377': 'Liander',
  '0614': 'Stedin',
  '0597': 'Stedin',
  '0313': 'Stedin',
  '0141': 'Coteq Netbeheer',
  '0501': 'Stedin',
  '0766': 'Enexis',
  '0310': 'Stedin',
  '0060': 'Liander',
  '0230': 'Liander',
  '0096': 'Liander',
  '0307': 'Stedin',
  '0114': 'Enexis',
  '0946': 'Enexis',
  '0384': 'Liander',
  '0858': 'Enexis',
  '0569': 'Liander',
  '0603': 'Stedin',
  '0362': 'Liander',
  '0512': 'Stedin',
  '0312': 'Stedin',
  '0118': 'Enexis',
  '0965': 'Enexis',
  '0375': 'Liander',
  '0668': 'Liander',
  '0232': 'Liander',
  '0589': 'Stedin',
  '0907': 'Enexis',
  '0489': 'Stedin',
  '0331': 'Stedin',
  '0784': 'Enexis',
  '0397': 'Stedin',
  '0275': 'Liander',
  '0090': 'Liander',
  '0183': 'Enexis',
  '0848': 'Enexis',
  '0166': 'Enexis',
  '0994': 'Enexis',
  '0638': 'Liander',
  '0308': 'Stedin',
  '0415': 'Liander',
  '0531': 'Stedin',
  '0826': 'Enexis',
  '0983': 'Enexis',
  '0484': 'Liander',
  '0855': 'Enexis',
  '0417': 'Liander',
  '0222': 'Liander',
  '0437': 'Liander',
  '0226': 'Liander',
  '0938': 'Enexis',
  '0150': 'Enexis',
  '0753': 'Enexis',
  '0197': 'Liander',
  '0441': 'Liander',
  '0119': 'Enexis',
  '0758': 'Enexis',
  '0085': 'Liander',
  '0502': 'Stedin',
  '0361': 'Liander',
  '0765': 'Enexis',
  '0355': 'Stedin',
  '0777': 'Enexis',
  '0575': 'Liander',
  '0263': 'Liander',
  '0168': 'Enexis',
  '0796': 'Enexis',
  '0416': 'Liander',
  '0599': 'Stedin',
  '0262': 'Liander',
  '0171': 'Liander',
  '0432': 'Liander',
  '0703': 'Stedin',
  '0059': 'Liander',
  '0398': 'Liander',
  '0851': 'Enexis',
  '0147': 'Enexis',
  '0865': 'Enexis',
  '0534': 'Liander',
  '0184': 'Liander',
  '0202': 'Liander',
  '0867': 'Enexis',
  '0047': 'Enexis',
  '0457': 'Liander',
  '0431': 'Liander',
  '0986': 'Enexis',
  '0273': 'Liander',
  '0269': 'Liander',
  '0866': 'Enexis',
  '0034': 'Liander',
  '0037': 'Enexis',
  '0074': 'Liander',
  '0394': 'Liander',
  '0376': 'Liander',
  '0193': 'Enexis',
  '0473': 'Liander',
  '0353': 'Stedin',
  '0957': 'Enexis',
  '0756': 'Enexis',
  '0221': 'Liander',
  '0297': 'Liander',
  '0553': 'Liander',
  '0786': 'Enexis',
  '0610': 'Stedin',
  '0214': 'Liander',
  '0828': 'Enexis',
  '0163': 'Enexis',
  '0050': 'Liander',
  '0889': 'Enexis',
  '0453': 'Liander',
  '0175': 'Enexis',
  '0448': 'Liander',
  '0579': 'Liander',
  '0213': 'Liander',
  '0590': 'Stedin',
  '0072': 'Liander',
  '0785': 'Enexis',
  '0944': 'Enexis',
  '0345': 'Stedin',
  '0450': 'Liander',
  '0281': 'Liander',
  '0626': 'Liander',
  '0293': 'Liander',
  '0988': 'Enexis',
  '0879': 'Enexis',
  '0748': 'Enexis',
  '0820': 'Enexis',
  '0296': 'Liander',
  '0148': 'Enexis',
  '0687': 'Stedin',
  '0340': 'Stedin',
  '0888': 'Enexis',
  '0716': 'DNWG (Stedin)',
  '0402': 'Liander',
  '0400': 'Liander',
  '0797': 'Enexis',
  '0809': 'Enexis',
  '0252': 'Liander',
  '0228': 'Liander',
  '0505': 'Stedin',
  '0109': 'Enexis',
  '0080': 'Liander',
  '0392': 'Liander',
  '0396': 'Liander',
  '0718': 'Stedin',
  '0498': 'Liander',
  '0303': 'Liander',
  '0339': 'Stedin',
  '0984': 'Enexis',
  '0503': 'Stedin',
  '0654': 'Stedin',
  '0995': 'Liander',
  '0798': 'Enexis',
  '0383': 'Liander',
  '0873': 'Enexis',
  '0173': 'Coteq Netbeheer',
  '0736': 'Stedin',
  '0642': 'Stedin',
  '0093': 'Liander',
  '0342': 'Stedin',
  '0815': 'Enexis',
  '0971': 'Enexis',
  '0351': 'Stedin',
  '0823': 'Enexis',
  '0794': 'Enexis',
  '0532': 'Liander',
  '0556': 'Stedin',
  '0160': 'Enexis',
  '0274': 'Liander',
  '0755': 'Enexis',
  '0542': 'Stedin',
  '0518': 'Stedin',
  '0622': 'Stedin',
  '0981': 'Enexis',
  '0772': 'Enexis',
  '0225': 'Liander',
  '0899': 'Enexis',
  '0627': 'Stedin',
  '0770': 'Enexis',
  '0158': 'Enexis',
  '0779': 'Enexis',
  '0370': 'Liander',
  '0244': 'Liander',
  '0482': 'Stedin',
  '0613': 'Stedin',
  '0757': 'Enexis',
  '0893': 'Enexis',
  '0321': 'Stedin',
  '0852': 'Liander',
  '0388': 'Liander',
  '1982': 'Enexis'
}

// Source: https://totalenergies.be/nl/particulieren/hulp-en-contact/veelgestelde-vragen/elektriciteit-aardgas/mijn-netwerk/de-netbeheerders/wie-mijn-netbeheerder //
// Brussels Hoofdstedelijk Gewest gemeentes //
const municipalityCodesBrussel = [
  '21004', // Brussel
  '21015', // Schaarbeek
  '21005', // Etterbeek
  '21009', // Elsene
  '21013', // Sint-Gillis
  '21001', // Anderlecht
  '21012', // Sint-Jans-Molenbeek
  '21011', // Koekelberg
  '21003', // Sint-Agatha-Berchem
  '21008', // Ganshoren
  '21010', // Jette
  '21006', // Evere
  '21019', // Sint-Pieters-Woluwe
  '21002', // Oudergem,
  '21017', // Watermaal-Bosvoorde
  '21016', // Ukkel
  '21007', // Vorst
  '21018', // Sint-Lambrechts-Woluwe
  '21014', // Sint-Joost-ten-Node
]
// TODO Make separate gridOperators imports or place them in config: excessive render of objects //
export const getGridOperatorByMunicipalityCode = ({ code }) => {
  const vlaanderenOP = municipalityCodesBrussel.includes(code) ? 'Sibelga' : code.length > 4 ? 'Fluvius' : ''

  return  vlaanderenOP || primaryGridOperatorByMunicipalityCode[code] || ''
}

export function getGridOperators () {
  if (isVlaanderen) {
    return [
      { text: 'Sibelga', value: 'Sibelga' },
      { text: 'Fluvius', value: 'Fluvius' },
    ]
  }
  const municipalityOptions = store.getters['tenant/getMunicipalityOptions']
  const operators = new Set()
  municipalityOptions.map(option => {
    operators.add(primaryGridOperatorByMunicipalityCode[option.value])
  })

  return Array.from(operators).map(operator => ({ text: operator, value: operator }))
}
