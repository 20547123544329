<template>
  <div>
    <div
      class="TableFilters"
      :style="filterStyle"
    >
      <div
        v-for="(filter,index) in filtersWithComponents"
        :key="`filter_${index}`"
        :style="{'grid-area' : filter.id }"
      >
        <component
          :is="filter.componentName"
          :filter-config="filter"
        />
      </div>
    </div>
    <component :is="TableActiveFilters" />
  </div>
</template>

<script>

import {
  loadAllComponentsFromFolder,
} from './utils.js'

import TableState from './mixins/TableState.vue'

export default {
  name: 'TableFilters',
  components: loadAllComponentsFromFolder({
    ComponentContext: require.context('@/components/Table/filters', true, /\.vue$/i),
  }),
  mixins: [TableState],
  data () {
    return {
      TableActiveFilters: () => import('./TableActiveFilters.vue'),
    }
  },
  computed: {
    filtersWithComponents() {
      return this.filterLayout.filters
        .map(property => this.config.filters[property] || {})
        .filter(filter => filter.componentName !== null)
    },

    /**************************************************************************
     * Layout
     */

    /**
     * @type {object}
     */
    filterLayout() {
      return Object.assign({
        columns: 'auto',
        area: '',
        gap: '8px',
      }, (this.layout.filters || {}))
    },

    /**
     * @type {object}
     */
    filterStyle() {
      return {
        'grid-template-columns': this.filterLayout.columns,
        'grid-template-areas': this.filterLayout.area,
        'gap': this.filterLayout.gap,
        'padding-bottom': '1em',
      }
    },
  },
}
</script>

<style lang="scss">
.TableFilters {
  display: grid;
  grid-template-rows: auto;

  label {
    margin-bottom: 0;
    font-size: 80%;
  }
}
// .filters-container {
//   border: 1px solid rgba(0, 0, 0, 0.125);
//   background: white;
//   border-bottom: none;
//   padding: 1em 1em 0;
//   &.sticky {
//     position: sticky;
//     top: 2.2em;
//     z-index: 3;
//     padding-bottom: 0.5em;
//   }
// }
</style>
