
/**
 * A Location is part of Realisation record
 *  One Realisation record can hold multiple Locations
 */
let Location = function({
  data, copy,
}) {
  let model = {}

  // short circuit making copies
  if (copy || this.LooksAndQuacksLikeCopy({ data })) {
    model = this.makePlainCopy({ data })
  } else {

    model = this.commonStructure({ data })

    /**
     * Tenant specific fields
     */
    model.ChargePointOperator = 'Den Hartog Charging' // TODO: must be a select?
    model.GridOperator = data.GridOperator || ''
    model.Municipality = data.MunicipalityCode || data.Municipality || null
    model.ProcessType = data.ProcessType || null
    model.FinUnit = ''
  }

  //
  Object.keys(model).forEach(key => {
    this[key] = model[key]
  })
}


Location.prototype.LocationCopy = function({ version }){
  let oldData = JSON.parse(JSON.stringify(this))

  let data = {
    Location: oldData.Location,
    Remarks: oldData.Remarks,
    ChargePointOperator: oldData.ChargePointOperator,
    GridOperator: oldData.GridOperator,
    ParkingSpots: oldData.ParkingSpots,
    Contractor: oldData.Contractor,
    CPOAdvice: {
      SumAdditionalCost: oldData.CPOAdvice.SumAdditionalCost || '',
      AdditionalCostDescription: oldData.CPOAdvice.AdditionalCostDescription || '',
    },
    LocationPreparation: {
      RequiresLocationPreparation: oldData.LocationPreparation.RequiresLocationPreparation || [],
      OtherReason: oldData.LocationPreparation.OtherReason || '',
    },
    Docs: {
      ParkingSpots: oldData.Docs.ParkingSpots || [],
      // Underground: oldData.Docs.Underground || [],
      CPOAdditional: oldData.Docs.CPOAdditional || [],
    },
    FinUnit: oldData.FinUnit,
    Municipality: oldData.Municipality,
    ProcessType: oldData.ProcessType,
  }

  data.version = version

  return new Location({ data, copy: true })
}


export default Location
