const RECORD_TYPE = {
  REQUEST: 'WorkflowChargerRequests',
  PROCESS: 'Processes',
  ASSETS: 'Assets'
};

const RECORD_TYPES = Object.values(RECORD_TYPE);

module.exports = {
  RECORD_TYPE,
  RECORD_TYPES
};
