<template>
  <div style="display:none" />
</template>

<script>
/* eslint-disable */

import MapBoxMixin from '@/components/common/MapBoxMixin'
import { mapGetters } from 'vuex'
import { uniqueIntId } from "@/helpers/number";

/**
 * This layer loads and shows the charging points
 *  For now no interaction is included
 */
export default {
  name: 'LayerChargingpoints',
  mixins: [ MapBoxMixin ],
  props: {
    filters: {
      type: Array,
      required: false,
      default: null
    },
    coordinates: {
      type: Object,
      default: () => {}
    },
    municipalityCode: {
      type: String,
      default: ''
    },
    monitoring: {
      type: Boolean,
      default: false
    },
    showMapItems: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iconCounter: 0,
      requiredIcons: [
        'realized',
        'realized-private',
        'realized-private-proprietary',
        'realized-proprietary',
        'alert',
        'suggestion',
        'suggestion-private',
        'definitive',
        'definitive-private', 
        'rejected',
        'rejected-private',
        'in-progress',
        'in-progress-private',
        'suggestion-alternative',
        'definitive-alternative',
        'suggestion-private-alternative',
        'definitive-private-alternative'
      ],
      chargingpointsSet: new Set(),
      currentMunicipality: null
    }
  },
  computed: {
    ...mapGetters('chargingpoints', ['chargingpoints', 'getInitialChargingpoints', 'chargingpointsByCode', 'getActiveFilterItems']),
    ...mapGetters('processes', ['getSelectedProcess']),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },
    // Update location status upon CPOAdvice change
    selectedChargingPoint() {
      return this.coordinates
        ? this.chargingpoints.find(point => {
          return point.data.coordinates[0] === this.coordinates.lng &&
                 point.data.coordinates[1] === this.coordinates.lat
        })
        : false
    }
  },
  watch: {
    /**
     * React to changes in filters
     */
    filters() {
      this.setFilter()
    },

    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.createActiveFilterItems()
          this.updateOrAddLayer()
      }
    },

    /**
     * When the charginpoints list changes we reload the layer
     */
    chargingpoints (val) {
      if (this.iconsLoaded) {
        this.updateOrAddLayer()
      }
    },

    selectedChargingPoint () {
      this.updateOrAddLayer()
    },

    municipalityCode: {
      immediate: true,
      handler () {
        if (this.iconsLoaded) {
          this.createActiveFilterItems()
          this.updateOrAddLayer()
        }
      }
    },
    showMapItems: {
      immediate: true,
      handler (show) {
        this.updateOrAddLayer()
      }
    }
  },
  created() {
    if (this.loaded) {
      this.loadIcons()
    }
  },
  methods: {
    createActiveFilterItems () {
      if (this.municipalityCode && this.municipalityCode !== this.getActiveFilterItems.municipalityCode) {
        this.getInitialChargingpoints.map(point => {
          if (point.data.isWorkflowAltLocation) {
            if (!point.data.properties.status.endsWith('alternative')) {
              point.data.properties.status += '-alternative'
            }
            this.chargingpointsSet.add('isWorkflowAltLocation')
          } else {
            this.chargingpointsSet.add(point.data.properties.status)
          }
        })

        this.$store.dispatch('chargingpoints/loadActiveFilterItems', 
          { 
            filterType: 'chargingpoints',
            filterItems: Array.from(this.chargingpointsSet),
            municipalityCode: this.municipalityCode
          }
        )
        this.currentMunicipality = this.municipalityCode
      }
    },
    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        const icon = name.trim()
        this.map.loadImage(
          require(`@/assets/image/legend/chargingpoint-${icon}.png`),
          (err, image) => {
            if (err) throw err;
            if (! this.map.hasImage(`chargingpoint-${icon}`)) {
              this.map.addImage(`chargingpoint-${icon}`, image);
            }
            this.iconCounter++
        })
      })
    },

    /**
     * Translate datapoints into GeoJSON
     */
    generateGeoJson({ chargingpoints }) {
      chargingpoints = Array.isArray(chargingpoints) ? chargingpoints : []
      if (this.monitoring) {
        chargingpoints = chargingpoints.filter(point => {
          return ['realized', 'in-progress'].includes(point.data.properties.status) ||
                 point.data.properties.status === 'definitive' && point.data.workflowUuid
        })
      }

      this.chargingpointsSet.clear()
      
      const featureCollection = {
        "type": "FeatureCollection",
        "features": chargingpoints.map(point => {

          return {
            "id": uniqueIntId({chargingpoint: point}),
            "type": "Feature",
            "properties": Object.assign(
              point.data.properties, {
              refId: point.ref['@ref']?.id || point.ref.value?.id,
              uuid: point.data.uuid || null,
              proactive: point.getFullPaseValue() || 'none'
            }),
            "geometry": {
              "type": "Point",
              "coordinates": point.data.coordinates
            }
          }
        })
      }
    
      return featureCollection
    },

    /**
     * If there already is a layer, only update the dataset that feeds it
     */
    updateOrAddLayer() {
      if (! this.map) return 

      let source = this.map.getSource('chargingpoints')
      if (source) {
        this.updateLayer()
      } else {
        this.addLayer()
      }
    },

    /**
     * Update the source data of the layer
     */
    updateLayer() {
      if (!this.map) return

      const source = this.map.getSource('chargingpoints')
      if (! source) return 

      const isNewProcess = this.$route.name.startsWith('new-')
      const boundToProcess = !isNewProcess && this.chargingpoints.find(cp => cp.data.workflowUuid === this.getSelectedProcess?.uuid)

      source.setData(this.generateGeoJson({ 
        chargingpoints: this.showMapItems
          ? this.chargingpoints : boundToProcess ? [boundToProcess] : []
      }))
    },

    /**
     * Load the chargingpoint layer
     */
    addLayer() {

      if (! this.map) return
      
      // Clean up first if we must
      let source = this.map.getSource('chargingpoints')
      if (source) {
        if (this.map.getLayer('chargingpoints')) {
          this.map.removeLayer('chargingpoints')
        }
        if (this.map.getLayer('chargingpoints-text')) {
          this.map.removeLayer('chargingpoints-text')
        }
        
        this.map.removeSource('chargingpoints')
      }
     
      // Add the generated source 
      this.map.addSource('chargingpoints', {
        type: 'geojson',
        data: this.generateGeoJson({ 
          chargingpoints: this.chargingpoints
        })
      })
      // if (this.municipalityCode) {
      //   this.$emit('activeFilterTypes', { type: 'chargingpoints', value: Array.from(this.chargingpointsSet) })
      // }

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['locationpin', 'altlocationpin'] // 'realisation-processes', 'realisation-processes-text', 'requests', 'requests-text', 
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      /**
       * The marker icon layer
       */
      this.map.addLayer({ 
        'id': 'chargingpoints',
        'type': 'symbol',
        'source': 'chargingpoints',
        'minzoom': 10,
        'layout': {
          'symbol-sort-key': 1,
          'symbol-placement': 'point',
          'symbol-z-order': 'source',
          'icon-allow-overlap': true,
          'icon-anchor': 'bottom',
          'icon-image': [
            'match', 
            ['get', 'status'], 
            'definitive', 'chargingpoint-definitive',
            'definitive-private', 'chargingpoint-definitive-private',
            'rejected', 'chargingpoint-rejected',
            'rejected-private', 'chargingpoint-rejected-private',
            'in-progress', 'chargingpoint-in-progress',
            'in-progress-private', 'chargingpoint-in-progress-private',
            'realized', 'chargingpoint-realized',
            'realized-proprietary', 'chargingpoint-realized-proprietary',
            'realized-private', 'chargingpoint-realized-private',
            'realized-private-proprietary', 'chargingpoint-realized-private-proprietary',
            // 'fastcharger-realized', 'chargingpoint-fastcharger-realized',
            // 'fastcharger-planned', 'chargingpoint-fastcharger-planned',
            'suggestion', 'chargingpoint-suggestion',
            'suggestion-private', 'chargingpoint-suggestion-private',
            'suggestion-alternative', 'chargingpoint-suggestion-alternative',
            'definitive-alternative', 'chargingpoint-definitive-alternative',
            'suggestion-private-alternative', 'chargingpoint-suggestion-private-alternative',
            'definitive-private-alternative', 'chargingpoint-definitive-private--alternative',
            'alert', 'chargingpoint-alert', 
            'no-default-image' // Hide unkown points
          ], 
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0.05,
            18,
            0.2
          ],
        },
        'paint': {
          // 'icon-color': '',
          'icon-opacity': [ // transition based on zoom
            'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0,
            10.2,
            0.8
          ]
        }
      }, positionBelow) // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer({ 
        "id": "chargingpoints-text",
        "type": "symbol",
        "source": "chargingpoints",
        "minzoom": 14,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          // 'text-allow-overlap': true,
          'text-field': [
            "match", 
            ['get', 'status'], 
            // "definitive", ['get', 'id'],
            // "in-progress", ['get', 'id'],
            "realized", ['get', 'id'],
            // "realized-private", ['get', 'id'],
            // "fastcharger-realized", ['get', 'id'],
            // "fastcharger-planned", ['get', 'id'],
            // "suggestion", ['get', 'id'],
            // "alert", 'chargingpoint-alert', 
            "" // Hide suggestions
          ],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          "text-size": 12,
          "text-radial-offset": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            1,
            18.2,
            2
          ],
          'text-variable-anchor': [ "top-right", "bottom-right", "bottom-left", "top-left", "right", "left", "top", "bottom" ],
          "text-justify": 'auto'
        },
        "paint": {
          "text-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14.4,
            0,
            14.6,
            0.8
          ]
        }
      }, positionBelow)
    },
  }
}
</script>

