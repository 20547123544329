<template>
  <div class="TenantLogo">
    <img
      :src="src"
      :width="getImage.width"
      alt=""
      :style="style"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('tenant', ['getImage']),
    src() {
      return require(`../assets/image/${this.getImage.file}`);
    },
    style() {
      return this.getImage.color && { background: this.getImage.color };
    }
  }
};
</script>

<style lang="scss">
.TenantLogo {
  position: relative;
  margin: 0 16px 0 0;

  img {
    position: absolute;
    left: 0;
    bottom: 0;

    background: white;
    padding: 0.5rem;
    box-shadow: 2px 5px 19px 0px rgba(0, 0, 0, 0.27);
    border-radius: 4px;
  }
}
</style>
