<template>
  <a
    :href="`mailto:${supportEmailAddress}`"
    v-text="supportEmailAddress"
  />
</template>

<script>
import { Config } from '@/config'

export default {
  data() {
    return {
      supportEmailAddress: Config.supportEmailAddress,
    }
  },
}
</script>
