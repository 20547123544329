<script>
import SingleSelectFilter from './SingleSelectFilter.vue'

import { removeAllActiveFiltersByCategory } from '../utils.js'

export default {
  name: 'MultiSelectFilter',
  mixins: [SingleSelectFilter],
  computed: {

    /**
     * Option values that are selectable
     *  Note: stub
     */
    enabledOptionValues() {
      return this.optionsFromConfig.map(option => option.value)
    },

    /**
     * Labels by values
     *  Note: stub
     */
    labelsByValue() {
      return this.optionsFromConfig.reduce((result, option) => {
        result[option.value] = option.text
        return result
      }, {})
    },

    /**************************************************************************
     * Abstracted methods
     */

    /**
     * Options from the filter config specification
     */
    optionsFromConfig() {
      return Array.isArray(this.filterConfig.options)
        ? this.filterConfig.options
        : []
    },

    /**
     * Option list bound by template in SingleSelectFilter
     */
    options() {
      return this.availableOptions.map(value => {
        return {
          value,
          text: this.getLabelByValue({ value }),
        }
      })
    },

    /**
     * Connect selected tags to active filters
     */
    selectedOptions() {
      return this.activeFilters
        .filter(filter => filter.category === this.filterConfig.id)
        .map(filter => filter.value)
    },

    /**
     * Tags that enabled and unselected
     */
    availableOptions() {
      return this.enabledOptionValues
        .filter(opt => this.selectedOptions.indexOf(opt) === -1)
    },
  },
  methods: {
    /**
     * note: Stub
     */
    getLabelByValue({ value }) {
      return this.labelsByValue[value] || 'Onbekend'
    },

    /**************************************************************************
     * Abstracted methods
     */

    setup() {
      // ignore setup from mixin
    },
    /**
     * Go over the active filters
     *  Remove all filters from this category in place
     *  We cannot assign a new array to activeFilters
     */
    resetFilter() {
      removeAllActiveFiltersByCategory({
        activeFilters: this.activeFilters,
        category: this.filterConfig.id,
      })
      this.value = ''
    },

    /**
     * Handle filter input
     */
    handleInput(value) {
      // Ignore empty values
      if (! value) return

      // Don't add a tag that is already an active filter
      if (this.availableOptions.includes(value)) {
        this.activeFilters.push({
          category: this.filterConfig.id,
          categoryLabel: this.filterConfig.label,
          label: this.getLabelByValue({ value }),
          value: value,
        })
      }

      // de-select the value, as the computed removes the option from the list
      // Don't set an empty string, as this will hide the clear button
      this.value = '-deselected-' // -deselected-
    },
  },
}
</script>
