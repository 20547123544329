/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  visible: false,
  frozen: false,
}

const getters = {
  isDebugModalVisible: state => !! state.visible,
}
const actions = {

}
const mutations = {
  freezeDebugModal(state) {
    state.frozen = true
  },
  unfreezeDebugModal(state) {
    state.frozen = false
  },
  showDebugModal(state) {
    state.visible = true
  },
  hideDebugModal(state) {
    if (state.frozen) return
    state.visible = false
  },
  toggleDebugModal(state) {
    if (state.frozen) return
    state.visible = ! state.visible
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
