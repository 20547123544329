import Vue from 'vue';
import VueRouter from 'vue-router';

import { guards, overlayBlock } from '@/services/router-guards';
import { authGuard } from '@/auth/authGuard';

import Main from '@/views/Main';

import List from '@/views/List';
import Detail from '@/views/Detail';

//import Realisations from '@/views/Realisations';
import Processes from '@/views/Processes';
import Process from '@/views/Process';
import Realisation from '@/views/Realisation';

import Config from '@/views/Config';
import TagConfigPanel from '@/views/admin/TagConfigPanel.vue';
import PIDConfigPanel from '@/views/admin/PIDConfigPanel.vue';
import ContractorsConfigPanel from '@/views/admin/ContractorsConfigPanel.vue';

// Reporting
import Reporting from '@/views/Reporting';
import KeyMetrics from '@/views/reporting/KeyMetrics';
import RequestJudgement from '@/views/reporting/RequestJudgement';
import RealisationProcessStatus from '@/views/reporting/RealisationProcessStatus';
import ProcessTimeInSteps from '@/views/reporting/ProcessTimeInSteps';

// Manuals
import Manuals from '@/views/Manuals.vue';
import ManualPage from '@/views/manuals/ManualPage.vue';

Vue.use(VueRouter);

const routes = [
  /**
   * Realisation process
   */
  {
    path: '/processen/:type?/:filter?',
    name: 'ProcessList',
    component: Processes
  },
  {
    path: '/realisatie/:uuid?',
    name: 'Realisation',
    component: Realisation,
    children: [
      {
        path: '/realisatie/nieuw',
        name: 'NewRealisation',
        component: Realisation
      }
      // the rest of the routes will be dynamically added in App.vue once the steps are loaded from config
    ]
  },
   /**
   * e.g. Addition process // TODO: All processes dynamic load
   */
    // {
    //   path: '/procesen/:filter?',
    //   name: 'ProcessList',
    //   component: Processes
    // },
    {
      path: '/proces/:uuid?',
      name: 'Process',
      component: Process,
      children: [
        {
          path: '/proces/nieuw',
          name: 'NewProcess',
          component: Process
        }
        // the rest of the routes will be dynamically added in App.vue once the steps are loaded from config
      ]
    },

  /**
   * Requests
   */
  {
    path: '/aanvragen',
    name: 'RequestList',
    component: List
  },
  {
    path: '/aanvragen/:uuid',
    name: 'RequestDetail',
    component: Detail
  },

  /**
   * Config
   */
  {
    path: '/beheer',
    name: 'Config',
    component: Config,
    children: [
      {
        path: 'tags',
        name: 'TagsConfig',
        component: TagConfigPanel
      },
      {
        path: 'pids',
        name: 'PidsConfig',
        component: PIDConfigPanel
      },
      {
        path: 'contractors',
        name: 'ContractorsConfig',
        component: ContractorsConfigPanel
      }
    ]
  },

  /**
   *
   */
  {
    path: '/rapportage',
    name: 'Reporting',
    component: Reporting,
    children: [
      {
        path: 'key-metrics',
        name: 'KeyMetrics',
        component: KeyMetrics
      },
      {
        path: 'beoordelingen',
        name: 'RequestJudgement',
        component: RequestJudgement
      },
      {
        path: 'realisatiestatus',
        name: 'RealisationProcessStatus',
        component: RealisationProcessStatus
      },
      {
        path: 'procesdoorlooptijden',
        name: 'ProcessTimeInSteps',
        component: ProcessTimeInSteps
      }
    ]
  },

  /**
   * Manual pages
   */
  {
    path: '/handleidingen',
    name: 'Manuals',
    component: Manuals,
    children: [
      {
        path: ':id',
        name: 'ManualPage',
        component: ManualPage
      }
    ]
  },

  /**
   * Main - currently redirects to RequestList or RealisationList based on role
   */
  {
    path: '/',
    name: 'Dashboard',
    component: Main
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  linkActiveClass: 'active',
  linkExactActiveClass: 'active exact-active'
});

router.beforeEach(guards([authGuard, overlayBlock({ Vue })]));

export default router;
