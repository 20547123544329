import { allMunicipalityOptions } from '@/services/allMunicipalityOptions';
/**
 * Municipality Names by Code enum
 *  Populated on first demand
 *
 * @type {?string}
 */
let municipalityNamesByCode = null;

/**
 * Take the municipality options from the config and turn it into an enum for easy access
 *
 * @returns {object} The municipalityNamesByCode enum
 */
function buildMunicipalityNamesByCodeEnum() {
  return allMunicipalityOptions.reduce((result, option) => {
    result[option.value] = option.text;
    return result;
  }, {});
}

/**
 * Obtain the name of a municipality by code
 *
 * @typedef MunicipalityCode
 * @type {object}
 * @property {string} code - The municipality code
 *
 * @param {MunicipalityCode} param0 The municipality code
 * @returns {string|null} The municipality name or null
 */
export function getMunicipalityNameByCode({ code }) {
  if (municipalityNamesByCode === null) {
    municipalityNamesByCode = buildMunicipalityNamesByCodeEnum();
  }

  return municipalityNamesByCode[code] || null;
}
