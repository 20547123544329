<script>
import SingleSelectFilter from './SingleSelectFilter.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MunicipalityFilter',
  mixins: [SingleSelectFilter],
  computed: {
    ...mapGetters('tenant', ['getMunicipalityOptions']),
    options: () => this.getMunicipalityOptions.sort((a, b) => a.text.localeCompare(b.text))
  }
};
</script>
