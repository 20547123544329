<script>
import { mapGetters } from 'vuex'

import MultiSelectFilter from './MultiSelectFilter.vue'

export default {
  name: 'TagSelectFilter',
  mixins: [MultiSelectFilter],
  computed: {
    ...mapGetters('tenant', {
      getTags: 'getExistingTags',
      labelsByValue: 'getLabelsByTagUuid',
    }),

    /**
     * Tags that are selectable
     */
    enabledOptionValues() {
      return this.getTags.map(tag => tag.uuid)
    },
  },
}
</script>
