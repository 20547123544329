<script>

import SingleSelectFilter from './SingleSelectFilter.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'VKBSelectFilter',
  mixins: [SingleSelectFilter],
  computed: {
    ...mapGetters('tenant', [
      'getSelectOptionsByName',
    ]),
    options() {
      return this.getSelectOptionsByName({
        name: 'processCategories',
      })
        .reduce(( options, option ) => {
          option.disabled = false
          // option.value = option.text
          options.push(option)
          return options
        }, [])
    },
  },
}
</script>
