<template>
  <div class="MapModal__tab">
    <div v-if="!hasChargingPoint && !averageData">
      <p>
        Selecteer een gerealiseerde laadpaal om de gebruiksdata in te zien. Als je de gemiddelde bezettingsgraad over
        meerdere laadpalen wil zien, kun je met CTRL + klik meerdere laadpalen selecteren. Je kunt ook in één keer alle
        laadpalen in de buurt van de aanvraag selecteren met de knop ‘Nabije selecteren’.
      </p>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="selectNearby"
      >
        Nabije selecteren
      </b-button>
      <br>
      <Toggle
        :value="multipleSelect"
        label="Selecteer meerdere laadpalen"
        class="mt-2"
        @change="() => (multipleSelect = !multipleSelect)"
      />
    </div>

    <div v-if="hasChargingPoint">
      <strong>Adres</strong>
      <div
        v-if="address"
        class="MapModal__segment"
      >
        {{ address }} <br v-if="distance">
        <span v-if="distance">
          Afstand hemelsbreed vanaf aanvraaglocatie: <strong>{{ distance }}</strong> meter
        </span>
      </div>
      <table class="MapModal__segment mt-3">
        <tr v-if="chargerNumber">
          <td class="pr-3">
            Referentie
          </td>
          <td>{{ chargerNumber }}</td>
        </tr>
        <tr v-if="evse">
          <td>EVSE</td>
          <td>{{ evse }}</td>
        </tr>
        <tr v-if="operator">
          <td>CPO</td>
          <td>{{ operator }}</td>
        </tr>
      </table>
    </div>

    <div
      v-if="hasChargingPoint && averageData"
      class="mt-3"
    >
      <strong>Gemiddelde gebruiksdata</strong> <br>

      <div class="MapModal__segment">
        <ChartOccupancyRate
          :data="averageData"
          title="Bezettingsgraad"
          :options="{ limit: getMonitoringConfig.limit }"
        />
      </div>

      <div class="MapModal__segment">
        <p class="mt-1 mb-0 d-flex justify-content-end">
          <b-button
            variant="outline-danger"
            size="sm"
            @click="deselectChargingPoint"
          >
            Deselecteren
          </b-button>
        </p>

        <ul
          v-if="multipleSelect"
          class="mt-3"
          style="max-height: 35vh; margin-bottom: 10px; overflow: scroll"
        >
          <li
            class="d-flex justify-content-between"
            style="max-width: 500px"
          >
            <span style="flex-grow: 1; font-weight: bold"> Adres </span>
            <span style="min-width: 150px; font-weight: bold"> EVSE </span>
            <span style="min-width: 100px; font-weight: bold"> Operator </span>
          </li>
          <li
            v-for="location in selectedChargingpointsData"
            :key="location.uuid"
            class="d-flex justify-content-between selected-chargingpoints-row"
            style="max-width: 500px"
            @click="() => setSelectedChargerUUID(location)"
          >
            <span
              style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; flex-grow: 1; max-width: 180px"
            >{{ location.address }}
            </span>
            <span
              style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; min-width: 125px"
            >{{ location.evse }}
            </span>
            <span style="min-width: 100px; text-align: center">{{ location.operator }} </span>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="hasChargingPoint && !averageData"
      class="mt-3"
    >
      <strong>Gebruiksdata</strong>

      <div
        v-if="loading"
        class="mt-1 mb-3"
      >
        Bezig met laden van data
      </div>
      <div
        v-else-if="!hasMonitoringData"
        class="mt-1 mb-3"
      >
        Er is (nog) geen data beschikbaar over deze locatie
      </div>
      <div v-else-if="connectionPointOptions">
        <b-form-select
          v-model="selectedPointToShow"
          class="mt-1 mb-3"
          :options="connectionPointOptions"
        />
        <div class="MapModal__segment">
          <ChartOccupancyRate
            :data="selectedPointMonitoringData"
            title="Bezettingsgraad"
            :options="{ limit: getMonitoringConfig.limit }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartOccupancyRate from '@/components/monitoring/ChartOccupancyRate.vue';
import Toggle from '@/components/common/Toggle';

import { turf } from '@/services/turf';
import { numberWithDots } from '@/helpers/number';
import { mapGetters, mapActions, mapMutations } from 'vuex';

const LAADPAAL_SELECTED = 'laadpaal_is_selected';

export default {
  name: 'ChargerTab',
  components: {
    ChartOccupancyRate,
    Toggle
  },
  props: {
    requestCoordinates: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      /**
       * Whether we're busy loading data from the API
       */
      loading: false,

      /**
       * Select Multiple locations
       */
      multipleSelect: false,

      /**
       * The monitoring data of the selected charging location
       */
      data: null,

      /**
       * The connection points of the selected charging location
       */
      connectionPoints: [],

      /**
       * The monitoring data of the selected chargingpoint
       */
      monitoringData: undefined,

      /**
       * The selected connection point to show
       */
      selectedPointToShow: LAADPAAL_SELECTED

      /**
       * Usage above this limit shows up red
       *  Usage between 85% and 100% of limit shows orange
       */
    };
  },
  computed: {
    ...mapGetters('tenant', ['getMonitoringConfig']),
    ...mapGetters('monitoring', {
      locationData: 'getSelectedLocationData',
      allMonitoringData: 'getAllMonitoringData',
      selectedChargerUUID: 'getSelectedChargerUUID',
      selectedLocationsWithData: 'getSelectedLocationsWithData',
      selectedChargerUuids: 'getSelectedChargerUuids',
      selectedChargerMonitoringData: 'getSelectedChargerMonitoringData'
    }),
    ...mapGetters('chargingpoints', ['chargingpoints', 'getChargingPointByUUID']),
    hasChargingPoint() {
      return !!this.chargingpoint;
    },
    hasLocationData() {
      return !!this.data;
    },
    hasMonitoringData() {
      return !!this.monitoringData;
    },
    chargingpoint() {
      return this.getChargingPointByUUID({ uuid: this.selectedChargerUUID }) || false;
    },
    address() {
      if (!this.hasChargingPoint) return false;
      return this.chargingpoint?.data.address.formatted_address || false;
    },
    chargerNumber() {
      return `${this.chargingpoint?.data.code}-${this.chargingpoint?.data.properties.id}`;
    },
    evse() {
      return this.chargingpoint?.data.properties.evse;
    },
    operator() {
      return this.chargingpoint?.data.properties.operator;
    },
    coordinates() {
      return this.chargingpoint.data.coordinates;
    },
    connectionPointOptions() {
      if (!this.monitoringData) return false;
      const points = Object.keys(this.monitoringData);
      return points.reduce(
        (list, option) => list.concat([{ value: option, text: option }]),
        [{ value: LAADPAAL_SELECTED, text: `Laadpaal (${points.length} laadpunt${points.length > 1 ? 'en' : ''})` }]
      );
    },
    distance() {
      if (!this.requestCoordinates) return null;
      const from = [this.requestCoordinates.lng, this.requestCoordinates.lat];
      const to = this.coordinates;

      return numberWithDots(Math.ceil(turf.distance(from, to, { unit: 'kilometers' }) * 1000));
    },
    nearbyChargingpoints() {
      if (!this.requestCoordinates || !this.chargingpoints) return null;

      const from = [this.requestCoordinates.lng, this.requestCoordinates.lat];
      const limit = 800 / 1000;
      return this.chargingpoints
        .filter(point => turf.distance(from, point.data.coordinates, { unit: 'kilometers' }) < limit)
        .map(chargingpoint => {
          this.loadData({ chargingpoint }); // async load data. Don't wait for it
          return chargingpoint;
        })
        .map(point => point.data);
    },
    hasList() {
      return this.nearbyChargingpoints !== null;
    },
    averageData() {
      let monitoringData = this.monitoringData ? this.monitoringData : undefined;
      if (this.multipleSelect) {
        const values = [...this.allMonitoringData.values()].map(val => JSON.stringify(val)).map(val => JSON.parse(val));
        monitoringData = {};
        values.forEach(obj => {
          for (const [key, value] of Object.entries(obj)) {
            monitoringData[key] = value;
          }
        });
      }

      if (!monitoringData || !this.chargingpoint) return undefined;

      const flatMapMonitoringValues = Object.values(monitoringData).flatMap(datapoint => datapoint);
      const totals = flatMapMonitoringValues.reduce((averageData, datapoint) => {
        const { month, metrics } = datapoint;
        if (!averageData[month]) {
          averageData[month] = {
            label: new Date(month).toLocaleString('default', { month: 'short' }),
            session_count: 0,
            occupancy_rate: 0,
            unique_users: 0,
            volume: 0,
            chargingpointsCount: 0
          };
        }
        Object.entries(metrics).forEach(([key, value]) => {
          if (!averageData[month][key]) {
            averageData[month][key] = 0;
          }

          averageData[month][key] += value;
        });

        averageData[month]['chargingpointsCount'] += 1;

        return averageData;
      }, {});

      Object.entries(totals).forEach(([month, metrics]) => {
        const { chargingpointsCount } = metrics;
        Object.entries(metrics).forEach(([key, value]) => {
          if (typeof value == 'number' && key !== 'chargingpointsCount') {
            totals[month][key] = value / chargingpointsCount;
          }
        });
      });

      return totals;
    },
    selectedPointMonitoringData() {
      const monitoringData = this.monitoringData;
      const chargingPointIds = Object.keys(monitoringData);

      if (!chargingPointIds.includes(this.selectedPointToShow)) return {};

      const chargingPointData = monitoringData[this.selectedPointToShow];
      const newData = chargingPointData.reduce((data, datapoint) => {
        const { month, metrics } = datapoint;

        if (!data[month]) {
          data[month] = {
            label: new Date(month).toLocaleString('default', { month: 'short' }),
            session_count: metrics?.session_count || 0,
            occupancy_rate: metrics?.occupancy_rate || 0,
            unique_users: metrics?.unique_users || 0,
            volume: metrics?.volume || 0
          };
        }

        return data;
      }, {});

      return newData;
    },
    selectedChargingpointsData() {
      const data = [...this.allMonitoringData.keys()].map(id => this.getChargingPointByUUID({ uuid: id }));
      const transformed = data.map(point => ({
        chargerNumber: `${point.data.code}-${point.data.properties?.id}`,
        evse: point.data.properties?.evse,
        operator: point.data.properties?.operator,
        uuid: point.data?.uuid,
        address: point.data?.address?.simple_address
      }));
      return transformed;
    }
  },
  watch: {
    async chargingpoint() {
      if (!this.chargingpoint.data?.uuid) {
        return;
      }

      if (this.multipleSelect && [...this.allMonitoringData.keys()].length > 0) {
        this.toggleSelectedCharger({ uuid: this.chargingpoint.data?.uuid });
        return;
      }

      this.selectedPointToShow = LAADPAAL_SELECTED;
      await this.loadData({ chargingpoint: this.chargingpoint, activate: true });
      this.setSelectedChargerUUID({ uuid: this.chargingpoint.data.uuid });

      if (!this.chargingpoint) {
        this.monitoringData = undefined;
      }
    }
  },
  created() {
    this.loadData({ chargingpoint: this.chargingpoint, activate: true });
  },
  beforeDestroy() {
    this.clearSelected();
    this.monitoringData = undefined;
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchConnectionPointsByChargingpoint',
      'addOrRemoveSelectedCharger',
      'toggleSelectedCharger',
      'fetchLastMonthsUsageReport'
    ]),
    ...mapMutations('monitoring', ['setSelectedChargerUUID', 'clearSelected']),
    selectNearby() {
      this.nearbyChargingpoints.forEach(chargingpoint => this.toggleSelectedCharger({ uuid: chargingpoint.uuid }));
    },
    async loadData({ chargingpoint, activate }) {
      // Without a chargingpoint reference we can't load data
      if (!chargingpoint) return;

      // check if data is already loaded
      if (this.locationData) {
        return;
      }

      if (activate) {
        this.loading = true;
      }

      this.monitoringData = await this.fetchLastMonthsUsageReport({
        chargingpoint,
        numberOfMonths: 3,
        multipleSelect: this.multipleSelect
      });

      if (activate) {
        this.loading = false;
      }
    },
    deselectChargingPoint() {
      this.clearSelected();
      this.monitoringData = undefined;
    }
  }
};
</script>

<style lang="scss">
.MapModal__tab {
  &__select-all {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
.MapModal__segment {
  button.btn.btn-outline-danger.btn-sm {
    font-size: 0.75rem;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      &:first-child {
        border-bottom: 1px solid rgb(174, 174, 174);
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }
  }

  hr {
    border-color: rgb(174, 174, 174);
    margin: 0.75rem auto 0.75rem 0;
  }
}

.selected-chargingpoints-row {
  cursor: pointer;
  &:hover {
    background-color: #d3d3d3;
  }
}
</style>
