
/**
 * Format postalcode in 0000 AA format
 *
 * @param {*} postalcode
 * @returns
 */
export const formatPostalCode = (postalcode) => {
  const isVlaanderen = process.env.VUE_APP_TENANT
  if (postalcode < 6) {
    return postalcode
  }

  return isVlaanderen ? postalcode : `${postalcode.substr(0, 4)} ${postalcode.substr(-2, 2)}`
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * From https://www.codegrepper.com/code-examples/javascript/how+to+assign+alphabet+to+numbers+in+js
 *
 * convertLetterToNumber("b"); // 2
 * convertLetterToNumber("Ba"); // 53
 */
export const convertLetterToNumber = (str) => {
  str = str.toUpperCase()
  let out = 0, len = str.length
  for (let pos = 0; pos < len; pos++) {
    out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1)
  }
  return out
}
