<template>
  <div class="PIDConfigPanel DetailGrid">
    <div class="DetailGrid__nav">
      <h2 class="h3 PIDConfigPanel__header">
        <span>
          PID Beheer
        </span>

        <b-button
          variant="dark"
          type="submit"
          :disabled="disabled"
          @click="handleSubmit"
        >
          Opslaan
        </b-button>
      </h2>

      <Form
        ref="form"
        @error="handleValidationError"
        @submit="handleSubmit"
      >
        <Feedback
          :feedback="statusFeedback"
          :dismissible="true"
        />

        <Tags
          label="Voeg een PID toe"
          path="PIDS"
          :counters="['realisationCount']"
          :disabled="disabled"
          :values="config"
        />
      </Form>
    </div>
    <div class="DetailGrid__sidebar">
      <h4>Over PIDS</h4>

      <p>
        De checkbox 'Actief' bepaald of de PID wel of niet voor gebruikers beschikbaar
        is om te selecteren in een realisatieprocessen.
      </p>
      <p>
        Met de prullenbak icoon kun je PIDs weggooien, dit kan alleen als de PID niet
        langer gekoppeld is aan realisatieprocessen.
      </p>
    </div>
  </div>
</template>

<script>
import dot from 'dot-object'

import { mapGetters, mapMutations } from 'vuex'

import Form from '@/components/form/Form'
import Feedback from '@/components/form/Feedback'
import Tags from '@/components/definitions/config/Tags.vue'

export default {
  name: 'PIDConfigPanel',
  components: { Form, Tags, Feedback },
  data() {
    return {
      feedback: null,
      feedbackMessage: '',
      disabled: false,
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getPIDs',
    ]),
    ...mapGetters('realisations', {
      realisationRecords: 'records',
    }),
    ...mapGetters('requests', {
      requestRecords: 'requests',
    }),
    statusFeedback() {
      if (this.feedback === null) {
        return {
          show: false,
        }
      }
      return {
        show: true,
        variant: this.feedback,
        message: this.feedbackMessage,
      }
    },
    config() {
      /**
       * Count records by tag uuid
       */
      const countRecordsByTags = (counts, record) => {
        let pid = record.PidUuid
        if (pid) {
          counts[pid] = counts[pid] ? (counts[pid] + 1) : 1
        }

        return counts
      }

      let realisationTagCounts = this.realisationRecords
        .reduce(countRecordsByTags, {})

      return {
        PIDS: this.getPIDs.map(pid => {
          pid.realisationCount = realisationTagCounts[pid.uuid] || 0
          pid.requestCount = 0
          return pid
        }),
      }
    },
  },
  methods: {
    ...mapMutations('tenant', [
      'setConfig',
    ]),
    /**
     * Handle Validation Error
     */
    handleValidationError() {
      this.feedback = 'danger'
      this.feedbackMessage = 'Controleer of alle velden (juist) zijn ingevuld.'
    },

    /**
     * Handle Submit event
     */
    handleSubmit: async function() {
      try {
        // console.log("submit")

        this.feedback = null
        this.disabled = true

        // Get form input
        const form = this.$refs['form']
        const values = form.getValues()
        let data = dot.object(values)

        // console.log(data)
        // TODO: Proper validation

        // Map the data
        data.PIDS = data.PIDS
          .filter(pid => pid !== null)
          .map(pid => {
            // console.log(pid)
            return {
              uuid: pid.uuid || false,
              label: pid.label,
              enabled: !! (pid.enabled.length && pid.enabled.includes(true)),
              deleted_at: pid.deleted_at || null,
            }
          })

        // Basic validation
        if (data.PIDS.some(pid => pid.label === '')) {
          this.feedbackMessage = 'Lege PIDs zijn niet toegestaan'
          this.feedback = 'danger'
          this.disabled = false
          return
        }
        if (data.PIDS.some(pid => pid.label.length > 60)) {
          this.feedbackMessage = 'Beperk PIDs tot maximaal 60 tekens'
          this.feedback = 'danger'
          this.disabled = false
          return
        }

        // Make the call
        const token = await this.$auth.getTokenSilently()
        const api = await fetch('/api/config_savepids', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data,
          }),
        })
        if (! api.ok) {
          this.feedbackMessage = 'Er ging iets mis met het opslaan. Probeer het later nog eens'
          this.feedback = 'danger'
          throw new Error(api.error)
        }

        const response = await api.json()

        // console.log("response")
        // console.log(response.data)

        // Update the tenant config in the Vue store
        this.setConfig({
          config: response.data,
        })

        this.feedbackMessage = 'De wijzigingen zijn opgeslagen'
        this.feedback = 'success'
        this.disabled = false

      } catch (e) {
        this.disabled = false

        // console.log(e)
      }
    },

  },
}
</script>

<style lang="scss">
.PIDConfigPanel {

  &__header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
