<template>
  <div
    v-if="knownIcon"
    class="SvgIcon svg-container"
  >
    <svg
      v-if="icon === 'chevron-down'"
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    ><g
      id="Icon/32/Chevron-Down---"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    ><polygon
      id="Path"
      fill="#000000"
      points="16 25.7568858 0 9.75688579 2.91002571 6.85714286 16 19.9368344 29.0899743 6.85714286 32 9.75688579"
    /></g></svg>

    <svg
      v-if="icon === 'chart-bar-regular'"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="chart-bar"
      class="svg-inline--fa fa-chart-bar fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    ><path
      fill="currentColor"
      d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"
    /></svg>

    <svg
      v-if="icon === 'envelope-regular'"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="envelope"
      class="svg-inline--fa fa-envelope fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    ><path
      fill="currentColor"
      d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
    /></svg>

    <svg
      v-if="icon === 'sign-out-regular'"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="sign-out"
      class="svg-inline--fa fa-sign-out fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    ><path
      fill="currentColor"
      d="M96 64h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-53 0-96-43-96-96V160c0-53 43-96 96-96zm231.1 19.5l-19.6 19.6c-4.8 4.8-4.7 12.5.2 17.1L420.8 230H172c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h248.8L307.7 391.7c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l164.4-164c4.7-4.7 4.7-12.3 0-17l-164.4-164c-4.7-4.6-12.3-4.6-17 .1z"
    /></svg>

    <svg
      v-if="icon === 'user-regular'"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="user"
      class="svg-inline--fa fa-user fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    ><path
      fill="currentColor"
      d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
    /></svg>

    <svg
      v-if="icon === 'cog-regular'"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="cog"
      class="svg-inline--fa fa-cog fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    ><path
      fill="currentColor"
      d="M452.515 237l31.843-18.382c9.426-5.441 13.996-16.542 11.177-27.054-11.404-42.531-33.842-80.547-64.058-110.797-7.68-7.688-19.575-9.246-28.985-3.811l-31.785 18.358a196.276 196.276 0 0 0-32.899-19.02V39.541a24.016 24.016 0 0 0-17.842-23.206c-41.761-11.107-86.117-11.121-127.93-.001-10.519 2.798-17.844 12.321-17.844 23.206v36.753a196.276 196.276 0 0 0-32.899 19.02l-31.785-18.358c-9.41-5.435-21.305-3.877-28.985 3.811-30.216 30.25-52.654 68.265-64.058 110.797-2.819 10.512 1.751 21.613 11.177 27.054L59.485 237a197.715 197.715 0 0 0 0 37.999l-31.843 18.382c-9.426 5.441-13.996 16.542-11.177 27.054 11.404 42.531 33.842 80.547 64.058 110.797 7.68 7.688 19.575 9.246 28.985 3.811l31.785-18.358a196.202 196.202 0 0 0 32.899 19.019v36.753a24.016 24.016 0 0 0 17.842 23.206c41.761 11.107 86.117 11.122 127.93.001 10.519-2.798 17.844-12.321 17.844-23.206v-36.753a196.34 196.34 0 0 0 32.899-19.019l31.785 18.358c9.41 5.435 21.305 3.877 28.985-3.811 30.216-30.25 52.654-68.266 64.058-110.797 2.819-10.512-1.751-21.613-11.177-27.054L452.515 275c1.22-12.65 1.22-25.35 0-38zm-52.679 63.019l43.819 25.289a200.138 200.138 0 0 1-33.849 58.528l-43.829-25.309c-31.984 27.397-36.659 30.077-76.168 44.029v50.599a200.917 200.917 0 0 1-67.618 0v-50.599c-39.504-13.95-44.196-16.642-76.168-44.029l-43.829 25.309a200.15 200.15 0 0 1-33.849-58.528l43.819-25.289c-7.63-41.299-7.634-46.719 0-88.038l-43.819-25.289c7.85-21.229 19.31-41.049 33.849-58.529l43.829 25.309c31.984-27.397 36.66-30.078 76.168-44.029V58.845a200.917 200.917 0 0 1 67.618 0v50.599c39.504 13.95 44.196 16.642 76.168 44.029l43.829-25.309a200.143 200.143 0 0 1 33.849 58.529l-43.819 25.289c7.631 41.3 7.634 46.718 0 88.037zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 144c-26.468 0-48-21.532-48-48 0-26.467 21.532-48 48-48s48 21.533 48 48c0 26.468-21.532 48-48 48z"
    /></svg>

    <svg
      v-if="icon === 'circle-question'"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="cog"
      class="svg-inline--fa fa-cog fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    ><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z" /></svg>
  </div>
</template>

<script>

const icons = [
  'chevron-down',
  'chart-bar-regular',
  'envelope-regular',
  'sign-out-regular',
  'user-regular',
  'cog-regular',
  'circle-question',
]

/**
 * Obtained from https://gist.github.com/achhunna/cae74645f3ef1e7787c07bcdee163d18
 * Article on usage: https://medium.com/js-dojo/making-svg-icon-component-in-vue-cb7fac70e758
 * Modified as per comment on article.
 *
 * Also requires webpack.config.js rule. See: https://stackoverflow.com/a/55318979/1533140
 *
 * TODO: Avoid inline svg. Convert into svg element with all icons in head, and use 'use' for inline.
 */
function recursivelyRemoveFill(el) {
  if (!el) {
    return
  }
  el.removeAttribute('fill');
  [].forEach.call(el.children, child => {
    recursivelyRemoveFill(child)
  })
}
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      default: null,
    },
    hasFill: {
      type: Boolean,
      default: false,
    },
    growByHeight: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    knownIcon() {
      return icons.includes(this.icon)
    },
  },
  watch: {
    icon() {
      this.$nextTick(this.setupSvg)
    },
    hasFill() {
      this.$nextTick(this.setupSvg)
    },
    growByHeight() {
      this.$nextTick(this.setupSvg)
    },
  },
  mounted() {
    this.setupSvg()
  },
  methods: {
    setupSvg() {
      if (this.$el.firstElementChild.nodeName === 'svg') {
        const svgElement = this.$el.firstElementChild
        // use `viewBox` attribute to get the svg's inherent width and height
        const viewBox = svgElement.getAttribute('viewBox').split(' ').map(n => Number(n))
        const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2)
        if (this.hasFill) {
          // recursively remove all fill attribute of element and its nested children
          recursivelyRemoveFill(svgElement)
        }
        // set width and height relative to font size
        // if growByHeight is true, height set to 1em else width set to 1em and remaining is calculated based on widthToHeight ratio
        if (this.growByHeight) {
            svgElement.setAttribute('height', '1em')
            svgElement.setAttribute('width', `${widthToHeight}em`)
        } else {
            svgElement.setAttribute('width', '1em')
            svgElement.setAttribute('height', `${1 / widthToHeight}em`)
        }
        svgElement.classList.add('svg-class')
      }
    },
  },
}
</script>

<style scoped>
.svg-container {
  display: inline-flex;
  user-select: none;

  align-content: center;
  align-items: center;
}
.svg-container svg {
  vertical-align: middle;
}
</style>
