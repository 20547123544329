<script>

import { mapGetters, mapMutations } from 'vuex'

import { configs } from '../devconfig'


export default {
  name: 'TableState',
  inject: ['tableIdentifier'],
  data () {
    return {
      activeControlsTab: null, // Default set if no localStorage is present //
    }
  },
  computed: {
    ...mapGetters('EVtables', [
      'getActiveStoredTableState',
      'activeConfig',
    ]),
    ...mapGetters('user', ['getUserSettings']),

    instanceKey() {
      return `${this.tableIdentifier}.${this.activeConfig({
        identifier: this.tableIdentifier,
      })}`
    },

    tableState() {
      return this.getActiveStoredTableState({
        identifier: this.tableIdentifier,
      })
    },

    settings() {
      return this.tableState.settings
    },

    /**
     * Configuration
     */
    config() {
      const uuid = this.activeConfig({
        identifier: this.tableIdentifier,
      })

      if (! configs[uuid]) {
        throw new Error('Unknown config')
      }

      return configs[uuid]

      // return this.accessibleConfigs
      //   .find(config => config.uuid === this.activeConfig({ identifier: this.tableIdentifier })) || false
    },
    accessibleConfigs() {
      return this.tableState.accessibleConfigs
    },

    /**
     * At first pretend there is only 1 config
     */
    layout() {
      return this.config.layouts[this.settings.layout]
    },

    /**
     * Records
     */
    records() {
      return this.tableState.records
    },

    activeFilters() {
      return this.tableState.activeFilters
    },
    activeSorting() {
      return this.tableState.activeSorting
    },
  },
  created () {
    // Set all initial values from userSettings //
    this.setValuesFromUserSettings()
  },
  methods: {
    ...mapMutations('EVtables', [
      'storeTableState',
    ]),

    /**
     * Settings
     */
    setPageSize({ pageSize }) {
      this.tableState.settings.pageSize = pageSize
    },
    setTableLayout({ layout }) {
      this.tableState.settings.layout = layout
    },

    /**
     * Restore state
     */
    restoreState({ state }) {
      this.tableState.settings = state.settings
      this.tableState.activeFilters = state.activeFilters
      this.tableState.activeSorting = state.activeSorting
    },

    saveUserSettings () {
      const userSettings = this.getUserSettings || { EVTables: {} }
      userSettings.EVTables[this.instanceKey] = {
        activeControlsTab: this.activeControlsTab,
        pageSize: this.settings.pageSize,
        layout: this.settings.layout,
      }

      this.$store.dispatch('user/saveUserSettings', { userSettings })
    },

    setValuesFromUserSettings () {
      const userSettings = this.getUserSettings?.EVTables?.[this.instanceKey]
      if (userSettings) {
        Object.keys(this.settings).forEach(prop => {
          if (userSettings[prop]) {
            this.tableState.settings[prop] = userSettings[prop]
          }
        })

        this.activeControlsTab = userSettings.activeControlsTab
      }
    },
  },
}
</script>
