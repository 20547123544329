<template>
  <div>{{ label }}</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PIDCell',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('tenant', [
      'getLabelsByPidUuid',
    ]),
    label() {
      return this.getLabelsByPidUuid[this.value] || ''
    },
  },
}
</script>
