<template>
  <div>
    <h5>Adresgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Straat
        </th>
        <td colspan="2">
          {{ address.street }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Huisnummer
        </th>
        <td colspan="2">
          {{ address.streetNumber }} {{ address.streetNumberSuffix }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Postcode
        </th>
        <td>{{ address.postalCode }}</td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.postalcode } }">
            <b-badge variant="dark">
              {{ requestCountByPostalcode({ postalcode: search.postalcode }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Plaats
        </th>
        <td>
          {{ address.city }}
        </td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.city } }">
            <b-badge variant="dark">
              {{ requestCountByCity({ city: search.city }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th>Gemeente</th>
        <td>{{ municipality }}</td>
      </tr>
      <tr>
        <th>Coördinaten</th>
        <td>{{ lat }}, {{ long }}</td>
      </tr>
    </table>

    <h5>Opmerkingen en overige informatie</h5>
    <table class="table table-sm mb-4">
      <tr v-if="remark">
        <th scope="row">
          Opmerkingen
        </th>
        <td>{{ remark ? remark : '-' }}</td>
      </tr>
    </table>

    <h5>Contactgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Naam
        </th>
        <td>{{ contact.name || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Telefoonnummer
        </th>
        <td>{{ contact.phone || '-' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Emailadres
        </th>
        <td>{{ contact.email || '-' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObject } from '@/services/validation'
import { getMunicipalityNameByCode } from '@/services/municipalities'

export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('requests', [
      'requestCountByNumberplate',
      'requestCountByPostalcode',
      'requestCountByCity',
    ]),
    search() {
      return this.request.search
    },
    address() {
      return this.request.address
    },
    lat(){
      return this.request.coordinates.lat || '-'
    },
    long() {
      return this.request.coordinates.lng || '-'
    },
    municipality() {
     const municipality = this.address.municipality
      if (isObject(municipality)) {
        return municipality.name
      }
      return (!isNaN(municipality) ? getMunicipalityNameByCode({ code: municipality }) : municipality) || ''
    },
    remark() {
      return this.request.remark
    },
    contact() {
      return this.request.contact
    },
  },
}
</script>

<style>

</style>
