<template>
  <div class="KeyMetrics mt-3">
    <h4 class="pb-3">
      Key Metrics
    </h4>

    <table class="table table-sm">
      <tr
        v-for="(row,index) in tableData"
        :key="`keyMetric_${index}`"
      >
        <th scope="row">
          {{ row.label }}
        </th>
        <td>{{ row.value }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'KeyMetrics',
  computed: {
    ...mapGetters('requests', [
      'requestsByStatus',
    ]),
    ...mapGetters('realisations', [
      'records',
    ]),
    ...mapGetters('relations', [
      'realisationUuidByRequestUuid',
    ]),
    openRequestCount() {
      return this.requestsByStatus({
        status: 2, // open status
      }).length || 0
    },
    acceptedRequestsWithoutRP() {
      return this.requestsByStatus({
        status: 3, // accepted
      }).filter(request => {
        // check for relation
        return this.realisationUuidByRequestUuid({ uuid: request.uuid }) === null
      }).length
    },

    completedRPCount() {
      return this.records.filter(record => record.status.completed).length
    },
    cancelledRPCount() {
      return this.records.filter(record => record.status.cancelled).length
    },
    activeRPCount() {
      let totalCount = this.records.length

      return totalCount - this.cancelledRPCount - this.completedRPCount
    },

    tableData() {
      return [{
        label: 'Aantal open aanvragen',
        value: `${this.openRequestCount} aanvragen`,
      }, {
        label: 'Aantal geaccepteerde aanvragen zonder RP',
        value: `${this.acceptedRequestsWithoutRP} aanvragen`,
      }, {
      //   label: 'Gemiddelde doorlooptijd beoordeling aanvraag',
      //   value: '0 dagen'
      // }, {
        label: 'Aantal lopende RPs',
        value: `${this.activeRPCount} RPs`,
      }, {
        label: 'Aantal afgeronde RPs',
        value: `${this.completedRPCount} RPs`,
      },
      // {
      //   label: 'Gemiddelde doorlooptijd RP',
      //   value: '0 dagen'
      // }
      ]
    },
  },
}
</script>

<style lang="scss">
.KeyMetrics {
  th {
    min-width: 450px;
  }
  td:nth-child(3n) {
    text-align: right;
    min-width: 40px;
  }
}
</style>
