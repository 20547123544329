<template>
  <b-alert
    :show="alertDismissCountDown"
    :fade="fade"
    :variant="variant"
    :dismissible="dismissible"
    :class="['update-info', position ]"
    @dismissed="handleDismissed"
  >
    {{ message }}
  </b-alert>
</template>
<script>

export default {
  name: 'EVAlert',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    fade: {
      type: Boolean,
      default: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    position: {
      type: String,
      default: 'top',
    },
  },
  data () {
    return {
      alertDismissCountDown: 0,
    }
  },
  watch: {
    show: {
      handler (val) {
        this.alertDismissCountDown = val ? this.count : 0
      },
    },
  },
  methods: {
    handleDismissed () {
      this.alertDismissCountDown = 0
      this.$emit('dismissed')
    },
  },
}
</script>
<style lang="scss" scoped>
.update-info {
  position: absolute;
  width: 100%;
  top: -3.4em;
  opacity: 1;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  text-align: center;
  &.bottom {
    top: 4.5em;
  }
}
</style>
