<template>
  <b-badge variant="dark">
    {{ value }}
  </b-badge>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>
