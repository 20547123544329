<template>
  <div class="ParentPage">
    <LeftSideBar class="ParentPage__leftsidebar">
      <MenuGenerator :menu="menu" />
    </LeftSideBar>

    <div class="ParentPage__wrapper">
      <div class="ParentPage__main">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MenuGenerator from '@/components/MenuGenerator.vue';
import LeftSideBar from '@/components/LeftSidebar.vue';

export default {
  name: 'ParentPage',
  components: {
    LeftSideBar,
    MenuGenerator
  },
  props: {
    menu: {
      type: Array,
      default: null
    },
    rootRouteName: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('user', ['canAccessConfig', 'securityCheck']),
    routeName() {
      return this.$route.name;
    }
  },
  watch: {
    routeName() {
      this.maybeRedirect();
    }
  },
  created() {
    this.maybeRedirect();
  },
  methods: {
    maybeRedirect() {
      if (!this.rootRouteName) return;

      if (this.routeName === this.rootRouteName) {
        let menuitem = this.getFirstAccessibleItem();

        if (menuitem) {
          this.$router.replace(menuitem.to);
        }
      }
    },

    /**
     * Find the first menu item of type link that is accessible for the current user
     */
    getFirstAccessibleItem() {
      return this.menu.reduce(this.findAccessibleMenuItem, false);
    },

    /**
     * A recursive method to look for the first accessible menu item, regardless of menu depth.
     */
    findAccessibleMenuItem(selected, menuitem) {
      // Short circuit if we already have a selected menu item

      if (selected) return selected;

      if (menuitem.securityCheck && !this.securityCheck({ attribute: menuitem.securityCheck })) {
        return false;
      }

      // If this is a link we have a match
      if (menuitem.type === 'link') {
        return menuitem;
      }

      // If this is a segment, we look at it's items
      if (Array.isArray(menuitem.items)) {
        return menuitem.items.reduce(this.findAccessibleMenuItem, false);
      }

      return false;
    }
  }
};
</script>

<style lang="scss">
.ParentPage {
  width: 100%;
  min-height: calc(100vh - 77px);
  height: 100%;
  max-height: 100%;

  margin: 0 auto;
  text-align: left;

  display: grid;
  grid-template-columns: 225px auto;

  .ParentPage__wrapper {
    box-sizing: content-box;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .ParentPage__leftsidebar {
    width: 225px;
    padding-bottom: 40px;
    background: #f3f3f3;
  }

  .ParentPage__main {
    padding: 20px;
  }

  .ParentPage__main {
    a {
      cursor: pointer;
    }
    h2 {
      margin-bottom: 30px;
    }
    table {
      th {
        width: 225px;
      }
    }
  }

  .ParentPage__sidebar {
    position: sticky;
    top: 0;
    align-self: start;

    width: 400px;

    select,
    input {
      width: 400px;
    }
  }

  .status:not(:last-child) {
    margin-right: 5px;
  }
}
</style>
