const state = {
  savedRecordValues: null,
  activeRecordValues: null,
}

const getters = {
  getSavedRecordValues: state => state.savedRecordValues,
  getActiveRecordValues: state => state.activeRecordValues,
}

const actions = {
  setSavedRecordValues ({ commit, dispatch }, { values }) {
    commit('setSavedRecordValues', { values })
    dispatch('setActiveRecordValues', { values })
  },

  setActiveRecordValues ({ commit }, { values }) {
    commit('setActiveRecordValues', { values })
  },
}

const mutations = {
  setSavedRecordValues(state, { values }) {
    state.savedRecordValues = values
  },

  setActiveRecordValues(state, { values }) {
    state.activeRecordValues = values
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
