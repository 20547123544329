<template>
  <span v-if="value === '-'">-</span>
  <b-badge
    v-else-if="value"
    variant="dark"
  >
    {{ label }}
  </b-badge>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    ...mapGetters('tenant', ['getSelectOptionLabelByValue']),
    label() {
      return this.getSelectOptionLabelByValue({
        name: 'processCategories',
        value: this.value,
      })
    },
  },
}
</script>
