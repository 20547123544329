<template>
  <div>
    <h5>Dossier ID</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          ID
        </th>
        <td>{{ additional ? additional.dossierID : '-' }}</td>
      </tr>
    </table>
    <h5>Adresgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Straat
        </th>
        <td colspan="2">
          {{ address.street }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Huisnummer
        </th>
        <td colspan="2">
          {{ address.streetNumber }} {{ address.streetNumberSuffix }}
        </td>
      </tr>
      <tr>
        <th scope="row">
          Postcode
        </th>
        <td>{{ address.postalCode }}</td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.postalcode } }">
            <b-badge variant="dark">
              {{ requestCountByPostalcode({ postalcode: search.postalcode }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Plaats
        </th>
        <td>
          {{ address.city }}
        </td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.city } }">
            <b-badge variant="dark">
              {{ requestCountByCity({ city: search.city }) }}
              <b-icon
                class="ml-2"
                icon="search"
              />
            </b-badge>
          </router-link>
        </td>
      </tr>
      <tr>
        <th>Gemeente</th>
        <td>{{ municipality }}</td>
      </tr>
      <tr>
        <th>Coördinaten</th>
        <td>{{ lat }}, {{ long }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObject } from '@/services/validation'
import { getMunicipalityNameByCode } from '@/services/municipalities'

export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('requests', [
      'requestCountByPostalcode',
      'requestCountByCity',
    ]),
    search() {
      return this.request.search
    },
    lat(){
      return this.request.coordinates.lat || '-'
    },
    long() {
      return this.request.coordinates.lng || '-'
    },
    address() {
      return this.request.address
    },
    municipality() {
     const municipality = this.address.municipality
      if (isObject(municipality)) {
        return municipality.name
      }
      return (!isNaN(municipality) ? getMunicipalityNameByCode({ code: municipality }) : municipality) || ''
    },
    additional() {
      return this.request.additional
    },
  },
}
</script>
