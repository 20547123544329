<template>
  <ParentPage
    class="Reporting"
    :menu="menu"
    :root-route-name="rootRouteName"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import { AdminMenu } from '@/config'

import ParentPage from '@/components/ParentPage.vue'

export default {
  name: 'Config',
  components: {
    ParentPage,
  },
  data() {
    return {
      menu: AdminMenu,
      rootRouteName: 'Config',
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAccessConfig',
    ]),
  },
  created() {
    // Access check
    if (! this.canAccessConfig) {
      this.$router.push({
        name: 'Dashboard',
      })
    }
  },
}
</script>
