
class File {
  constructor(data) {

    /**
     * File uuid
     * @type {String}
     */
    this.uuid = data.uuid || null

    /**
     * The original filename, slugified, without extension
     * @type {String}
     */
    this.filename = data.filename || null

    /**
     * The file extension is taken from the original file name
     * @type {String}
     */
    this.extension = data.extension || null

    /**
     * File status
     */
    this.status = {
      /**
       * Whether or not the file is available for download
       *
       * @type {Boolean}
       */
      uploaded: !! data.status?.uploaded,
      /**
       * Whether the file was marked as suspect (e.g. there is a filetype mismatch)
       * @type {Boolean}
       */
      suspect: !! data.status?.suspect,
      /**
       * Whether the file was removed by one of the annual GDPR checks
       * @type {Boolean}
       */
      gdpr: !! data.status?.gdpr,
      /**
       * Whether a file ended up missing unexpectedly.
       * @type {Boolean}
       */
      missing: !! data.status?.missing,
      /**
       * WHether or not the file has been deleted
       * @type {Boolean}
       */
      deleted: !! data.status?.deleted,
    }

    /**
     * Timestamp of last DB modification of this File record
     */
    this.ts = data.ts
  }

  /**
   * Get the full filename, including the file extension
   * @returns {string}
   */
  getFullFilename() {
    return `${this.filename}.${this.extension}`
  }

  /**
   * Whether or not the file is available for download
   *
   * @returns {boolean}
   */
  isAvailableForDownload() {
    return this.status.uploaded === true
      && this.status.suspect === false
      && this.status.gdpr === false
      && this.status.missing === false
      && this.status.deleted === false
  }

  /**
   * TODO: Implement...
   */
  download() {
    return null
  }
}

export default File
