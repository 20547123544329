<template>
  <div class="TableControls mb-3">
    <div class="TableControls__header">
      <component
        :is="componentName"
        v-if="componentName"
        class="TableControls__permanent"
        v-on="$listeners"
      />
      <div
        v-else
        class="TableControls__permanent"
      />
      <div
        v-if="hasAnyTabs"
        class="TableControls__icons"
      >
        <template v-for="(t, index) in tabs">
          <div
            v-if="t.allowed"
            :key="index"
            :class="{ 'active': activeTab === t.label }"
            @click="setTab(t.label)"
          >
            <div
              v-if="!t.noLabel"
              v-text="t.label"
            />
            <b-icon :icon="t.icon" />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="activeTab"
      class="TableControls__main"
    >
      <component
        :is="`Table${activeTab}`"
        :config="config"
        :class="`TableControls__${activeTab}`"
      />
    </div>
  </div>
</template>

<script>
import {
  loadAllComponentsFromFolder,
} from './utils.js'
import TableExport from './TableExport'
import TableSettings from './TableSettings'
import TableState from './mixins/TableState'
import { mapGetters } from 'vuex'

export default {
  name: 'TableControls',
  components: {
    TableSettings,
    TableExport,
    ...loadAllComponentsFromFolder({
      ComponentContext: require.context('@/components/Table/controls', true, /\.vue$/i),
    }),
  },
  mixins: [TableState],
  computed: {
    ...mapGetters('user', ['canAccessExport']),
    /**
     * Does the active layout have filters configured
     */
    hasFilterConfig() {
      let activeLayout = (this.layout.filters || {})
      let filtersWithComponents = (activeLayout.filters || [])
        .map(property => this.config.filters[property] || {})
        .filter(filter => filter.componentName !== null)
      return filtersWithComponents.length !== 0
    },

    /**
     * The only setting right now, is the selection of configurations
     */
    hasSettings() {
      return this.config.settings !== false
    },
    hasExport () {
      return !!this.config.export && this.canAccessExport
    },
    componentName() {
      return this.config.controlsComponentName || false
    },
    activeTab () {
      return this.tabs.find(tab => tab.label === this.activeControlsTab)?.label || null
    },
    tabs() {
      return [
        { label: 'Export', icon: 'download', allowed: this.hasExport },
        { label: 'Settings', icon: 'gear', allowed: this.hasSettings, noLabel: true },
      ]
    },
    hasAnyTabs() {
      return this.tabs.some(t => t.allowed)
    },
  },
  methods: {
    setTab(tab) {
      // Property activeTab @TableState //
      this.activeControlsTab = this.activeControlsTab !== tab ? tab : null
      this.saveUserSettings()
    },
  },
}
</script>

<style lang="scss">
.TableControls {

  &__header {
    display: flex;
    // border-bottom: 1px solid $gray-300;
    padding-bottom: 3px;
  }

  &__permanent {
    flex-grow: 1;
  }
  &__icons {
    flex-shrink: 0;
    padding-left: 12px;
    display: flex;
    align-self: flex-end;
    min-height: 24px;

    > div {
      display: flex;
      margin-right: 0.3em;
      padding: 0 0.5em;
      position: relative;
      cursor: pointer;
      user-select: none;
      align-items: center;

      svg {
        position: relative;
      }

      &:before {
        top: -10%;
        left: 0;
        position: absolute;
        width: 100%;
        height: 126%;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border: 1px solid $gray-300;
        // border-bottom: none;
        border-radius: 0.25rem;
      }

      &:hover:before {
        content: ''
      }

      &.active:before {
        content: '';
        background: $gray-200;
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:hover {
        color: #00B46B;
      }
      div {
        position: relative;
        z-index: 9;
        margin-right: 0.5em;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__main {
    background: $gray-200;
    padding: 0.5rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    display: flex;
    justify-content: flex-end;
  }

  &__Export {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em 0;
    .btn {
      min-width: 9em;
      margin-left: 0.6em;
    }
  }

  &__Export, &__Settings {
    display: flex;
    justify-content: flex-end;
  }

  &__Export {
    align-items: center;
    padding: 0.5em 0;
    .btn {
      min-width: 9em;
      margin-left: 0.6em;
    }
  }

}
</style>
