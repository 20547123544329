/**
 * Import Dependency
 */

import Vue from 'vue'

function identifierToKey({ identifier, state }) {
  let config = state.activeTableConfigs[identifier] || false
  // if (config === false) {
  //   throw new Error('Table is not active')
  // }
  return `${identifier}.${config}`
}

/**
 * Import API
 */

/**
 * Declare Variable
 */
 const state = {

  // configsByUuid: {
  //   // uuid: []
  // },
  accessibleConfigsByIdentifier: {
    // category.name: uuid[]
  },

  activeTableConfigs: {
    // category.name: uuid
  },

  // state by table
  tableStates: {
    // category.name.uuid: {
    //   records: [],
    //   activeFilters: [],
    //   activeSorting: [],
    //   settings: { layout: 'default', pageSize: 10 }
    // }
  },
  dataMapperRequests: [],
}

const getters = {
  getActiveStoredTableState: (state) => ({ identifier }) => {
    let key = identifierToKey({ identifier, state })
    return state.tableStates[key] || null
  },
  activeConfig: (state) => ({ identifier }) => {
    return state.activeTableConfigs[identifier] || false
  },
  hasAllRecordsLoaded: (state) => state.dataMapperRealisations.length && state.dataMapperRequests.length,
  dataMapperRealisations: (state) => state.dataMapperRealisations,
  dataMapperRequests: (state) => state.dataMapperRequests,
}
const actions = {
  loadRecords ({ commit }, { identifier, records } ) {
    commit('setRecords', { identifier, records })
  },
}
const mutations = {
  /**
   * List of configs (uuids) to a specific table, accessible to the user based on their role
   */
  setAccessibleConfigs(state, { configs, identifier }) {
    Vue.set(state.accessibleConfigsByIdentifier, identifier, configs)
  },

  /**
   * Set the active config for a specific table.
   */
  setActiveConfig(state, { uuid, identifier }) {
    Vue.set(state.activeTableConfigs, identifier, uuid)
  },

  //
  storeTableState(state, { identifier, settings, activeFilters, activeSorting, records }) {
    let key = identifierToKey({ identifier, state })
    Vue.set(state.tableStates, key, {
      settings,
      activeFilters,
      activeSorting,
      records,
    })
  },

  setupTableState(state, { identifier, config }) {
    let key = identifierToKey({ identifier, state })

    Vue.set(state.tableStates, key, {
      settings: {
        layout: 'default',
        pageSize: config.pageSize || 10,
      },
      activeFilters: [],
      activeSorting: [],
      records: [],
    })
  },

  setRecords(state, { identifier, records }) {
    let key = identifierToKey({ identifier, state })
    if (state.tableStates[key]) {
      Vue.set(state.tableStates[key], 'records', records)
    }

    const recordsType = identifier.includes('realisation')
      ? 'dataMapperRealisations'
      : 'dataMapperRequests'

    state[recordsType] = records
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
