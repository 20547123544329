/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
 const state = {
  visible: false,
  frozen: false,
}

const getters = {
  isOverlayVisible: state => !! state.visible,
  isOverlayFrozen: state => !! state.frozen,
}
const actions = {

}
const mutations = {
  freezeOverlay(state) {
    state.frozen = true
  },
  unfreezeOverlay(state) {
    state.frozen = false
  },
  showOverlay(state) {
    state.visible = true
  },
  hideOverlay(state) {
    if (state.frozen) return
    state.visible = false
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
