<template>
  <div
    v-if="page"
    v-html="page"
  />
</template>

<script>
import * as DOMPurify from 'dompurify';
export default {
  data() {
    return {
      page: null
    };
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      async handler(id) {
        if (id) {
          try {
            const token = await this.$auth.getTokenSilently();
            const response = await fetch('/api/get-manual-by-id', {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify({ id })
            });
            if (response.status === 200) {
              const result = await response.json();
              this.page = DOMPurify.sanitize(result);
            }
            if (response.status === 404) {
              this.page = 'Handleiding niet gevonden';
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }
};
</script>
