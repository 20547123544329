<template>
  <div
    v-if="show"
    class="MapModal"
  >
    <div
      class="MapModal__backdrop"
      @click="handleClose"
    />

    <div class="MapModal__modal">
      <div class="MapModal__map">
        <MapBox
          :access-token="accessToken"
          :map-style.sync="mapStyle"
          :options="mapOptions"
          @load="onMapLoaded"
        >
          <!-- data layers -->
          <LayerParkingPermitAreas :loaded="loaded" />

          <LayerRealisationProcesses @click="handleOpenRealisationPopup" />
          <LayerRequests :uuid="uuid" />
          <!-- <LayerRequestIsochrone :request="request" /> -->
          <LayerLocationPicker
            :locked="true"
            :use-geocoder="false"
            :coordinates="coordinates"
          />

          <LayerChargingpoints
            :municipality-code="municipalityCode"
            :monitoring="true"
          />
          <LayerRequestChargerLine :request="request" />
          <LayerMonitoring />

          <PopupRequest :record="{}" />

          <PopupRealisationProcess
            :uuid="realisationUuid"
            @close="handleCloseRealisationProcessPopup"
          />

          <PopupChargingLocation />
        </MapBox>
      </div>
      <div class="MapModal__sidebar">
        <div class="MapModal__nav">
          <span
            class="MapModal__tabnav"
            :class="{ active: tab === 'info' }"
            @click="switchTab({ tab: 'info' })"
          >
            Informatie
          </span>
          <span
            class="MapModal__tabnav"
            :class="{ active: tab === 'legend' }"
            @click="switchTab({ tab: 'legend' })"
          >
            Legenda
          </span>

          <span
            class="MapModal__close"
            @click="handleClose"
          >X</span>
        </div>
        <ChargerTab
          v-if="tab === 'info'"
          :request-coordinates="coordinates"
        />

        <div
          v-if="tab === 'legend'"
          class="MapModal__tab"
        >
          <div class="MapModal__segment">
            <LegendRequest />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import MapBox from '@/components/common/MapBox';
import LayerChargingpoints from '@/components/map/LayerChargingpoints';
import LayerMonitoring from '@/components/map/LayerMonitoring';
import LayerRequests from '@/components/map/LayerRequests';
import LayerRequestChargerLine from '@/components/map/LayerRequestChargerLine';
import LayerRealisationProcesses from '@/components/map/LayerRealisationProcesses';
import LayerParkingPermitAreas from '@/components/map/LayerParkingPermitAreas';
import LayerLocationPicker from '@/components/map/LayerLocationPicker';
import PopupRequest from '@/components/map/PopupRequest.vue';
import PopupRealisationProcess from '@/components/map/PopupRealisationProcess';
import LegendRequest from '@/components/map/LegendRequest.vue';
import PopupChargingLocation from '@/components/map/PopupChargingLocation.vue';

import ChargerTab from '@/components/map/ModalTabs/ChargerTab';

export default {
  name: 'MapModal',
  components: {
    MapBox,
    LayerChargingpoints,
    LayerRequests,
    LayerRealisationProcesses,
    LayerParkingPermitAreas,
    PopupRealisationProcess,
    PopupRequest,
    PopupChargingLocation,
    LegendRequest,
    LayerMonitoring,
    ChargerTab,
    LayerRequestChargerLine,
    LayerLocationPicker
    // LayerRequestIsochrone
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      /**
       * MapBox instance
       */
      map: null,

      /**
       * MapBox SDK reference
       */
      mapbox: null,

      /**
       * MapBox is ready
       */
      loaded: false,

      /**
       * MapBox config
       */
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,

      /**
       * A default Mapbox Marker on the Request address position
       */
      requestMarker: null,

      /**
       * MapBox geocoder field
       */
      geocoder: null,

      /**
       * The uuid of the clicked realisation process
       */
      realisationUuid: null,

      /**
       * Visible sidebar tab
       */
      tab: 'info'
    };
  },

  computed: {
    ...mapGetters('requests', ['requestByUuid']),
    ...mapGetters('tenant', ['getMapOptions']),
    request() {
      return this.requestByUuid({ uuid: this.uuid });
    },
    /**
     * Whether the loaded request is lacking coordinates
     */
    hasCoordinates() {
      return this.request && this.request.coordinates && this.request.coordinates.lat;
    },
    noMatch() {
      // console.log(this.request)
      return this.request && this.request.coordinates && this.request.coordinates.nomatch;
    },
    coordinates() {
      return this.hasCoordinates ? this.request.coordinates : null;
    },
    mapOptions() {
      let center = this.hasCoordinates
        ? [this.request.coordinates.lng, this.request.coordinates.lat]
        : [4.9041, 52.3676];

      return {
        center,
        zoom: 14.25 // right before charger text shows, while monitor indicators are just visible
      };
    },
    municipalityCode() {
      const req = this.requestByUuid({ uuid: this.uuid });
      return req.municipalityCode || req.raw.municipalityCode || req.raw.municipalityCode || req.geographyCode;
    }
  },
  beforeDestroy() {
    if (this.requestMarker) {
      this.requestMarker.remove();
    }
  },

  methods: {
    onMapLoaded({ map, mapbox }) {
      this.loaded = true;
      this.map = map;
      this.mapbox = mapbox;

      // Add the GeoCoder plugin
      // This geocoder is only for navigation purposes
      // License restrictions prevent other usage
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: this.mapbox,

        countries: this.getMapOptions.countries || 'nl',
        types: 'address',
        language: 'nl',

        collapsed: true,
        placeholder: 'Zoek op adres',
        marker: false
      });
      this.map.addControl(this.geocoder);
    },
    handleOpenRealisationPopup({ uuid }) {
      this.realisationUuid = uuid;
    },
    handleCloseRealisationProcessPopup() {
      this.realisationUuid = null;
    },
    switchTab({ tab }) {
      this.tab = tab;
    },
    handleClose() {
      // console.log("close")
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.MapModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  &__modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    height: calc(100vh - 80px);
    border-radius: 4px;
    box-shadow: 2px 5px 16px 0px #666666, 5px 5px 15px 5px rgba(0, 0, 0, 0);

    display: grid;
    grid-template-columns: 1fr minmax(300px, 500px);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  &__backdrop {
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
  }

  &__map {
    grid-column: 1;
    height: 100%;
    min-height: 100%;
    background: #cecece;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &__sidebar {
    background: white;
    grid-column: 2;
    height: 100%;
    padding: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &__nav {
    padding: 10px 10px 0;
    position: relative;
  }
  &__nav:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #aeaeae;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;

    &:hover {
      color: red;
    }
  }
  &__tabnav {
    font-weight: bold;
    cursor: pointer;
    padding: 10px 20px 4px;
    display: inline-block;
    margin-right: 5px;

    &:hover,
    &.active {
      color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:hover {
      color: black;
      background: #cecece;
    }
    &.active {
      background: #aeaeae;
    }
  }
  &__tab {
    padding: 20px;
  }
}
</style>
