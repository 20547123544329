<template>
  <Toggle
    :value="value"
    :disable="disabled"
    :bg-color="bgColor"
    :label="label"
    :is-legend="isLegend"
    @change="handleChange"
  />
</template>

<script>
import { visibleRequestsByRealisationStatuses } from '@/config'
import Toggle from '@/components/common/Toggle.vue'
export default {
  // Component mounted from mapbox.js as instance.$el //
  name: 'ToggleSwitch',
  components: { Toggle },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: '',
    },
    isLegend: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      localValue: false,
    }
  },
  created () {
    this.localValue = this.value || visibleRequestsByRealisationStatuses[this.filter]
  },
  methods: {
    handleChange (value) {
      visibleRequestsByRealisationStatuses[this.filter] = value
    },
  },
}
</script>
<style lang="scss">
.ToggleSwitch {
  margin-right: 0.5em;
}
</style>
