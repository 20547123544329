<script>

import TableState from './TableState.vue'

/**
 * This mixin is purely focused on the application of active filtering logic on the records
 *
 */
export default {
  mixins: [TableState],
  data() {
    return {
      /**
       * Duration in ms that no changes should occur to the active filter before applying these active filters
       */
      delay: 150,

      /**
       * timeout reference, if there is one
       */
      timeout: null,
    }
  },
  computed: {
    /**
     * Map filter configs by their id
     */
    filterConfigsById() {
      return this.config.filters
    },
  },
  watch: {
    /**
     * React to any and all changes to the active filters
     */
    activeFilters: {
      handler() {
        // Clear filters without delay
        if (this.activeFilters.length === 0) {
          this.resetAndRefresh()

        // Apply filters after a short period with no filter changes
        // This avoids heavy computations of which the result gets
        // completely replaced within a few milliseconds
        } else {
          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(this.filterAndRefresh, this.delay)
        }
      },
      deep: true,
    },
  },
  methods: {

    resetAndRefresh() {
      this.resetFiltering()
    },

    filterAndRefresh() {
      this.applyFilters()
    },

    /**
     * Reset filtering information
     */
    resetFiltering() {
      this.tableState.records.forEach(record => {
        record.filterPending = false
        record.filteredOut = false
        record.filterReason = null
      })
    },

    /**
     * Groups active filters by config
     *  If there is no config for an active filter, it gets ignored
     *
     * @returns {object[]}
     */
    groupActiveFiltersByConfig() {
      return Object.values(
        this.activeFilters
          .reduce(( filtersByConfig, filter ) => {
            let id = filter.category

            // 1st instance of filter
            if (
              ! filtersByConfig[id]
              && this.filterConfigsById[id]
            ) {

              filtersByConfig[id] = this.filterConfigsById[id]
              filtersByConfig[id].filters = [filter]

            // 2nd+ instance of filter
            } else if (filtersByConfig[id]) {
              filtersByConfig[id].filters.push(filter)
            }

            return filtersByConfig
          }, {}),
      )
    },

    /**
     * Apply filters, simplest first. Exit on first matching filter
     */
    applyFilters() {

      /**
       * Group active filters by their config, and combine with filter config info
       */
      let activeFilters = this.groupActiveFiltersByConfig()


      /**
       * Reset filtering if it turns out there are no valid active filters
       */
      if (activeFilters.length === 0) {
        this.resetFiltering()
        return
      }


      /**
       * Prep filters, to increase efficiency
       *  And sort them, for efficiency gains
       */
      activeFilters = activeFilters
        .sort((a, b) => a.cost - b.cost)
        .map(filter => filter.prep({ filter }))


      /**
       * Loop through all records with all filters
       *  Callback functions sort records
       *  By applying simplest first, less records go through the complex filters
       */
      const sortedRecords = activeFilters.reduce((records, filter) => {
        return filter.callback({
          records,
          filter,
        })
      }, {
        visible: this.tableState.records,
        hidden: [],
      })


      /**
       * Show the records that survived the filters
       */
      sortedRecords.visible.forEach(record => {
        record.filteredOut = false
        record.filterReason = null
      })
    },
  },
}
</script>
