
class Request {
  constructor({
    ref, data,
  }) {
    // console.log("Request Data", data)

    const contact = data.baseData ? (data.baseData.contact || {}) : {}
    const addressData = data.baseData ? data.baseData.address : {}

    /**
     * Address formatting
     */
    let address = `${addressData.street} ${addressData.streetNumber} ${addressData.streetNumberSuffix || ''}`.trim()
    address = `${address}, ${addressData.postalCode}`
    address = `${address}, ${addressData.city}, ${(addressData.country || '').toUpperCase()}`

    /**
     * Coordinates
     */
    let coords = {
      lat: null,
      lng: null,
    }
    try {
      coords = {
        lat: parseFloat(data.baseData.coordinates.lat),
        lng: parseFloat(data.baseData.coordinates.lng),
      }
    } catch (e) {
      console.log(`Missing coordinates for Charge Point Request - ${data.uuid}`)
    }

    /**
     * Apply common formatting
     */
    let common = this.commonStructure({ ref, data })

    /**
     * Extend / override with custom properties
     */
    data = Object.assign(common, {
      tenant: 'vlaanderen',
      contact: contact,
      address: addressData,

      additional: data.additionalData,

      summary: Object.assign(common.summary, {
        name: contact.name,
        address,
      }),
      search: {
        postalcode: data.baseData.address && data.baseData.address.postalCode ? data.baseData.address.postalCode.replace(' ', '').trim() : '',
        city: data.baseData.address && data.baseData.address.city ? data.baseData.address.city.toLowerCase().trim() : '',
      },

      coordinates: {
        nomatch: false,
        lat: coords.lat,
        lng: coords.lng,
      },
      submitted_at: common.created_at,
    })

    /**
     * Make all data properties available on the Model object
     */
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
  }
  /**
   * Not really necessary for BE, but expected to exists
   */
  formatPostalCode(postalcode) {
    return `${postalcode}`.trim()
  }
}



export default Request
