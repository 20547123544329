<template>
  <div
    class="Tags"
    :class="{ 'Tags--compact': compact }"
  >
    <div
      v-if="readmode"
      class="Tags__viewer"
    >
      <h5>Tags:</h5>
      <div class="Tags__list">
        <span v-if="currentTags.length === 0">Er zijn geen tags</span>
        <b-badge
          v-for="(tag,index) in currentTags"
          :key="`tag_${index}`"
          class="mr-1"
          variant="secondary"
        >
          {{ labelByUuid({ uuid: tag }) }}
        </b-badge>
      </div>
      <b-button
        v-if="!record.status.locked"
        class="Tags__edit"
        variant="light"
        size="sm"
        @click="handleStartEdit"
      >
        <b-icon icon="pencil-square" />
        Aanpassen
      </b-button>
    </div>

    <b-form-group
      v-else
      label-for="tags-component-select"
      class="Tags__editor"
    >
      <h5>Tags:</h5>

      <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
      <b-form-tags
        id="tags-component-select"
        v-model="selectedTags"
        :disabled="disabled"
        class="mb-2"
        add-on-change
        no-outer-focus
      >
        <template #default="{ tags, inputAttrs, inputHandlers, impaired, removeTag }">
          <!-- Eslint warning fix: disabled = impared -->
          <ul
            v-if="tags.length > 0"
            class="list-inline d-inline-block mb-2"
          >
            <li
              v-for="tag in tags"
              :key="tag"
              class="list-inline-item"
            >
              <b-form-tag
                :title="labelByUuid({ uuid: tag })"
                :disabled="impaired"
                @remove="removeTag(tag)"
              >
                {{ labelByUuid({ uuid: tag }) }}
              </b-form-tag>
            </li>
          </ul>

          <b-input-group>
            <b-form-select
              v-bind="inputAttrs"
              size="sm"
              :disabled="impaired || availableOptions.length === 0"
              v-on="inputHandlers"
            >
              <template #first>
                <!-- This is required to prevent bugs with Safari -->
                <option
                  disabled
                  value=""
                >
                  Kies een tag...
                </option>
              </template>
              <b-form-select-option
                v-for="(option,index) in availableOptions"
                :key="index"
                :value="option"
              >
                {{ labelByUuid({ uuid: option }) }}
              </b-form-select-option>
            </b-form-select>

            <b-input-group-append>
              <b-button
                :disabled="impaired || (currentTags.length === 0 && selectedTags.length === 0)"
                variant="dark"
                size="sm"
                @click="handleSave"
              >
                Opslaan
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
      </b-form-tags>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import Realisation from '@/models/Realisation'

export default {
  name: 'Tags',
  props: {
    /**
     * Either a Request or a Realisation Process model
     */
    record: {
      type: Object,
      required: true,
    },

    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      readmode: true,
      disabled: false,
      selectedTags: [],
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getEnabledTags',
      'getLabelsByTagUuid',
    ]),

    /**
     * The record tag uuids
     */
    currentTags() {
      return this.record.getTags()
    },

    /**
     * Tags that are selectable
     */
    enabledTagUuids() {
      return this.getEnabledTags.map(tag => tag.uuid)
    },

    /**
     * Tags that enabled and unselected
     */
    availableOptions() {
      return this.enabledTagUuids.filter(opt => this.selectedTags.indexOf(opt) === -1)
    },
  },
  // watch: {
  //   currentTags() {
  //     this.selectedTags = this.currentTags
  //   }
  // },
  created() {
    this.selectedTags = this.currentTags
  },
  methods: {
    ...mapMutations('realisations', [
      'replaceRecord',
    ]),
    ...mapMutations('requests', [
      'updateRequest',
      'replaceRequest',
    ]),
    labelByUuid({ uuid }) {
      return this.getLabelsByTagUuid[uuid] || 'Onbekende tag'
    },
    handleStartEdit() {
      this.readmode = false
    },
    handleSave: async function() {
      try {
        // console.log("submit")

        this.feedback = null
        this.disabled = true

        // Note: Because the input options are generated we
        //       only need to validate the input at the server.
        const ModelType = this.record.getModelName()

        let payload = {
          ref: this.record.getRef(),
          data: {
            Tags: this.selectedTags,
            ModelType
          },
        }

        let endpoint = ''

        /**
         * Prep
         */
        switch (ModelType) {
          case 'Request':
            endpoint = '/api/requesttags'
            break
          case 'Realisation':
          case 'Process':
            endpoint = '/api/process_tags'
            payload.data.step = 'tags'
            break
          default:
            throw new Error('Unknown model. No endpoint available.')
        }

        // Make the call
        const token = await this.$auth.getTokenSilently()
        const api = await fetch(endpoint, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
        if (! api.ok) {
          throw new Error(api.error)
        }
        const response = await api.json()

        // console.log("response")
        // Note: this handles the tag update for Requests
        this.record.setTags({ Tags: this.selectedTags })

        if (this.record instanceof Realisation) {
          this.replaceRecord({
            record: response.data.record,
          })
        } else if (ModelType === 'Process') {
          this.$store.dispatch('processes/replaceProcess', { process: response.data.record })
        } else {
          // this.updateRequest({
          //   ref: this.record.getRef()
          // })
          this.replaceRequest({
            request: response.data.record,
          })
        }


        this.readmode = true
        this.disabled = false

      } catch (e) {
        this.disabled = false

        // console.log(e)
      }
    },
  },
}
</script>

<style lang="scss">
.Tags {

  &__viewer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $padding;
    padding-bottom: $padding-sm;
    border-bottom: 1px solid $gray-400;
    max-width: 100%;

    * {
      flex-shrink: 0;
    }

    h5 {
      margin-right: 1rem;
    }

    .Tags__list {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  &.Tags--compact {
    h5 {
      display: none;
    }
  }
}
</style>
