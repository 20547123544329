import Vue from 'vue';
import App from './App.vue';

import store from './store';

import router from './router';

// models
import Realisation from './models/Realisation';
import Process from './models/Process';
import Location from './models/Location';
import ProcessLocation from './models/ProcessLocation';
import Request from './models/Request';
import VueTour from 'vue-tour'
import EvtoolsTour from './components/tour/EvtoolsTour.vue';

// Polyfill Fetch
import 'whatwg-fetch';

// Polyfill Buffer
if (!window.Buffer) {
  window.Buffer = require('buffer').Buffer;
}

// AlertPlugin, ButtonPlugin, IconsPlugin, InputGroupPlugin, FormInputPlugin, FormSelectPlugin, ListGroupPlugin, NavPlugin, PaginationPlugin
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import PrimeVue from 'primevue/config';

import './assets/sass/bootstrap.scss';
import './assets/sass/index.scss';
import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import { locale } from '@/primevueLocale';

Vue.use(BootstrapVue);
Vue.use(PrimeVue, { locale }); // use dutch translations
Vue.use(IconsPlugin);
Vue.use(VueTour);
Vue.use(EvtoolsTour);

Vue.config.productionTip = false;

// Auth0 - TODO: Move plugin code to npm module
import { Auth0Plugin } from './auth';
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

/**
 * Init Geocoder
 */

import { initGoogleAPILoader } from '@/services/geocoder';
initGoogleAPILoader();

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

const instance = new Vue({
  router,
  store,
  render: h => h(App)
});

// bind auth0 Plugin to the store, so we can retrieve there the token as well
instance.$store.$auth = instance.$auth;
instance.$store.$bvToast = instance.$bvToast;

// bind store to models
[Realisation, Location, Request, Process, ProcessLocation].forEach(
  module => (module.prototype.$store = instance.$store)
);

instance.$mount('#app');

// Set initial userSettings local storage object, only if non-existent //
if (localStorage && !localStorage.getItem('userSettings')) {
  localStorage.setItem('userSettings', JSON.stringify({}));
}
