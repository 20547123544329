<template>
  <div
    class="BlockRepeater"
    :class="{ 'inline': inline, 'disabled': disabled }"
  >
    <div
      v-if="!isLimited"
      class="BlockRepeater__header"
    >
      <button
        v-if="!viewer && !disabled"
        class="BlockRepeater__add"
        :disabled="isLimited"
        @click="addItem"
      >
        <b-icon icon="plus-circle" />
        <span v-if="label">{{ label }}</span>
      </button>
      <h3 v-if="title" class="mt-3">
        {{ title }}
      </h3>
    </div>
    <div class="BlockRepeater__body">
      <RepeaterItem v-for="id in itemArray" :id="id" :key="id" :inline="inline" :border="border" :divider="divider"
        :viewer="viewer" :compact="compact" :disabled="disabled" :permanent="isPermanent({ id })"
        :deleted="isDeleted({ id })" @delete="removeItem(id)">
        <slot :id="id" />
      </RepeaterItem>
      <div v-if="viewer && itemArray.length === 0">
        Geen gegevens beschikbaar
      </div>
    </div>
  </div>
</template>

<script>
import RepeaterItem from './BlockRepeaterItem.vue'
export default {
  name: 'BlockRepeater',
  components: {
    RepeaterItem,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    values: {
      type: Object,
      default: function () {
        return {}
      },
    },
    /**
     * Compact, inline visualisation
     */
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    permanent: {
      type: [Boolean, Function],
      default: false,
    },
    deleted: {
      type: Function,
      default: () => false,
    },
    limit: {
      type: Number,
      default: 0,
    },
    /**
     * Viewer (no UI)
     */
    viewer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentId: 0,
      itemArray: [],
    }
  },
  computed: {
    isLimited() {
      return !!this.limit && this.itemArray.length >= this.limit
    },
  },
  watch: {
    values: {
      handler() {
        // Reload the list based on the input data from the API whenever this input data changes
        this.setAvailableBlocks()
      },
      deep: true,
    },
  },
  created() {
    // Load available data from the API
    this.setAvailableBlocks()
  },
  methods: {
    setAvailableBlocks() {
      // Reset & (re)load
      this.itemArray = []
      this.currentId = 0

      if (this.path === '') throw new Error('BlockRepeater requires a path')

      // Move to the specified path in the nested values object
      let segments = this.path.split('.')
      let values = this.values
      segments.forEach(segment => {
        values = values[segment] || {}
      })

      // Get the number of array objects formatted as an array or numbers
      this.itemArray = Object.keys(values).map(key => parseInt(key))
      this.currentId = this.itemArray.length
    },
    isPermanent({ id }) {
      if (this.permanent === false || this.permanent === null) return false
      if (this.permanent === true) return true

      return this.permanent({ index: id })
    },
    isDeleted({ id }) {
      return this.deleted({ index: id })
      // console.log(this.itemArray, this.itemArray[id])
      // return !! (this.itemArray[id] && this.itemArray[id].deleted_at)
    },
    addItem(e) {
      e.preventDefault()
      this.$emit('changed', { index: this.currentId, items: this.itemArray })
      this.itemArray.push(this.currentId)
      this.currentId++

    },
    removeItem(id) {
      this.itemArray = this.itemArray.filter(item => item !== id)
      this.$emit('removed', { index: id })
    },
  },
}
</script>

<style lang="scss">
.BlockRepeater {
  display: block;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: reverse;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: $padding;
  }

  &__body {
    display: flex;
    flex-direction: column-reverse;
  }

  &__add {
    // width: 100%;
    background-color: transparent;
    // place-items: center;
    // grid-template-columns: 60px 1fr;
    border: 1px dashed $gray-500;
    border-radius: 3px;
    padding: $padding-sm;
    font-size: 1.5em;
    color: $primary;
    margin-right: $padding-sm;

    &:hover {
      background-color: $gray-200;
    }

    span {
      margin-left: $padding-sm
    }
  }

  &.box {
    border: 1px solid #adb5bd;
    border-radius: 3px;
    padding: 12.5px;
  }

  &.inline {
    .BlockRepeater {
      &__header {
        margin-bottom: 0;
      }

      &__add {
        font-size: 1em;
        border: none;

        &:hover {
          color: $primary;
          text-decoration: underline;
          background-color: transparent;
        }
      }
    }
  }

  &.disabled {
    .BlockRepeater {
      &__add {
        color: $gray-500 !important;
        cursor: not-allowed;
      }
    }
  }
}
</style>
